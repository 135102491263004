import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { GridInfo } from '../../../shared/grid-info';

@Component({
	selector: 'trella-post-notes',
	templateUrl: './post-notes.component.html',
	styleUrls: ['./post-notes.component.scss']
})
export class PostNotesComponent {
	@Input() gridInfo: GridInfo;

	constructor() {}

	get isPostNoteNewLine() {
		return this.gridInfo && this.gridInfo.postNotes && this.gridInfo.postNotes.newline;
	}

	get PostNoteTitle() {
		return this.gridInfo && this.gridInfo.postNotes && `${this.gridInfo.postNotes.title}:`;
	}

	get isMultiplePostNotes() {
		return this.gridInfo && this.gridInfo.postNotes && this.gridInfo.postNotes.details && this.gridInfo.postNotes.details.length > 1;
	}
}
