import { NPI_GROUP_TYPE, PROVIDER_TYPE } from './constants';

export class NpiGroup {
	npiGroupID?: number;
	npiGroupType: NPI_GROUP_TYPE;
	npiTypeName?: string;
	description?: string;
	customerID?: number;
	groupTypeID?: string;
	groupTypeName?: string;
	lobs?: string[];
	markets: string[];
	npis: Array<NpiGroupNpi>;
	portal?: string;
	providerType: PROVIDER_TYPE;
	shareLevel?: number;
	title: string;
	userID?: number;
	isActive: boolean;
}

export class NpiGroupNpi {

	npi: string;
	npiName: string;
}
