import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'trella-risk-adjusted-toggle',
	templateUrl: './risk-adjusted-toggle.component.html',
	styleUrls: ['./risk-adjusted-toggle.component.scss']
})
export class RiskAdjustedToggleComponent {
	@Input() isRiskAdjusted: boolean = false;
	@Input() isSmallSwitch: boolean = false;
	@Input() riskAdjustSwitchId: string = 'riskAdjustSwitchId';
	@Output() onRiskAdjustToggleChange: EventEmitter<boolean> = new EventEmitter();
}
