import { NpiType } from '../../enums/npi-type';
import { MedicalEntity } from '../medical-entity';

export class Npi extends MedicalEntity {
  alias: string;
  displayOther: string;
  email: string;
  fax: string;
  gpsLatitude: number;
  gpsLongitude: number;
  medicalDirector: boolean;
  multiNpiFacility: boolean;
  note: string;
  npi: string;
  phone: string;
  rating?: number;
  specialty: string;
  specialtyAlias: string;
  subSpecialty: string;
  trellaType: NpiType;
}
