import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { GridCellIconContent, GridCellContentProcessor } from '../models/grid/grid-cell-enum-type';

@Directive({
	selector: '[grid-cell-icon]'
})
export class GridCellIconDirective implements OnChanges {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@Input('grid-cell-icon') content: GridCellIconContent;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.content) {
			const appearance = GridCellContentProcessor.process(this.content);
			const iconClass = appearance && appearance.iconClass;
			const swatch = appearance && appearance.colorSwatch;

			this.clearClasses();

			if (iconClass) {
				this.addClass(iconClass);
			}

			if (swatch) {
				this.addClass(`color_swatch ${swatch}`);
			}
		}
	}

	clearClasses() {
		const classesToRemove = [...this.el.nativeElement.classList] as string[];
		classesToRemove.forEach(c => this.renderer.removeClass(this.el.nativeElement, c));
	}

	addClass(text: string) {
		// Renderer2 can only add one class at a time.
		const classes = text.split(' ');
		classes.map(c => this.renderer.addClass(this.el.nativeElement, c));
	}
}
