import { MAX_NUMBER_OF_STARS } from '../../shared/constants';

export enum DemographicCardType {
	mosaic = 'mosaic',
	market = 'market',
	simple = 'simple'
}

export class DemographicCardOptions {
	displayDetails?: boolean;
	displayLinkToNPPES?: boolean;
	displayPDGMProLink?: boolean;
	displayAssignees?: boolean;
	displayTarget?: boolean;
	displayFavorite?: boolean;
	displayNpiGroup?: boolean;
	displayTitleBar?: boolean;
	displayStaff?: boolean;
	displayPresentationButton?: boolean;
	addToCartChart?: boolean;
	addUser?: boolean;
	editInfo?: boolean;
	editTarget?: boolean;
	editInternalNote?: boolean;
	editFavorite?: boolean;
	editNpiGroup?: boolean;
	removeDataKit?: boolean;
	removeNpiAssignee?: boolean;
	removeUser?: boolean;
	maxNumberofStars: number;
	displaySubSpecialty?: boolean;

	constructor(type: DemographicCardType) {
		this.maxNumberofStars = MAX_NUMBER_OF_STARS;

		switch (type) {
			case DemographicCardType.mosaic: {
				Object.assign(this, mosaicOptions);
				break;
			}
			case DemographicCardType.market: {
				Object.assign(this, marketscapeOptions);
				break;
			}
			case DemographicCardType.simple: {
				Object.assign(this, mosaicOptions);
				break;
			}
		}
	}
}

const mosaicOptions: Partial<DemographicCardOptions> = {
	displayDetails: true,
	displayLinkToNPPES: true
};

const marketscapeOptions: Partial<DemographicCardOptions> = {
	displayTarget: true,
	displayFavorite: true,
	displayNpiGroup: true,
	displayPDGMProLink: true,
	displayDetails: true,
	displayLinkToNPPES: true,
	displayStaff: true,
  	displayTitleBar: true,
  	displayPresentationButton: true
};

const simpleOptions: Partial<DemographicCardOptions> = {
	displayTitleBar: true,
	removeDataKit: true
};

export class DemographicCardPermissions {
	assigneeDisplay?: boolean;
	assigneeAdd?: boolean;
	assigneeDelete?: boolean;
}
