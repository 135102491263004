import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Target, ChartConfig } from '../../shared/chart/chart';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { AdapterOptionsType } from '../chart/chart-data-series.model';
import { FilterSelection } from '../../shared/models/filter';
import { ChartComponent } from '../chart/chart.component';
import { ImageExportService as ChartExport, ImageExportService } from '../../shared/services/chart-export.service';

@Component({
	selector: 'trella-multi-chart',
	templateUrl: './multi-chart.component.html',
	styleUrls: ['./multi-chart.component.scss']
})
export class MultiChartComponent extends ComponentWithSubscription implements OnInit {
	@ViewChild('chartExport') chartExport: ChartComponent;
	chartConfigs: ChartConfig[];
  	@Input() target: Target;
	
	@Input()
	set configs(configs: ChartConfig[]) {
		this.chartConfigs = configs;
		this.selectOptions = configs.map(config => {
			const index = configs.indexOf(config);
			return {
				display: config.title || config.reportName || index
			};
		});

		if (configs && configs.length) {
			this.selectedOption = this.selectOptions[0];
		}
	}

	@Input() filters: FilterSelection[];

	@Input() isLoading = false;
	@Input() adapterOptions?: AdapterOptionsType;
	@Input() dataChange$: Observable<ChartConfig>;
	@Input() activeLegendItems = [];
	@Input() useNativeImageExport = true;
	@Input() inDataKit = false;

	@Output() clickEvent = new EventEmitter();
	@Output() expandDialogEvent = new EventEmitter();
	@Output() activeLegendItemsChange = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onRefreshData = new EventEmitter<ChartConfig>();
	@Output() addToClipboardEvent = new EventEmitter();
	@Output() exportChartImageEvent = new EventEmitter();
	@Output() removeDataKitComponent = new EventEmitter();
	@Output() openDevInfoDialogEvent = new EventEmitter();

	selectedOption: any;
	selectedChartConfig: ChartConfig;

	get showSpinner(): boolean {
		return this.isLoading;
	}

	@Output() selectOptions;

	get isSingleChart() {
		return this.chartConfigs && this.chartConfigs.length === 1;
	}

	constructor(private cdr: ChangeDetectorRef, private chartExportService: ImageExportService) {
		super();
	}

	ngOnInit() {
		this.dataChange$.subscribe(chartConfig => {
			this.selectedChartConfig = chartConfig;
			this.cdr.detectChanges();
		});
	}

	refreshData() {
		this.onRefreshData.emit(this.selectedChartConfig);
	}

	openExpandDialog() {
		this.expandDialogEvent.emit();
	}

	addToClipboard() {
		this.addToClipboardEvent.emit(this.selectedChartConfig);
	}

	exportChartImage(){
		if(this.useNativeImageExport){
			this.chartExportService.exportImage(this.chartExport);
		} else {
			this.exportChartImageEvent.emit(this);
		}
	}

	removeFromDataKit() {
		this.removeDataKitComponent.emit(this);
	}

	openE(){
		this.openDevInfoDialogEvent.emit(this);
	}

	public selectChart() {
		const index = this.selectOptions.indexOf(this.selectedOption);
		this.selectedChartConfig = this.chartConfigs[index];
		this.refreshData();
	}
}
