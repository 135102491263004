import { Component, ViewChild, Input } from '@angular/core';
import { MatTabNav } from '@angular/material/tabs';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';

@Component({
	selector: 'trella-tabs',
	templateUrl: './tabs.component.html',
	styleUrls: ['./tabs.component.scss']
})
export class TabsComponent extends ComponentWithSubscription {
	@Input() tabSections = [];
	@Input() localStorageKey: string;
	@Input() makeSticky = false;

	@ViewChild('tabs', { static: true }) tabGroup: MatTabNav;

	constructor() {
		super();
	}

	saveNewTab(idTab: string) {
		localStorage.setItem(this.localStorageKey, idTab);
	}
}
