import { Component, Input, Output, ChangeDetectorRef } from '@angular/core';

import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { FilterSelection, Filter } from '../../shared/models/filter';
import { KeyValue } from '@angular/common';
import { FilterSelectionService } from '../../shared/services/filter-selection.service';

@Component({
	selector: 'trella-filter-selection-dropdown',
	templateUrl: './filter-selection-dropdown.component.html',
	styleUrls: ['./filter-selection-dropdown.component.scss']
})
export class FilterSelectionDropdownComponent extends ComponentWithSubscription {
	private filter: Filter;
	private key: string;
	label?: string;

	constructor(private filterSelection: FilterSelectionService, private cdr: ChangeDetectorRef) {
		super();
	}

	@Input() set selections(selections: FilterSelection) {
		this.filter = selections.filter;
		this.key = selections.key;
		this.label = selections.label;
		this.options = selections.values;

		if (this.options && this.options.length && !this.selection) {
			this.selection = this.options.find(o => o.key === localStorage.getItem(this.key)) || this.options[0];
		}
	}

	@Output() options: KeyValue<string, string>[];

	selection: KeyValue<string, string>;

	selectFilter() {
		const filter = { ...this.filter };
		filter.modelJson = this.selection.value;
		localStorage.setItem(this.key, this.selection.key);
		this.filterSelection.select(filter, this.key);
	}
}
