import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Target, ChartConfig } from '../../shared/chart/chart';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { CHART_TYPE } from './chart-type';


@Component({
	selector: 'trella-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
	@ViewChild('chartimage', { static: false }) public chartImage: any;
	@Input() chartInfo: ChartConfig;
	@Input() dataChange$: Observable<ChartConfig>;
	@Input() activeLegendItems = [];
  	@Input() target: Target;

	@Output() clickEvent: EventEmitter<any> = new EventEmitter();
	@Output() activeLegendItemsChange = new EventEmitter();

	_dataChange$ = new Subject<ChartConfig>();
	CHART_TYPE = CHART_TYPE;

	ngOnInit() {
		this.dataChange$.subscribe(chartInfo => {
			this._dataChange$.next(chartInfo);
		});
	}

	onClickEvent(options: any) {
		this.clickEvent.emit(options);
	}
}
