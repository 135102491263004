import { isNumber } from 'highcharts';
import { Utils } from '../../shared/Utils';
import { CHART_DATA_TYPE } from './chart-data-type';

export class ChartUtils {
	static getFormattedChartTooltipType(value: number, dataType: string) {
		if (dataType === CHART_DATA_TYPE.percent) {
			return value <= 0 ? '' : '%';
		}

		if (dataType === CHART_DATA_TYPE.currency) {
			return '$';
		}

		return '';
	}

	static getChartDisplayedValue(value: any, formattedDataType: string) {
		if (value === undefined) {
			return '';
		}

		if (formattedDataType === '$') {
			return this.getFormattedCurrencyValue(value);
		}

		if (isNumber(value) && value >= 0) {
			return value.toLocaleString() + formattedDataType;
		}

		return Utils.getDisplayedValue(value, '') + formattedDataType;
	}

	static getFormattedCurrencyValue(value: string) {
		const style = {
			style: 'currency',
			currency: 'USD'
		};
		return isNaN(+value)
			? value
			: Number(value)
					.toLocaleString('en-US', style)
					.replace('.00', '');
	}

	static getFormattedPercentValue(value: string) {
		return `${value.toLocaleString()}%`;
	}
}
