// This is different from Angular's SimpleChange as it adds generic type T
export interface SimpleChange<T> {
	firstChange: boolean;
	previousValue: T;
	currentValue: T;
	isFirstChange: () => boolean;
}

// This is a simple @OnChange decorator that came from a sample online
// It caches a value with a get/set and creates the isFirstChangeKey based on
// if this is the first time the value is set.
// NOTE: The callback is fired on every change event and the parent must check
// to see if it is the first event or not.
export function OnChange<T = any>(callback: (value: T, simpleChange?: SimpleChange<T>) => void) {
	const cachedValueKey = Symbol();
	const isFirstChangeKey = Symbol();
	return (target: any, key: PropertyKey) => {
		Object.defineProperty(target, key, {
			set(value) {
				// change status of "isFirstChange"
				if (this[isFirstChangeKey] === undefined) {
					this[isFirstChangeKey] = true;
				} else {
					this[isFirstChangeKey] = false;
				}
				// No operation if new value is same as old value
				if (!this[isFirstChangeKey] && this[cachedValueKey] === value) {
					return;
				}
				const oldValue = this[cachedValueKey];
				this[cachedValueKey] = value;
				const simpleChange: SimpleChange<T> = {
					firstChange: this[isFirstChangeKey],
					previousValue: oldValue,
					currentValue: this[cachedValueKey],
					isFirstChange: () => this[isFirstChangeKey]
				};
				callback.call(this, this[cachedValueKey], simpleChange);
			},
			get() {
				return this[cachedValueKey];
			}
		});
	};
}

// This decorator is a simplified version of @OnChange that assumes we do NOT
// want to invoke the callback if it is the first change.
export function OnChangeAfterFirst<T = any>(callback: (value: T, simpleChange?: SimpleChange<T>) => void) {
	const cachedValueKey = Symbol();
	const isFirstChangeKey = Symbol();
	return (target: any, key: PropertyKey) => {
		Object.defineProperty(target, key, {
			set(value) {
				// change status of "isFirstChange"
				if (this[isFirstChangeKey] === undefined) {
					this[isFirstChangeKey] = true;
				} else {
					this[isFirstChangeKey] = false;
				}
				// No operation if new value is same as old value
				if (!this[isFirstChangeKey] && this[cachedValueKey] === value) {
					return;
				}
				const oldValue = this[cachedValueKey];
				this[cachedValueKey] = value;

				// Do not call change callback if it is the first change
				if (this[isFirstChangeKey]) {
					return;
				}

				const simpleChange: SimpleChange<T> = {
					firstChange: this[isFirstChangeKey],
					previousValue: oldValue,
					currentValue: this[cachedValueKey],
					isFirstChange: () => this[isFirstChangeKey]
				};
				callback.call(this, this[cachedValueKey], simpleChange);
			},
			get() {
				return this[cachedValueKey];
			}
		});
	};
}

// This is a simple decorator that fires on any change event
// and does not track if it is the first occurance or not.
export function OnAnyChange<T = any>(callback: (value: T) => void) {
	const cachedValueKey = Symbol();
	return (target: any, key: PropertyKey) => {
		Object.defineProperty(target, key, {
			set(value) {
				// No operation if new value is same as old value
				if (this[cachedValueKey] === value) {
					return;
				}
				const oldValue = this[cachedValueKey];
				this[cachedValueKey] = value;
				callback.call(this, this[cachedValueKey]);
			},
			get() {
				return this[cachedValueKey];
			}
		});
	};
}

// This decorator requires this.ready to be defined and set to true
// It only invokes the callback if this.ready==true (typically set at the end of ngOnInit)
// NOTE: If this.ready is not defined on the component, the callback will never get called
export function OnChangeAfterReady<T = any>(callback: (value: T) => void) {
	const cachedValueKey = Symbol();
	return (target: any, key: PropertyKey) => {
		Object.defineProperty(target, key, {
			set(value) {
				// No operation if new value is same as old value
				if (this[cachedValueKey] === value) {
					return;
				}
				const oldValue = this[cachedValueKey];
				this[cachedValueKey] = value;
				// If "this.ready" is not true, then init has not happened yet
				if (!this.ready) {
					return;
				}
				callback.call(this, this[cachedValueKey]);
			},
			get() {
				return this[cachedValueKey];
			}
		});
	};
}
