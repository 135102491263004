import { Component, Input } from '@angular/core';
import { Utils } from '../../../shared/Utils';
import { ChartDataElement } from '../chart-data-series.model';
import { CHART_TYPE } from '../chart-type';
import { CHART_DATA_TYPE } from '../chart-data-type';
import { ChartUtils } from '../chart-utils';

@Component({
	selector: 'trella-chart-tooltip',
	templateUrl: './chart-tooltip.component.html'
})
export class ChartTooltipComponent {
	@Input() data: ChartDataElement[];
	@Input() chartType: CHART_TYPE;
	@Input() chartDataType: CHART_DATA_TYPE;
	@Input() category: string;
	@Input() points: any;

	getSpecialValue(point: any) {
		if (!point || !point.series) {
			return '';
		}

		const label = point.series.name;
		const dataSet = this.data.find(d => d.label === label);
		const rawValue = dataSet.data[point.categoryIndex];

		if (!rawValue) {
			// TODO: need a better way, very hacky
			return dataSet.data.value;
		}

		// need to get the symbol based of the data type of the chart (empty string or '%')
		const dataTypeSymbol = ChartUtils.getFormattedChartTooltipType(rawValue, this.chartDataType);
		return ChartUtils.getChartDisplayedValue(rawValue, dataTypeSymbol);
	}

	getLegendColor(point: any) {
		if (!point) {
			return '';
		}

		return point.series.color;
	}

	getLegendName(point: any) {
		if (!point) {
			return '';
		}

		return point.series.name;
	}
}
