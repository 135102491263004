import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'trella-data-eula',
	templateUrl: './data-eula.component.html',
	styles: [
		`
			ol li {
				list-style: decimal;
				padding-top: 10px;
				margin-left: 20px;
			}
		`
	]
})
export class DataEulaComponent implements OnInit {
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onAccept: EventEmitter<boolean> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onDecline: EventEmitter<boolean> = new EventEmitter();

	constructor() {}

	ngOnInit() {}

	accept() {
		this.onAccept.emit();
	}

	decline() {
		this.onDecline.emit();
	}
}
