import { Component, EventEmitter, Host, Input, Output } from '@angular/core';
import { FilterGroupService } from '../../../shared/services/filter-group.service';
import { BaseFilterComponent } from '../base-filter.component';
import { FILTER_TYPE } from '../../../shared/enums/filter-type.enum';
import { getRangeFilterNameByType, RangeFilterType } from '../../../shared/models/ranges/range-filter-type';
import { RangeFilterRulesJson } from '../../../shared/models/ranges/range-filter-rules-json';
import { ChangeContext } from 'ng5-slider';
import { RangeSelection } from '../../../shared/models/ranges/range-selection';
import { RangeFilterRules } from '../../../shared/models/ranges/range-filter-rules';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'trella-range',
	templateUrl: './range.component.html',
	styleUrls: ['./range.component.scss']
})
export class RangeComponent extends BaseFilterComponent {
	@Input() set rules(rules: RangeFilterRulesJson[]) {
		this._rules = rules.map(r => new RangeFilterRules(r));
	}
	@Input() rangeSelections: RangeSelection[] = [];

	private _rangeChanged: EventEmitter<RangeSelection[]> = new EventEmitter<RangeSelection[]>();
	@Output() rangeChanged: Observable<RangeSelection[]> = this._rangeChanged.asObservable().pipe(debounceTime(1250));

	@Input() definitionFactory: (FILTER_TYPE) => string;
	get getDefinition(): string {
		return this.definitionFactory(this.filterType) ?? '';
	}

	@Input() titleFactory: (FILTER_TYPE) => string;
	get getTitle(): string {
		return (this.titleFactory && this.titleFactory(this.filterType)) || 'Range Filters';
	}
	
	private _rules: RangeFilterRules[];

	filterType: FILTER_TYPE = FILTER_TYPE.RANGE;
	getRangeFilterNameByType = getRangeFilterNameByType;

	constructor(protected filterGroupService: FilterGroupService) {
		super(filterGroupService);
	}

	get getCount(): number {
		const selected = [];

		this.rangeSelections.forEach(rangeSelection => {
			const rule = this.getRules(rangeSelection);
			if (rangeSelection.minSelected !== rule.minAllowed || rangeSelection.maxSelected !== rule.maxAllowed) {
				selected.push(rangeSelection);
			}
		});

		return selected.length;
	}

	getRules(selection: RangeSelection) {
		return this._rules.find(f => f.name === selection.name);
	}

	handleUserChange(changeContext: ChangeContext, name: RangeFilterType) {
    this.rangeSelections = this.rangeSelections.map(x => {
      if(x.name === name){
        x.minSelected = changeContext.value;
        x.maxSelected = changeContext.highValue;
      }
      return x;
    });
    this._rangeChanged.emit(this.rangeSelections);

	}
}
