import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FILTER_TYPE } from '../enums/filter-type.enum';

@Injectable({
	providedIn: 'root'
})
export class FilterGroupService {
	private _resetRequested = new Subject<void>();
	private _filtersToggled = new Subject<FILTER_TYPE>();

	public resetRequested = this._resetRequested.asObservable();
	public filtersToggled = this._filtersToggled.asObservable();

	resetFilters() {
		this.toggleFilters(null);
		this._resetRequested.next();
	}

	toggleFilters(filterType: FILTER_TYPE) {
		this._filtersToggled.next(filterType);
	}
}
