import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { NavButtonColors, NavigationDirection } from '../constants';
import { NavButtonContent } from '../models/nav-button-content';

@Directive({
	selector: '[mosaic-navigation-button]'
})
export class NavigationButtonDirective implements OnChanges {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@Input('mosaic-navigation-button') content: NavButtonContent;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.content) {
			this.clearClasses();
			this.renderer.setStyle(this.el.nativeElement, 'color', this.getColor(this.content.isEnabled));
			if (this.content.isEnabled) {
				this.renderer.addClass(this.el.nativeElement, 'pointer');
			}

			this.renderer.addClass(this.el.nativeElement, 'far');
			this.renderer.addClass(this.el.nativeElement, this.getIcon());

			const classesToAdd = ['fa-2x', 'mx-3', 'page-button'];
			classesToAdd.forEach(c => this.renderer.addClass(this.el.nativeElement, c));
		}
	}

	clearClasses() {
		const classesToRemove = [...this.el.nativeElement.classList] as string[];
		classesToRemove.forEach(c => this.renderer.removeClass(this.el.nativeElement, c));
	}

	getColor(isEnabled: boolean) {
		return isEnabled ? NavButtonColors.Enabled : NavButtonColors.Disabled;
	}

	getIcon() {
		switch (this.content.direction) {
			case NavigationDirection.Back:
				return 'fa-chevron-circle-left';
			case NavigationDirection.Forward:
			default:
				return 'fa-chevron-circle-right';
		}
	}
}
