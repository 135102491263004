import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { isEqual } from 'lodash';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { Utils } from '../../shared/Utils';
import { DemographicCardOptions, DemographicCardPermissions } from './aco-demographic-card.model';
import { Aco } from '../../shared/models/aco/aco';
import { MedicalEntityTypeConfig } from '../../shared/models/medical-entity-type-config';
import { DemographicCardType } from '../demographic-card/demographic-card.model';

const PHYSICIAN_FACILITY_DATAKIT_HOVER = 'Create Plan My Visit Presentation';
const AGENCY_DATAKIT_HOVER = 'Create Comparison Presentation';
@Component({
	selector: 'trella-aco-demographic-card',
	templateUrl: './aco-demographic-card.component.html',
	styleUrls: ['./aco-demographic-card.component.scss']
})
export class AcoDemographicCardComponent extends ComponentWithSubscription implements OnChanges {
	@Input() config: MedicalEntityTypeConfig;
	@Input() info: Aco;
  @Input() options: DemographicCardOptions;
	@Input() permissions: DemographicCardPermissions;
	@Input() dataUpdate = false;



	@Output() refreshNpiInfoEmitter: EventEmitter<any> = new EventEmitter();
	@Output() saveEmitter: EventEmitter<any> = new EventEmitter();


	pristineInfo: Aco;
	open = false;
	editMode = false;

	get icon() {
		return this.config && this.config.icon;
	}

	get color() {
		return this.config && this.config.colorClass;
	}

	get canSave() {
		return this.info;
	}

	constructor() {
		super();
	}

	ngOnChanges() {
		if (!this.config || !this.info || !this.options || this.open) {
			return;
		}
		this.open = false;
		this.refreshNpiInfo();
	}



	refreshNpiInfo() {
		this.refreshNpiInfoEmitter.emit();
	}

	toggleDetails() {
		this.open = !this.open;
	}

	toggleEditMode() {
    return;
	}

	save() {
		this.saveEmitter.emit();
		this.editMode = false;
	}

	cancel() {
		this.info = Utils.deepClone(this.pristineInfo);
		this.editMode = false;
	}

  formatMarkets(markets){
    return markets?.join(', ') ?? '';
  }

}
