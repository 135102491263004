import { RangeFilterRulesJson } from './range-filter-rules-json';
import { RangeFilterType } from './range-filter-type';

export class RangeSelection {
	minSelected?: number;
	maxSelected?: number;
	name: RangeFilterType;

	constructor(rules: RangeFilterRulesJson) {
		this.name = rules?.name;
		this.minSelected = rules?.minAllowed;
		this.maxSelected = rules?.maxAllowed;
	}
}
