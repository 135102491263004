import { Injectable } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject } from 'rxjs';
import { GridSortEvent } from '../models/grid/grid-sort-event';
import { Keyed } from '../models/keyed';

@Injectable({
	providedIn: 'root'
})
export class GridSortService {
  private _sortRequested = new BehaviorSubject<Keyed<GridSortEvent>>(null);
  readonly sortRequested = this._sortRequested.asObservable();

  sort(key: string, sort: SortDescriptor[], isRefreshRequested: boolean = false){
    this._sortRequested.next({value: {sortDescriptors: sort, isRefreshRequested}, key});
  }
}
