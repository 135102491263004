import { Input, Directive, HostListener, Output, EventEmitter } from '@angular/core';
@Directive({
	selector: 'div[searchContainer]'
})
export class SearchResultContainerDirective {
	@Input() searchContainer: string;
	@Output() searchContainerChange = new EventEmitter<string>();

	constructor() {}

	@HostListener('click', ['$event'])
	onclick(event: MouseEvent) {
		this.searchContainer = '';
		this.searchContainerChange.emit(this.searchContainer);
	}
}
