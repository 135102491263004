import { KeyValue } from '@angular/common';

export class Filter {
	modelJson: string;
	filterType: string;
	paramName?: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}

export class FilterSelection {
	filter: Filter;
	key: string;
	label?: string;
	values: KeyValue<string, string>[];
}

export class FilterEvent {
	filter: Filter;
	key: string;
}
