import {
	Component,
	ElementRef,
	OnInit,
	Output,
	EventEmitter,
	Input,
	SimpleChanges,
	ViewChild,
	OnChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import $ from 'jquery';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NpiSearchResult } from '../../shared/models/npi/npi-search-result';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SearchItem } from '../../shared/models/search/search-item';

@Component({
	selector: 'trella-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})
export class SearchComponent extends ComponentWithSubscription implements OnInit, OnChanges {
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onError: EventEmitter<void> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onSearch: EventEmitter<any> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onItemSelect: EventEmitter<NpiSearchResult> = new EventEmitter();
	@Input() searchResults: SearchItem[];
	@Input() showSpinner: boolean;
	@Input() removeOnSelect = true;
	@Input() leaveOpen = true;
	@Input() makeSticky = false;
	@ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;
	@ViewChild('searchInput') searchInput: ElementRef;

	inputChangeSub = new Subject<string>();
	myControl = new FormControl();
	searchText: string;
	selectedPhysSpecialty = '';
	@Input() searchPlaceholderText = 'Search by NPI Number or Name';
	@Input() showOutline = false;
	@Input() showSpecialty = false;
	@Input() physSpecalties: object;
	@Output() specialtyChange: EventEmitter<string> = new EventEmitter();

	get rightSpinnerElement() {
		return $(this.element.nativeElement).find('.right-spinner');
	}

	constructor(private element: ElementRef) {
		super();
	}


	ngOnInit() {

		this.inputChangeSub.pipe(takeUntil(this.ngUnsubscribe), debounceTime(500)).subscribe(
			async search => {
				search = search.trim();
				if (!search) {
					return;
				}
				this.showSpinner = true;
				await this.onInputChangeInternal(search);
			},
			err => {
				this.onError.emit();
			}
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.showSpinner = false;
	}

	onChange(search: string) {
		this.searchText = search;
		this.inputChangeSub.next(search);
	}

	stopSpinner() {
		this.showSpinner = false;
	}

	private async onInputChangeInternal(search: string) {
		this.onSearch.emit(search);
	}


	selectItem(event: MatAutocompleteSelectedEvent) {
		const info = event.option.value;

		this.myControl.setValue(this.searchText);
		if (this.removeOnSelect) {
			this.searchResults = this.searchResults.filter(x => x !== info); // TODO: change to remove
		}
		this.onItemSelect.emit(info);
		if (this.leaveOpen) {
			setTimeout(() => this.resetAutoCompleteState(), 1);
		} else {
			// hide list AND blur the selection component
			setTimeout(() => this.blurAfterSelect(), 1);
		}
	}

	blurAfterSelect() {
		this.searchInput.nativeElement.blur();
	}

	resetAutoCompleteState() {
		this.autoComplete.openPanel();
	}

	getUniqueNpiProvider(array) {
		const set = new Set();

		return array
			.map((v, index) => {
				const key = v.npi + '|' + v.provider;

				if (set.has(key)) {
					return false;
				} else {
					set.add(key);
					return index;
				}
			})
			.filter(e => e)
			.map(e => array[e]);
	}

	onSpecialtyChange() {
		this.specialtyChange.emit(this.selectedPhysSpecialty);
		this.searchResults.length = 0;
		if (!this.searchText) {
			return;
		}
		this.showSpinner = true;
		this.inputChangeSub.next(this.searchText);
		this.autoComplete.openPanel();
	}
}
