import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { AgmMap, MarkerManager } from '@agm/core';
import { MapMarker } from '../../shared/models/map-marker';
import { NpiType } from '../../shared/enums/npi-type';

declare const google: any;

@Component({
	selector: 'trella-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChild(AgmMap) map: AgmMap;
  @Input() markers: MapMarker[] = [];
  @Input() isLoading = false;
  NpiType = NpiType;
	markerManager: MarkerManager;
	styledMapType = [
		{
			featureType: 'administrative.province',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#006747' }]
		},
		{
			featureType: 'administrative.locality',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#006747' }]
		},
		{
			featureType: 'administrative.neighborhood',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#006747' }]
		},
		{
			featureType: 'landscape.natural',
			elementType: 'labels.text.fill',
			stylers: [{ color: '#43B02A' }]
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{
					lightness: 100
				},
				{
					visibility: 'simplified'
				}
			]
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					visibility: 'on'
				},
				{
					color: '#C6E2FF'
				}
			]
		},
		{
			featureType: 'poi',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#C5E3BF'
				}
			]
		},
		{
			featureType: 'road',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#D1D1B8'
				}
			]
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [
				{
					color: '#FFC72C'
				}
			]
		},
		{
			featureType: 'road.highway.controlled_access',
			elementType: 'geometry',
			stylers: [
				{
					color: '#FF8200'
				}
			]
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					color: '#A8AD00'
				}
			]
		}
  ];

  zoom = 8;
  // 3340 Peachtree Rd NE
  // Atlanta, GA 30326
	lat = 33.8475500020057;
  lng = -84.37097545623895;

	constructor() {}

	ngOnInit() {}

	ngAfterViewInit() {
    this.map.boundsChange.pipe(debounceTime(100)).subscribe(bounds => this.handleBoundsChange(bounds));
	}

	onMouseOver(tooltip) {
		tooltip.open();
  }

	onMouseOut(tooltip) {
		tooltip.close();
	}

	handleBoundsChange(bounds: google.maps.LatLngBounds) {
		this.markers.forEach(m => (m.visible = bounds.contains(m)));
	}
}
