import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { remove } from 'lodash';
import { MultiSelectItem } from '../../shared/multi-select-item';
import { MousetrapService } from '../../shared/services/mousetrap.service';
@Component({
	selector: 'trella-multi-select-list-box',
	templateUrl: './multi-select-list-box.component.html',
	styleUrls: ['./multi-select-list-box.component.scss']
})
export class MultiSelectListBoxComponent implements OnInit {
	@Input() source: MultiSelectItem[] = [];
	@Input() destination: MultiSelectItem[] = [];
	@Input() sourceListTitle = ''; // title for source list
	@Input() destinationListTitle = ''; // title for destination list
	@Input() sourceSearchable = false;
	@Input() destinationSearchable = false;
	@Input() height = '200px';
	@Output() listChange: EventEmitter<MultiSelectItem[]> = new EventEmitter();

	lastSourceClick: MultiSelectItem;
	lastDestinationClick: MultiSelectItem;

	selectedSourceItems: MultiSelectItem[] = [];
	selectedDestinationItems: MultiSelectItem[] = [];

	searchSource: string;
	searchDestination: string;

	shift = false;
	ctrl = false;

	get displaySelectedSourceItemDisplay() {
		return this.selectedSourceItems.length > 0 && this.lastSourceClick;
	}

	get selectedSourceItemDisplay() {
		if (!this.selectedSourceItemDescription) {
			return this.lastSourceClick.display;
		}
		return `${this.lastSourceClick.display} (${this.selectedSourceItemDescription})`;
	}

	get selectedSourceItemDescription() {
		return this.lastSourceClick && this.lastSourceClick.description;
	}

	get displaySelectedDestinationItemDisplay() {
		return this.selectedDestinationItems.length && this.lastDestinationClick;
	}

	get selectedDestinationItemDisplay() {
		if (!this.selectedDestinationItemDescription) {
			return this.lastDestinationClick.display;
		}

		return `${this.lastDestinationClick.display} (${this.selectedDestinationItemDescription})`;
	}

	get selectedDestinationItemDescription() {
		return this.lastDestinationClick && this.lastDestinationClick.description;
	}

	constructor(private mousetrapService: MousetrapService) {}

	ngOnInit() {
		this.mousetrapService.ctrl$.subscribe(ctrl => (this.ctrl = ctrl));
		this.mousetrapService.shift$.subscribe(shift => (this.shift = shift));
	}

	highlightSourceItem(item: MultiSelectItem) {
		if (!this.lastSourceClick && (this.shift || this.shift)) {
			return;
		}

		if (this.shift) {
			this.selectedSourceItems = [];
			if (this.source.indexOf(this.lastSourceClick) < this.source.indexOf(item)) {
				for (let i = this.source.indexOf(this.lastSourceClick); i <= this.source.indexOf(item); i++) {
					this.selectedSourceItems.push(this.source[i]);
				}
			}
			if (this.source.indexOf(this.lastSourceClick) > this.source.indexOf(item)) {
				for (let i = this.source.indexOf(item); i <= this.source.indexOf(this.lastSourceClick); i++) {
					this.selectedSourceItems.push(this.source[i]);
				}
			}
			return;
		}

		if (this.ctrl) {
			if (!this.selectedSourceItems.includes(item)) {
				this.selectedSourceItems.push(item);
				this.lastSourceClick = item;
				return;
			}

			remove(this.selectedSourceItems, item);
			this.lastSourceClick = null;
			return;
		}

		this.selectedSourceItems = [];
		this.selectedSourceItems.push(item);
		this.lastSourceClick = item;
		return;
	}

	highlightDestinationItem(item: MultiSelectItem) {
		if (!this.lastDestinationClick && (this.shift || this.shift)) {
			return;
		}
		if (this.shift) {
			this.selectedDestinationItems = [];

			if (this.destination.indexOf(this.lastDestinationClick) < this.destination.indexOf(item)) {
				for (let i = this.destination.indexOf(this.lastDestinationClick); i <= this.destination.indexOf(item); i++) {
					this.selectedDestinationItems.push(this.destination[i]);
				}
			}
			if (this.destination.indexOf(this.lastDestinationClick) > this.destination.indexOf(item)) {
				for (let i = this.destination.indexOf(item); i <= this.destination.indexOf(this.lastDestinationClick); i++) {
					this.selectedDestinationItems.push(this.destination[i]);
				}
			}
			return;
		}

		if (this.ctrl) {
			this.selectedDestinationItems.push(item);
			this.lastDestinationClick = item;
			return;
		}
		this.selectedDestinationItems = [];
		this.selectedDestinationItems.push(item);
		this.lastDestinationClick = item;
	}

	moveToDestinationList() {
		if (!this.selectedSourceItems.length) {
			return;
		}

		// add selected item to source, along with existing items already there
		this.destination = this.destination.concat(this.selectedSourceItems);

		// remove from destination list
		this.selectedSourceItems.forEach(source => {
			remove(this.source, source);
		});

		// due to virtual scrolling, need to re-set list object
		this.source = [...this.source];

		// sort the items
		this.destination.sort((a, b) => a.display.localeCompare(b.display));

		this.selectedSourceItems = [];

		this.listChange.emit(this.destination);
	}

	moveToSourceList() {
		if (!this.selectedDestinationItems.length) {
			return;
		}
		// add selected item to destination, along with existing items already there
		this.source = this.source.concat(this.selectedDestinationItems);

		// remove from source list
		this.selectedDestinationItems.forEach(destination => {
			remove(this.destination, destination);
		});

		// due to virtual scrolling, need to re-set list object
		this.destination = [...this.destination];

		// sort the items
		this.source.sort((a, b) => a.display.localeCompare(b.display));

		this.selectedDestinationItems = [];

		this.listChange.emit(this.destination);
	}
}
