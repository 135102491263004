import { CheckedSelection } from './checked-selection';
import { Selection } from './selection';
import { Node } from './tree-filter/tree-filter-node';
import { Specialty } from './specialty';
import { YearsAco } from './aco/years-aco';

export class Utils {
	static getCheckedSelections(selections: Selection[]): CheckedSelection[] {
		if (!selections) {
			return [];
		}
		return selections.map(s => ({ ...s, checked: false }));
	}

	static convertToTree = (specialties: Specialty[]): Node[] => {
		const nodes = [];
		for (const specialty of specialties) {
			const node = Object.assign(new Node(), {
				display: specialty.name,
				value: specialty.name,
				parent: null,
				children: []
			});
			for (const subspecialty of specialty.subspecialties) {
				const subNode = Object.assign(new Node(), {
					display: subspecialty.name,
					value: subspecialty.taxonomyCode,
					parent: node,
					children: []
				});
				node.children.push(subNode);
			}
			nodes.push(node);
		}
		return nodes;
	};

	static convertAcosToTree = (years: YearsAco[]): Node[] => {
		const nodes = [];
		for (const year of years) {
			const node = Object.assign(new Node(), {
				display: year.year.toString(),
				value: year.year.toString(),
				parent: null,
				children: []
			});
			for (const aco of year.acos) {
				const subNode = Object.assign(new Node(), {
					display: aco.name,
					value: `${aco.id};${year.year}`,
					parent: node,
					children: []
				});
				node.children.push(subNode);
			}
			nodes.push(node);
		}
		return nodes;
	};

	static convertToCheckedSelections = (specialties: Specialty[]): CheckedSelection[] => {
		const selections = [];
		for (const specialty of specialties) {
			const selection = {
         display: specialty.name,
         id: specialty.name,
         checked: false
			} as CheckedSelection;

			for (const subspecialty of specialty.subspecialties) {
				const child = {
            display: subspecialty.name,
            id: subspecialty.taxonomyCode,
            checked: false
        } as CheckedSelection;
				selections.push(child);
			}
			selections.push(selection);
		}
		return selections;
	};
}
