import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterGroupService } from '../../../shared/services/filter-group.service';
import { BaseFilterComponent } from '../base-filter.component';
import { FILTER_TYPE } from '../../../shared/enums/filter-type.enum';
import { RangeFilterType, getRangeFilterNameByType } from '../../../shared/models/ranges/range-filter-type';
import { ChangeContext } from 'ng5-slider';
import { RangeFilterRules } from '../../../shared/models/ranges/range-filter-rules';
import { SelectionsWithRange } from '../../../shared/models/selections-with-range';
import { SelectionsWithRangeSelection } from '../../../shared/models/selections-with-range-selection';
import { CheckedSelection } from '../../../shared/models/checked-selection';
import { Observable } from 'rxjs';

@Component({
	selector: 'trella-affiliation',
	templateUrl: './affiliation.component.html',
	styleUrls: ['./affiliation.component.scss']
})
export class AffiliationComponent extends BaseFilterComponent {
	@Input() set affiliationRules(affiliations: SelectionsWithRange) {
		if (affiliations) {
			this.rangeRules = new RangeFilterRules(affiliations.range);
		}
	}

	@Input() set currentAffiliations(affiliations: SelectionsWithRangeSelection) {
		this.selectedAffiliations = {
			items: affiliations.items,
			range: { ...affiliations.range }
		};
	}

	@Input() definitionFactory: (FILTER_TYPE) => string;
	get getDefinition(): string {
		return this.definitionFactory(this.filterType) ?? '';
	}

	@Input() titleFactory: (FILTER_TYPE) => string;
	get getTitle(): string {
		return (this.titleFactory && this.titleFactory(this.filterType)) || 'Facility Affiliation';
	}

	get getCount(): number {
		return this.selectedAffiliations?.items?.filter(i => i.checked).length || 0;
	}

	rangeRules: RangeFilterRules;
	selectedAffiliations: SelectionsWithRangeSelection;

	filterType: FILTER_TYPE = FILTER_TYPE.AFFILIATIONS;
	rangeFilterName = getRangeFilterNameByType(RangeFilterType.LEVEL_OF_AFFILIATION);

	private _affiliationsChanged: EventEmitter<SelectionsWithRangeSelection> = new EventEmitter<SelectionsWithRangeSelection>();
	@Output() affiliationsChanged: Observable<SelectionsWithRangeSelection> = this._affiliationsChanged.asObservable();

	constructor(protected filterGroupService: FilterGroupService) {
		super(filterGroupService);
	}

	handleRangeChange(changeContext: ChangeContext) {
		const range = this.selectedAffiliations.range;
		range.maxSelected = changeContext.highValue;
		range.minSelected = changeContext.value;

		this._affiliationsChanged.emit({
			...this.selectedAffiliations,
			range
		});
	}

	handleSelectedAffiliationsChange() {
		this._affiliationsChanged.emit(this.selectedAffiliations);
	}

	getRangeMin() {
		return this.selectedAffiliations?.range.minSelected || 0;
	}

	getRangeMax() {
		return this.selectedAffiliations?.range.maxSelected || 100;
	}
}
