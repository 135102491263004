import { Component, ViewChild, Input, TemplateRef, OnInit } from '@angular/core';
import $ from 'jquery';
import { InfoCircleService } from '../../shared/services/info-circle.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
	selector: 'trella-info-circle',
	templateUrl: './info-circle.component.html',
	styleUrls: ['./info-circle.component.scss']
})
export class InfoCircleComponent implements OnInit {
	@ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
	@ViewChild('definitionTemplate') public definitionTemplate: TemplateRef<any>;

	@Input() darkBackground = false;
	@Input() tooltipTemplate: TemplateRef<any>;
	@Input() titleTemplate: TemplateRef<any>;
	@Input() position = 'top';
	@Input() tooltipText = '';

	constructor(private infoCircleService: InfoCircleService) {}
	selected = false;

	ngOnInit() {
		this.infoCircleService.deSelect$.subscribe(deSelected => {
			this.selected = deSelected;
			this.tooltipDir.hide();
		});
	}

	select(event: any) {
		const toolTip = $(event.currentTarget);
		if (!this.tooltipTemplate && !this.titleTemplate) {
			toolTip.attr('title', this.tooltipText);
		}
		if (!this.selected) {
			this.infoCircleService.deSelect();
		}

		if(!this.tooltipTemplate){
			this.tooltipDir.template = this.definitionTemplate;
		}

		this.selected = !this.selected;
		this.tooltipDir.toggle(event.currentTarget, this.selected);
		const elements = document.getElementsByClassName('k-i-close');
		Array.from(elements).forEach(element => {
			element.removeAttribute('href');
			element.className += ' pointer'; // Supports IE9
			element.addEventListener('click', x => this.infoCircleService.deSelect());
		});
	}
}
