import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'trella-eula',
	templateUrl: './eula.component.html',
	styleUrls: ['./eula.component.scss']
})
export class EulaComponent implements OnInit {
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onUpdateEula: EventEmitter<boolean> = new EventEmitter();
	constructor() {}

	ngOnInit() {}

	updateEula(choice: boolean) {
		this.onUpdateEula.emit(choice);
	}
}
