import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import $ from 'jquery';
import { remove } from 'lodash';
import { NpiGroup } from '../../shared/npi-group';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { NpiSearchResult } from '../../shared/models/npi/npi-search-result';
import { SearchComponent } from '../search/search.component';

@Component({
	selector: 'trella-comparisons-dialog',
	templateUrl: './comparisons-dialog.component.html',
	styleUrls: ['./comparisons-dialog.component.scss']
})
export class ComparisonsDialogComponent extends ComponentWithSubscription implements OnInit {
	@Input() height = '100px';
	@Input() npi: string;
	@Input() comparisons: NpiSearchResult[];
	@Input() max = 7;
	@Input() npiSearchResults: NpiSearchResult[];
	@Input() totalCostOfCareType: 'phys' | 'snf' | 'hha' | 'inp'; // TotalCostOfCareType
	@Input() physSpecalties: object;
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onSave: EventEmitter<any> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onCancel: EventEmitter<any> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onSearch: EventEmitter<string> = new EventEmitter();
	@Output() specialtyChange: EventEmitter<string> = new EventEmitter();
	@ViewChild(SearchComponent) public searchComponent: SearchComponent;

	warning: string;
	opened = false;
	npiGroups: NpiGroup[];
	searchString: string;

	selectedNpiGroup: NpiGroup;
	committedComparisons: NpiSearchResult[] = [];

	title = 'Comparisons';

	get divOverlayGroupsElement() {
		return $(this.element.nativeElement).find('#groupsOverlay');
	}

	get centerSpinnerGroupsElement() {
		return $(this.element.nativeElement).find('#groupsSpinner');
	}

	get canSave() {
		return this.committedComparisons.length;
	}

	get disableSearch() {
		return this.areMaxComparisonsSelected();
	}

	search(searchString: string) {
		if (this.areMaxComparisonsSelected()) {
			this.warning = 'Max Selected Comparisons is ' + this.max;
			this.searchComponent.stopSpinner();
			return;
		} else {
			this.onSearch.emit(searchString);
		}
	}

	constructor(private element: ElementRef) {
		super();
	}

	ngOnInit() {
		this.title = this.getFullTitle();
	}

	getFullTitle() {
		switch (this.totalCostOfCareType) {
			case 'phys':
				return 'Physicians';
			case 'inp':
				return 'Inpatient Facilities';
			case 'snf':
				return 'Skilled Nursing Facilities';
			case 'hha':
				return 'Home Health Facilities';
		}
	}

	areMaxComparisonsSelected(): boolean {
		return this.committedComparisons.length >= this.max;
	}

	stopSpinner() {
		this.searchComponent.stopSpinner();
	}

	open() {
		this.opened = true;
		this.committedComparisons = this.comparisons ? [...this.comparisons] : [];
	}

	cancel() {
		this.onCancel.emit();
		this.opened = false;
	}

	ok() {
		if (!this.canSave) {
			return;
		}
		// return comparisons to consuming component
		this.onSave.emit(this.committedComparisons);
		this.opened = false;
	}

	selectGroup(npiGroup: NpiGroup) {
		this.selectedNpiGroup = npiGroup;
	}

	selectNpi(npi: NpiSearchResult) {
		if (this.areMaxComparisonsSelected()) {
			this.warning = 'Max Selected Comparisons is ' + this.max;
			return;
		}

		if (this.committedComparisons.some(x => x.npi === npi.npi)) {
			this.warning = 'Npi already selected.';
			return;
		}

		this.warning = '';
		this.committedComparisons.push(npi);
	}

	removeFromList(npi: NpiSearchResult) {
		this.warning = '';
		remove(this.committedComparisons, npi);
	}

	// Need this for external parent component to get these. Possible emit event instead?
	getSelectedNpis() {
		return this.committedComparisons;
	}

	removeUnmatchedSpecialties(specialty: string) {
		this.specialtyChange.emit(specialty);
		if (!specialty) {
			return;
		}
		this.committedComparisons = this.committedComparisons.filter(x => x.specialty === specialty);
	}

	showSpecialty() {
		switch (this.totalCostOfCareType) {
			case 'phys':
				return true;
			default:
				return false;
		}
	}
}
