import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { isEqual } from 'lodash';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { Utils } from '../../shared/Utils';
import { Assignee } from '../../shared/models/npi/assignee';
import { Npi } from '../../shared/models/npi/npi';
import { DemographicCardOptions, DemographicCardPermissions } from './demographic-card.model';
import { NpiTypeConfig } from '../../shared/models/npi/npi-type-config';
import { GridInfo } from '../../shared/grid-info';
import { Subject } from 'rxjs';
import { NpiType } from '../../shared/enums/npi-type';

const PHYSICIAN_FACILITY_DATAKIT_HOVER = 'Create Plan My Visit Presentation';
const AGENCY_DATAKIT_HOVER = 'Create Comparison Presentation';
@Component({
	selector: 'trella-demographic-card',
	templateUrl: './demographic-card.component.html',
	styleUrls: ['./demographic-card.component.scss']
})
export class DemographicCardComponent extends ComponentWithSubscription implements OnChanges {
	@Input() assignee: Assignee;
	@Input() dataUpdate = false;
	@Input() info: Npi;
	@Input() isFavorite = false;
	@Input() isInNpiGroup = false;
	@Input() isMyAgency = false;
	@Input() isTarget = false;
	@Input() key = '';
	@Input() npiTypeConfig: NpiTypeConfig;
	@Input() options: DemographicCardOptions;
	@Input() permissions: DemographicCardPermissions;
	@Input() staffConfig: GridInfo;

	@Output() removeDataKitEmitter: EventEmitter<any> = new EventEmitter();
	@Output() addToCartChartEmitter: EventEmitter<any> = new EventEmitter();
	@Output() refreshNpiInfoEmitter: EventEmitter<any> = new EventEmitter();
	@Output() refreshNpiAssigneeInfoEmitter: EventEmitter<any> = new EventEmitter();
	@Output() saveEmitter: EventEmitter<any> = new EventEmitter();
	@Output() createPresentationEmitter: EventEmitter<any> = new EventEmitter();
	@Output() toggleTargetEmitter: EventEmitter<any> = new EventEmitter();
	@Output() toggleFavoriteEmitter: EventEmitter<any> = new EventEmitter();
	@Output() toggleNpiGroupEmitter: EventEmitter<any> = new EventEmitter();
	@Output() deleteNpiAssignee: EventEmitter<any> = new EventEmitter();
	@Output() removeUserEmitter: EventEmitter<string> = new EventEmitter();
	@Output() addUserEmitter: EventEmitter<any> = new EventEmitter();
	@Output() addKeyStaffEmitter: EventEmitter<any> = new EventEmitter();

	pristineInfo: Npi;
	open = false;
	editMode = false;
	staffDataChange$: Subject<GridInfo> = new Subject();
	noRating = false;

	customDataKitHover: string;
	customStarRatingHover: string;

	get icon() {
		return this.npiTypeConfig && this.npiTypeConfig.icon;
	}

	get color() {
		return this.npiTypeConfig && this.npiTypeConfig.colorClass;
	}

	get displayName() {
		return this.info && this.info.display;
	}

	get county() {
		return this.info && `${this.info.county} County`;
	}

	get state() {
		return this.info && this.info.state;
	}

	get city() {
		return Utils.convertToPascalCase(this.info && this.info.city);
	}

	get showMedicalDirector() {
		return this.info && this.info.medicalDirector;
	}

	get isMultiNPIFacility() {
		return this.info && this.info.multiNpiFacility;
	}

	get specialty() {
		return this.info && this.info.specialty;
	}

	get subSpecialty() {
		// deal with duplicates
		const npiSpecialty = this.info && this.info.specialty;
		const npiSubSpecialty = this.info && this.info.subSpecialty;
		// specifically did not use ===
		// eslint-disable-next-line eqeqeq
		return npiSpecialty != npiSubSpecialty ? `/ ${npiSubSpecialty}` : '';
	}

	get canSave() {
		return this.info;
	}

	get noAssignee() {
		return !this.assignee;
	}

	get assigneeUserName() {
		return this.assignee && this.assignee.userName;
	}

	constructor() {
		super();
	}

	ngOnChanges() {
		if (!this.npiTypeConfig || !this.info || !this.options || this.open) {
			return;
		}

		this.open = false;
		this.noRating = this.npiTypeConfig.npiType === NpiType.physician || this.npiTypeConfig.npiType === NpiType.hospice;
		this.customDataKitHover =
			this.npiTypeConfig.npiType === NpiType.physician ? PHYSICIAN_FACILITY_DATAKIT_HOVER : AGENCY_DATAKIT_HOVER;
		this.customStarRatingHover = this.info.rating > 0 ? `${this.info.rating} Stars` : 'No Star Rating Available for this NPI';
		this.refreshNpiInfo();
		if (this.options.displayAssignees) {
			this.refreshNpiAssigneeInfo();
		}
	}

	refreshStaff() {
		if (!this.open || !this.options.displayStaff) {
			return;
		}

		this.staffDataChange$.next(this.staffConfig);
	}

	addUser() {
		this.addUserEmitter.emit();
	}

	removeUser(id: string) {
		this.removeUserEmitter.emit(id);
	}

	async addToCartChart() {
		this.addToCartChartEmitter.emit();
	}

	refreshNpiInfo() {
		this.refreshNpiInfoEmitter.emit();
	}

	refreshNpiAssigneeInfo() {
		this.refreshNpiAssigneeInfoEmitter.emit();
	}

	toggleDetails() {
		this.open = !this.open;
	}

	toggleEditMode() {
		if (!this.info) {
			return;
		}

		if (this.editMode) {
			// Edit mode is already open. Can only close if nothing changed
			if (isEqual(this.info, this.pristineInfo)) {
				this.editMode = false;
				return;
			}
			// this.userFeedbackService.showWarning('There are unsaved NPI info changes. Please save or cancel before continuing.');
			return;
		}

		// Edit mode is currently off. Turn it on
		this.pristineInfo = Utils.deepClone(this.info);
		this.editMode = true;
		this.open = true;
	}

	save() {
		this.saveEmitter.emit();
		this.editMode = false;
	}

	cancel() {
		this.info = Utils.deepClone(this.pristineInfo);
		this.editMode = false;
	}

	createPresentation() {
		this.createPresentationEmitter.emit(this.info);
	}

	toggleTarget() {
		if (!this.options.editTarget) {
			return;
		}
		this.toggleTargetEmitter.emit(this.info.npi);
	}

	toggleTargetParams() {
		return {
			selectedNpis: [this.info.npi],
			providerType: this.npiTypeConfig.npiType
		};
	}

	toggleFavorite() {
		if (!this.options.editFavorite) {
			return;
		}
		this.toggleFavoriteEmitter.emit(this.info.npi);
	}

	toggleNpiGroup() {
		if (!this.options.editNpiGroup) {
			return;
		}
		this.toggleNpiGroupEmitter.emit(this.info);
	}

	removeFromDataKit() {
		if (!this.options.removeDataKit) {
			return;
		}
		this.removeDataKitEmitter.emit();
	}

	addKeyStaff() {
		this.addKeyStaffEmitter.emit(this.info);
	}
}
