import { SharedConfigurationFields } from './sharedConfigurationFields';
import { CHART_TYPE } from '../../lib/chart/chart-type';
import { CHART_DATA_TYPE } from '../../lib/chart/chart-data-type';
import { ChartConfigJson, InsightConfigJson } from '../report-config';
import { LabelRotation } from '@progress/kendo-angular-charts';

export class ChartConfig extends SharedConfigurationFields {
	constructor(config: ChartConfigJson, reportName: string) {
		super();
		if (!config) {
			return;
		}

		Object.assign(this, config);

		this.reportName = reportName;
		this.title = config.reportName || reportName;

		this.displayName = config.displayName || this.title;
		this.type = config.type as CHART_TYPE;
		this.dataType = config.dataType as CHART_DATA_TYPE;
	}

	hideDisplayName?: boolean;
	showNameExpanded?: boolean;
	type: CHART_TYPE;
	adapter?: string;
	dataType?: CHART_DATA_TYPE;
	wrapText?: boolean;
	maxCharacters?: number;
	showLegend?: boolean;
	notes?: string;
	text?: string;
	toolTip: string;
	formatter?: string;

	// column chart
	stacked?: boolean;
	sumStack?: boolean;

	// move to appropriate class
	xLabel?: string;
	yLabel?: string;
	plotOptions?: any;

	// insights
	decimalPoints?: number;
	categoriesField?: any;
	npiType?: string;
	rating?: string;

	// Chart
	reportName: string;
	displayName: string;
	linkedTableReports?: string[];

	key?: string;
	value?: string;
	groups?: ChartGroupModel[];

  target?: Target;

	npiField?: string;
	xField?: string;
	yField?: string;
	xAxisField?: string;
	xAxisLabelRotation?: number | LabelRotation | 'auto';

	// sunburst chart
	colorMap?: any;
	colorField?: string;

	seriesNameFields?: string;
	valueFields?: string[];
	radius?: string;
	colors?: string[];
	labels?: string[];

	// categories: string[];
	data?: any;
	chartOptions?: any;
	title?: string;
}

export class Target {
  value: string;
  color?: string;
  title?: string;
}

export class InsightOptions extends ChartConfig {
	constructor(config: InsightConfigJson, reportName: string) {
		super(config, reportName);

		Object.assign(this, config);
	}

	insightDisplayType: string;
	isHigherBetter: boolean;
	isNeutral: boolean;
	riskCategory?: string;
	sourceNpiName?: string;
	tooltip?: string;
}

export interface ChartGroupModel {
	label?: string; // see tcc_chart_snf_adj.json for example
	field?: string; // see tcc_chart_snf_adj.json for example
	name?: string; // TODO: still needed?
	data?: any; // TODO: still needed?
	valuePrefix?: string;
}
