import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class InfoCircleService {
	private deSelectChange$ = new Subject<boolean>();
	public deSelect$ = this.deSelectChange$.asObservable();

	constructor() {}

	deSelect() {
		this.deSelectChange$.next(false);
	}
}
