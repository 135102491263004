import { Component,  OnInit, ViewChild } from '@angular/core';
import { BaseChart } from '../base-chart';
import { last } from 'lodash';
import { ChartComponent, SeriesLabels } from '@progress/kendo-angular-charts';
import { ChartDataSeries, ChartDataElement } from '../../chart-data-series.model';
import { ChartConfig } from '../../../../shared/chart/chart';
import { CHART_DATA_TYPE } from '../../chart-data-type';
import { FormattedData } from '../../../../shared/models/grid/formatted-data';
import { RAW_VALUE } from '../../../../shared/constants';

@Component({
	selector: 'trella-column-chart',
	templateUrl: './column-chart.component.html',
	styleUrls: ['./column-chart.component.scss']
})
export class ColumnChartComponent extends BaseChart implements OnInit {
	@ViewChild('kendoimage') kendoImage: ChartComponent;

	rotation = { angle: 0, align: 'end' };
	type: any;
	seriesLabel: SeriesLabels = {
		visible: true,
		padding: 3,
		font: 'bold 16px Arial, sans-serif',
		content: x => this.formatDisplayValue(x.stackValue)
	};

	constructor() {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
		if (!this.data || !this.data.categories || !this.data.categories.length) {
			return;
		}
		this.rotation = { angle: 0, align: 'end' };
	}

	isLast(data: ChartDataSeries) {
		const currentActiveSeries = this.activeLegendItems.length ? this.activeLegendItems : this.data.data.map(x => x.label);
		return data === last(this.data.data.filter(x => currentActiveSeries.includes(x.label)));
	}

	readData(chartInfo: ChartConfig): ChartDataSeries {
		super.readData(chartInfo);
		const barChartData: ChartDataElement[] = [];
		const data = chartInfo.data;
		const categoriesField = chartInfo.categoriesField;
		const valueFields = chartInfo.groups.map(x => {
			return {
				key: x.field,
				label: x.label
			};
		});
		// for each valueField, push a data entry
		valueFields.forEach(field => {
			barChartData.push({
				data: data.map(d => d[field.key].formattedValue || d[field.key]),
				rawData: data.map(d => this.formatValue(d[field.key])),
				label: field.label
			} as ChartDataElement);
		});

		// The categories are the number of data point
		const categories = data.map(d => d[categoriesField] && (d[categoriesField].value || d[categoriesField]));
		const validData = this.validateData(barChartData);
		return {
			data: barChartData,
			categories,
			isValidData: validData
		};
	}

	// TODO: These can probably be abstract methods
	formatValue(value: FormattedData) {
		switch (this.options.dataType) {
			case CHART_DATA_TYPE.percent:
				return +value.value <= 0 ? 0 : +value.value;
			case CHART_DATA_TYPE.count:
				return this.getFormattedCountValue(value);
			default:
				return this.getFormattedCountValue(value);
		}
	}

	getFormattedCountValue(value: FormattedData) {
		const valueToRead = value.value || value;
		if (valueToRead === RAW_VALUE.negativeOne) {
			return 5;
		} else if (+valueToRead <= 0) {
			return 0;
		} else {
			return +valueToRead;
		}
	}

	validateData = (chartData: ChartDataElement[]) => this.validateRange(chartData);
	postDataRead() {
		// no op
	}

	handleOptionsChange(config: ChartConfig) {
		// no op
	}
}
