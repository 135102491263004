import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, Renderer2, NgZone, OnInit } from '@angular/core';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import {
	ColumnVisibilityChangeEvent,
	DataStateChangeEvent,
	GridComponent,
	GridDataResult,
	RowArgs,
	RowClassArgs,
	SelectionEvent
} from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import $ from 'jquery';
import { round } from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil, debounceTime } from 'rxjs/operators';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { GridAbilities } from '../../shared/grid-abilities';
import { GridColumn, GRID_COLUMN_TYPE, DataSubType, GRID_DATA_TYPE } from '../../shared/grid-column';
import { ACTION_TYPE, AGENCY_PROVIDER_TYPES, DISPLAY_VALUE, GRID_ACTION_TYPE, NavigationDirection } from '../../shared/constants';
import { GridInfo } from '../../shared/grid-info';
import { QueryOptions } from '../../shared/query-options';
import { GridCommandInfo } from '../../shared/grid-command-info';
import { GRID_COMMAND } from '../../shared/grid-command';
import { Command } from '../../shared/command-button';
import { Utils } from '../../shared/Utils';
import { GridActionService } from '../../shared/services/grid-action.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FormattedData } from '../../public_api';
import { GridExportService } from '../../shared/services/grid-export.service';
import { GridExport } from '../../shared/models/grid/grid-export';
import { GridSortService } from '../../shared/services/grid-sort.service';
import { GridService } from '../../shared/services/grid.service';
import { FormattedResult } from '../../shared/models/grid/formatted-result';
import { ReplaceValueMappingService } from '../../shared/services/replacement-value-mapping.service';
import { ProviderIds } from '../../shared/models/npi/provider-ids';

const tableRow = node => node.tagName.toLowerCase() === 'tr';
const closest = (node, predicate) => {
	while (node && !predicate(node)) {
		node = node.parentNode;
	}
	return node;
};
const ELLIPSIS_CSS = ['fal', 'fa-ellipsis-v', 'fa-lg'];
const DRAGDROPABOVE_CSS = 'dragDropAbove';
const DRAGDROPBELOW_CSS = 'dragDropBelow';

@Component({
	selector: 'trella-grid',
	templateUrl: './trella-grid.component.html',
	styleUrls: ['./trella-grid.component.scss']
})
export class TrellaGridComponent extends ComponentWithSubscription implements OnChanges, OnInit {
	@ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
	@ViewChild(GridComponent) grid: GridComponent;

	@Input() addActionText = 'Add';
	@Input() customerId = 0;
	@Input() disabled = false;

	get gridInfo() {
		return this._gridInfo;
	}

	@Input() set gridInfo(info: GridInfo) {
		this._gridInfo = info;
		if (info) {
			this.queryOptions = new QueryOptions();
			this.queryOptions.skip = info.skip;
			this.queryOptions.take = info.take;
			this.gridService.reportQueryOptions(this.key, this.queryOptions);
		}
	}
	@Input() key = '';
	@Input() reportName: string;

	@Input() rowLinkFactory: (key: Record<string, FormattedData>, info: GridInfo) => string;

	@Input() userFavorites: unknown[] = [];
	@Input() userTargets: unknown[] = [];
	// TODO: Move these inputs to GridOptions in the config
	@Input() allowAdd = false;
	@Input() hasShowSelectedToggle = false;
	@Input() showAssigneesButton = false;
	@Input() showComparisonsButton = false;
	@Input() showFavoritesButton = false;
	@Input() showSelected = false;
	@Input() showTargetsButton = false;
	@Input() showUnAssigneeButton = false;
	@Input() childGrid = false;

	NavigationDirection = NavigationDirection;

	private _currentPage = 1;
	private _searchTextDebounce = new Subject<string>();
	private _gridInfo: GridInfo;

	allowGridStateChange = true;
	allowEButton = false;
	currentTarget: any;
	dataResponse: GridDataResult;
	footer: FormattedResult[] = [];
	queryOptions?: QueryOptions = new QueryOptions();
	rowIsSelectable = false;
	searchText: string;
	selectedRows: string[] = [];
	selectionsCount: number;
	timeout = null;
	selectedProviders: ProviderIds[] = [];

	dropIndex; // need to store the location of the dropTarget
	dragItem; // the item that we are dragging
	dragStartIndex; // the index of the dragItem

	favoriteHoverText = 'Click here to add selected providers to Favorites';
	targetHoverText = 'Click to add selected providers to Targets';
	comparisonHoverText = 'Click here to add selected providers to a Custom List';
	assigneeHoverText = 'Click here to add Assigned User';
	removeAssigneeHoverText = 'Click here to remove Assigned User';

	constructor(
		public element: ElementRef,
		private renderer: Renderer2,
		private zone: NgZone,
		private gridActionService: GridActionService,
		private gridExportService: GridExportService,
		private gridSortService: GridSortService,
		private gridService: GridService,
		private replaceValueMappingService: ReplaceValueMappingService
	) {
		super();
	}
	/**
	 * This is the height of each row. Used for the virtual scroller to help calculate it's paging.
	 */
	get rowHeight() {
		// current row height for grids using virtual scroller - will need to pass in as option if this changes. (Targets dashboard grid)
		return this.gridInfo && this.gridInfo.gridAbilities && this.gridInfo.gridAbilities.scrollable === 'virtual' && 37;
	}

	get gridAbilities() {
		return this.gridInfo && this.gridInfo.gridAbilities;
	}

	set gridAbilities(val) {
		if (this.gridInfo) {
			this.gridInfo.gridAbilities = val;
			this.rowIsSelectable =
				this.gridInfo.gridAbilities && this.gridInfo.gridAbilities.selectable && this.gridInfo.gridAbilities.selectable.enabled;
		}
	}

	get enableCheckbox() {
		return this.gridAbilities.selectable && this.gridAbilities.selectable.enabled && !this.gridAbilities.hideSelectableCheckbox;
	}

	get canLock(){
		return  this.gridInfo && this.gridInfo.columns && (this.gridInfo.columns.filter(c => !c.hidden && !c.locked).length > 1) && !this.hasDetailRows;
	}

	get createListParams() {
		return {
			selectedNpis: this.selectedRows,
			selectedNpiNames: this.gridInfo && this.dataResponse.data.filter(n => this.selectedRows.includes(n.npi)).map(n => n.npiName),
			providerType: this.gridInfo && this.gridInfo.npiType,
			queryOptions: this.gridInfo && this.queryOptions,
			reportName: this.gridInfo && this.gridInfo.reportName
		};
	}

	set selections(selections: string[]) {
		this.selectedRows = selections;
		this.gridService.select(this.key, selections);
	}

	get showNpiGroupModal() {
		return (
			this.gridInfo &&
			this.enableListModals &&
			AGENCY_PROVIDER_TYPES.includes(this.gridInfo.npiType) &&
			this.gridInfo.gridOptions.showComparisonsButton
		);
	}

	get showMasterSearch() {
		return this.gridInfo && this.gridInfo.showMasterSearch;
	}

	get enableListModals() {
		return this.gridAbilities && this.gridAbilities.selectable && this.gridAbilities.selectable.enabled;
	}

	get showAddFavoritesButton() {
		return this.enableListModals && this.gridInfo.gridOptions.showFavoritesButton;
	}

	get showAddTargetsButton() {
		return this.enableListModals && this.gridInfo.gridOptions.showTargetsButton;
	}

	get showClearSortButton() {
		return this.hasSortFields();
	}

	get hasDetailRows() {
		return this.gridInfo && this.gridInfo.hasDetailRows;
	}

	get hasPostNotes() {
		return this.gridInfo && this.gridInfo.postNotes && this.gridInfo.postNotes.details;
	}

	get numberSelected() {
		const count = this.selectionsCount || (this.selections && this.selections.length);
		return count && count > 0 ? `(${count})` : undefined;
	}

	viewBtn = new Command({
		show: () => this.gridAbilities.viewable,
		execute: (rowIndex, dataItem, $event) => this.command({ command: GRID_COMMAND.view, rowIndex, dataItem, sourceEvent: $event })
	});

	editBtn = new Command({
		show: () => this.gridAbilities.editCommand,
		execute: (rowIndex, dataItem, $event) => this.command({ command: GRID_COMMAND.edit, rowIndex, dataItem, sourceEvent: $event })
	});

	deleteBtn = new Command({
		show: () => this.gridAbilities.deleteRow,
		execute: (rowIndex, dataItem, $event) => this.command({ command: GRID_COMMAND.delete, dataItem, rowIndex, sourceEvent: $event })
	});

	ngOnInit() {
		this._searchTextDebounce.pipe(takeUntil(this.ngUnsubscribe), debounceTime(800)).subscribe(searchTextValue => {
			this.gridService.search(this.key, searchTextValue);
		});

		this.gridActionService.gridPaginated.pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000)).subscribe(_ => {
			this._currentPage = 1;
		});

		this.subscribe(this.gridSortService.sortRequested, this.key, event => {
			if (event?.sortDescriptors && this.gridInfo && this.queryOptions) {
				this.queryOptions.sort = event.sortDescriptors;
				this.gridService.reportQueryOptions(this.key, this.queryOptions);
				if (event.isRefreshRequested) {
					this.gridService.requestRefresh(this.key, this.gridInfo);
				}
			}
		});

		this.subscribe(this.gridService.dataFetched, this.key, response => {
			if (response) {
				this.dataResponse = response;
				this.collapseAll();
			}
		});

		this.subscribe(this.gridService.footerFetched, this.key, footer => {
			this.footer = footer;
		});

		this.subscribe(this.gridService.selectionRequested, this.key, selections => {
			this.selections = selections;
		});

		this.subscribe(this.gridService.selectionCountRequested, this.key, selectionsCount => {
			this.selectionsCount = selectionsCount;
		});

		this.subscribe(this.gridService.eButtonPermissionChecked, this.key, isAllowed => {
			this.allowEButton = isAllowed;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		// merge to use defaults
		// TODO: This fires too many times
		this.gridAbilities = Object.assign(new GridAbilities(), this.gridAbilities);
	}

	command(e: GridCommandInfo) {
		this.gridService.issueCommand(this.key, e);
	}

	columnChange(e: ColumnVisibilityChangeEvent) {
		this.gridInfo.mergeColumnChangeEvent(e);
	}

	isFilterable() {
		return this.gridAbilities.filterable && this.hasFilterableColumn();
	}

	goBack() {
		this._currentPage = Math.max(1, this._currentPage - 1);
		this.updatePage();
	}

	goForward() {
		this._currentPage++;
		this.updatePage();
	}

	updatePage() {
		const oldSkip = this.queryOptions.skip;
		const newSkip = this.queryOptions.take * (this._currentPage - 1);

		if (oldSkip !== newSkip) {
			this.queryOptions.skip = newSkip;
			this.gridService.reportQueryOptions(this.key, this.queryOptions);
			this.gridService.requestRefresh(this.key, this.gridInfo);
		}
	}

	hasFilterableColumn() {
		return this.gridInfo.columns.some(x => x.filterable);
	}

	isFavoriteColumn(col: GridColumn) {
		return col && col.favoriteable;
	}

	isFavorite(data: unknown) {
		const npi = data && this.gridInfo.npiField && data[this.gridInfo.npiField];
		const currentFavorites = this.userFavorites as any[];
		return npi && currentFavorites && currentFavorites.find(i => i.npi === npi && i.providerType === this.gridInfo.npiType);
	}

	toggleFavorite(dataItem: unknown) {
		const npi = dataItem && this.gridInfo.npiField && dataItem[this.gridInfo.npiField];
		const providerType = this.gridInfo.npiType;
		this.gridService.toggle(this.key, [npi, providerType, GRID_ACTION_TYPE.FAVORITE]);
	}

	isTargetColumn(col: GridColumn) {
		return col && col.targetable;
	}

	isTarget(data: unknown) {
		const npi = data && this.gridInfo.npiField && data[this.gridInfo.npiField];
		const currentTargets = this.userTargets as any[];
		return npi && currentTargets && currentTargets.find(i => i.npi === npi && i.providerType === this.gridInfo.npiType);
	}

	toggleTarget(dataItem: unknown) {
		const npi = dataItem && this.gridInfo.npiField && dataItem[this.gridInfo.npiField];
		if (!npi) {
			return;
		}

		const npiType = this.gridInfo.npiType;
		this.gridService.toggle(this.key, [npi, npiType, GRID_ACTION_TYPE.TARGET]);
	}

	clearTextFilter() {
		this.searchText = '';
		this._searchTextDebounce.next(this.searchText);
	}

	triggerTextFilter() {
		this._searchTextDebounce.next(this.searchText);
	}

	getColDefinition(kendoColumn: GridColumn) {
		return kendoColumn && kendoColumn.definition;
	}

	createCustomDefinition(column: GridColumn) {
		let customDefinition;
		if (column && column.definition) {
			customDefinition = this.replaceValueMappingService.runReplacement(column.definition);
			(column.replacements || []).forEach(x => {
				const replacementUrl = `<a class='definitionColor' target='_blank' href='http://${x.url}'>${x.title}</a>`;
				customDefinition = customDefinition.replace(new RegExp(`@${x.word}`, 'g'), replacementUrl);
			});
		}
		return customDefinition || column.title;
	}

	addFavoriteTargetEventListeners() {
		// TODO
	}

	getCustomColumnSubtitle(column: GridColumn): string[] {
		if (!column || !column.sparklineCol || !column.sparklineCol.subtitles) {
			return [];
		}

		return column.sparklineCol.subtitles;
	}

	columnNeedsBorder(col: GridColumn) {
		if (!col) {
			return false;
		}
		return !col.targetable;
	}

	public rowCallback = (context: RowClassArgs) => {
		return this.getRowCallBack(context);
	};

	public getRowCallBack = (context: RowClassArgs) => {
		return {
			dragging: context.dataItem.dragging,
			thRowSelectable: this.rowIsSelectable
		};
	};

	public handleDragAndDrop() {
		if (!this.gridInfo.gridAbilities.isDragAndDrop) {
			return;
		}
		const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
		tableRows.forEach(item => {
			if ((item as any).draggable === true) {
				return;
			}
			this.renderer.setAttribute(item, 'draggable', 'true');
			// If we need to throttle these, we can easily use the lodash throttle function.
			item.addEventListener('dragenter', (event: DragEvent) => this.handleDragEnter(event));
			item.addEventListener('dragleave', (event: DragEvent) => this.handleDragLeave(event));
			item.addEventListener('dragstart', (event: DragEvent) => this.handleDragStart(event));
			item.addEventListener('dragend', (event: DragEvent) => this.handleDragEnd(event));
			item.addEventListener('mouseenter', (event: DragEvent) => this.handleMouseOver(event));
			item.addEventListener('mouseleave', (event: DragEvent) => this.handleMouseLeave(event));
		});
	}

	// Used in Html
	public clearSort() {
		if (this.queryOptions && this.queryOptions.sort) {
			this.queryOptions.sort = this.queryOptions.sort = [];

			this.gridService.reportQueryOptions(this.key, this.queryOptions);
			this.gridService.requestRefresh(this.key, this.gridInfo);
		}
	}

	private handleDragStart(event: DragEvent) {
		const { dataTransfer, target } = event;

		// Firefox won't drag without setting data
		dataTransfer.setData('application/json', '');

		const row: HTMLTableRowElement = target as HTMLTableRowElement;
		this.dropIndex = row.rowIndex;
		this.dragStartIndex = row.rowIndex;

		const dataItem = this.dataResponse.data[this.dropIndex];
		dataItem.dragging = true;
		this.dragItem = dataItem;
	}

	private handleDragEnter(event: DragEvent) {
		event.preventDefault();
		const target = closest(event.target, tableRow);
		this.dropIndex = target.rowIndex;

		target.classList.add(this.getApplicableDragDropCssClass());
	}

	private handleDragLeave(event: DragEvent) {
		event.preventDefault();
		const row = closest(event.target, tableRow);
		this.dropIndex = row.rowIndex;

		row.classList.remove(DRAGDROPABOVE_CSS, DRAGDROPBELOW_CSS);
	}

	private handleDragEnd(event: DragEvent) {
		event.preventDefault();
		const row = closest(event.target, tableRow);
		const data = this.dataResponse.data;
		const iconElement = this.getInnerIconElement(row);
		iconElement.classList.remove(...ELLIPSIS_CSS);

		let dropIndex = this.dropIndex;
		if (this.isStartIndexLessThanDropIndex(dropIndex)) {
			dropIndex++;
		}

		let targetAtDropIndex = data[dropIndex];

		// handle something being dropped at end of list
		// need to incrememnt position, of prev element.
		let positionModifier = 0;
		if (!targetAtDropIndex) {
			targetAtDropIndex = data[dropIndex - 1];
			positionModifier = 1;
		}

		if (this.dragItem === targetAtDropIndex) {
			return;
		}

		data.splice(dropIndex, 0, { ...this.dragItem, dragging: false }); // insert item
		if (data.find(item => item === this.dragItem)) {
			data.splice(data.indexOf(this.dragItem), 1); // remove item
		}

		// get "real position" - it may have changed if we are moving things around in the grid
		// TODO: output dragEvent
		// const targetServiceTarget = this.targetsService.allTargets.find(target => target.targetId === targetAtDropIndex.targetId)
		// this.targetsService.move({
		// 	targetId: this.dragItem.targetId,
		// 	newPosition: targetServiceTarget.position + positionModifier
		// })
	}

	private handleMouseOver(event: DragEvent) {
		event.preventDefault();
		const row = this.getRowFromEvent(event);
		const iconEl = this.getInnerIconElement(row);

		if (!iconEl) {
			return;
		}

		iconEl.classList.add(...ELLIPSIS_CSS);
	}

	private handleMouseLeave(event: MouseEvent) {
		event.preventDefault();
		const row = this.getRowFromEvent(event);
		const iconEl = this.getInnerIconElement(row);

		if (!iconEl) {
			return;
		}

		iconEl.classList.remove(...ELLIPSIS_CSS);
	}

	private getRowFromEvent(event: MouseEvent | DragEvent): HTMLTableRowElement {
		const { target } = event;
		const row: HTMLTableRowElement = target as HTMLTableRowElement;
		return row;
	}

	private getInnerIconElement(row: HTMLTableRowElement): HTMLElement {
		return row.querySelector('i.ellipsis');
	}

	private isStartIndexLessThanDropIndex(dropIndex: number): boolean {
		return this.dragStartIndex < dropIndex;
	}

	private getApplicableDragDropCssClass(): string {
		if (!this.isStartIndexLessThanDropIndex(this.dropIndex)) {
			return DRAGDROPABOVE_CSS;
		} else {
			return DRAGDROPBELOW_CSS;
		}
	}

	dataStateChange(state: DataStateChangeEvent): void {
		if (this.allowGridStateChange) {
			this.queryOptions = state;
			this.gridService.reportQueryOptions(this.key, this.queryOptions);
			this.gridService.requestRefresh(this.key, this.gridInfo);
		}

		this.zone.onStable.pipe(take(1)).subscribe(() => this.handleDragAndDrop());
	}

	// TODO: all this formatting code will be ripped out in favor of a server side solution.
	getDisplayedValue(value: any | FormattedData, column: GridColumn) {
		// TODO: Can we return the full value in the value field and have the formattedValue field contain just the text?
		// Maybe a "map" transform
		if (!column) {
			return;
		}

		if (column.enumType) {
			return;
		}

		if ((value || {}).formattedValue || (value || {}).formattedValue === '') {
			return value.formattedValue;
		}

		if (column) {
			if (Utils.doesFormatFunctionExist(column.dataType)) {
				const transformFunc = Utils.getFormatFunction(column.dataType);
				return transformFunc(value.formattedValue);
			}
		}

		if (!Utils.exists(column.format) || !Utils.exists(value) || Utils.isSpecialValue(value)) {
			return value;
		}

		if (value <= 0) {
			return Utils.getDisplayedValue(value, column.format);
		}

		if (column.format.indexOf('%') > -1) {
			// Turn into percent
			value = this.getPercentValueFromDecimal(value);

			if (!value) {
				// Value is special. Handle this generically
				return Utils.getDisplayedValue(value, column.format);
			}

			return value.toFixed(column.decimalPoints || 2) + '%';
		}

		if (column.format.indexOf('<') > -1 && value < 11 && value > 0) {
			// check for values <11
			return DISPLAY_VALUE.lessThanEleven;
		}

		if (column.format.includes('I')) {
			value = round(value);
		}

		if (column.decimalPoints) {
			return parseFloat(value).toFixed(column.decimalPoints);
		}

		if (column.format.indexOf('1') > -1) {
			// Decimal with 1 digit after dot
			return parseFloat(value).toFixed(1);
		}

		if (column.dataType === GRID_DATA_TYPE.integer && column.dataSubType === DataSubType.currency) {
			const currencyFormatter = new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: Number.isInteger(column.decimalPoints) ? column.decimalPoints : 2
			});

			return currencyFormatter.format(value);
		}

		return Utils.getDisplayedValue(value, column.format);
	}

	hasSortFields(): boolean {
		return !!(this.queryOptions && this.queryOptions.sort && this.queryOptions.sort.filter(sort => sort.dir).length);
	}

	getPercentValueFromDecimal(value: any): number {
		if (!Utils.exists(value) || Utils.isSpecialValue(value) || value <= 0) {
			return 0;
		}

		return value * 100;
	}

	getSparklineArray(dataItem: any, col: GridColumn) {
		if (!dataItem || !col || !col.sparklineCol || !col.sparklineCol.fields) {
			return [];
		}

		const sparklineFields = col.sparklineCol.fields;
		return sparklineFields.map(field => (dataItem[field] < 0 ? 0 : dataItem[field]));
	}

	getRawSparklineArray(dataItem: any, col: GridColumn) {
		if (!dataItem || !col || !col.sparklineCol || !col.sparklineCol.fields) {
			return [];
		}

		const sparklineFields = col.sparklineCol.fields;
		return sparklineFields.map(field => dataItem[field]);
	}

	getProgressBarClass(dataItem: any, col: GridColumn): string {
		const neutralColor = 'bg-primary';

		if (!dataItem || !col || !col.comparisonBar) {
			return neutralColor;
		}

		const comparison = col.comparisonBar;
		if (dataItem[comparison.comparedFromColumn] <= 0) {
			return neutralColor;
		}

		const finalCompareFrom = Number((dataItem[comparison.comparedFromColumn] * 100).toFixed(1));
		const finalCompareTo = Number((dataItem[comparison.comparedToColumn] * 100).toFixed(1));

		const compareOperators: string[] = comparison.comparison.split(',');

		let lessThanCounter = 0;
		let greaterThanCounter = 0;
		let equalToCounter = 0;

		compareOperators.forEach(operator => {
			if (finalCompareFrom < finalCompareTo && operator === '<') {
				// compared from value is less than compared to value
				lessThanCounter++;
			} else if (finalCompareFrom > finalCompareTo && operator === '>') {
				// compared from value is greater than compared to value
				greaterThanCounter++;
			} else {
				// compared from value is equal to compared to value
				equalToCounter++;
			}
		});

		return lessThanCounter > 0 ? 'bg-success' : greaterThanCounter > 0 ? 'bg-danger' : neutralColor;
	}

	isDefaultCol(col: GridColumn) {
		return !col.clickable && !col.sparklineCol && !col.comparisonBar && !col.favoriteable && !col.targetable;
	}

	isClickableCol(col: GridColumn) {
		return col.clickable;
	}

	isComparisonCol(col: GridColumn) {
		return col.comparisonBar;
	}

	isSparklineCol(col: GridColumn) {
		return col.sparklineCol;
	}

	openCreateFavoriteListModal() {
		this.gridService.issueCommand(this.key, {
			command: GRID_COMMAND.favorite,
			dataItem: this.createListParams
		} as GridCommandInfo);
	}

	openCreateTargetListModal() {
		this.gridService.issueCommand(this.key, {
			command: GRID_COMMAND.target,
			dataItem: this.createListParams
		} as GridCommandInfo);
	}

	openCreateAssigneeListModal() {
		this.gridService.issueCommand(this.key, {
			command: GRID_COMMAND.assignee,
			dataItem: this.createListParams,
			sourceEvent: ACTION_TYPE.ADD
		} as GridCommandInfo);
	}

	openRemoveAssigneeModal() {
		this.gridService.issueCommand(this.key, {
			command: GRID_COMMAND.assignee,
			dataItem: this.createListParams,
			sourceEvent: ACTION_TYPE.REMOVE
		} as GridCommandInfo);
	}

	openAddToNpiGroupModal() {
		this.gridService.issueCommand(this.key, {
			command: GRID_COMMAND.npiGroup,
			dataItem: this.createListParams
		} as GridCommandInfo);
	}

	getGridDataRowNpi(data): string {
		return data && data[this.gridInfo.keyField] && (data[this.gridInfo.keyField].value || data[this.gridInfo.keyField]);
	}

	getGridDataRowNpiName(data): string {
		return data && ((data['npiName'] && (data['npiName'].value || data['npiName'])) 
		|| (data['npiname'] && (data['npiname'].value || data['npiname'])) 
		|| (data['billing_npi_name'] && (data['billing_npi_name'].value || data['billing_npi_name'])));
	}

	selectKeyfield = (context: RowArgs): string => {
		return (
			this.gridInfo.keyField &&
			context.dataItem[this.gridInfo.keyField] &&
			(context.dataItem[this.gridInfo.keyField].value || context.dataItem[this.gridInfo.keyField])
		);
	};

	openNetworkModal() {
		this.gridService.addToNetwork(this.key, this.selectedProviders);
	}

	selectionChange(event: SelectionEvent) {
		if (event.selectedRows.length) {
			event.selectedRows.forEach(row => {
				const npiId = this.getGridDataRowNpi(row.dataItem);
				const name = this.getGridDataRowNpiName(row.dataItem);
				this.selectedRows.push(npiId);
				this.selectedProviders.push(new ProviderIds(name, npiId));
			});
			this.gridService.select(this.key, this.selectedRows);
		} else {
			const deselectedNpis = event.deselectedRows.map(row => this.getGridDataRowNpi(row.dataItem));
			this.selections = this.selectedRows.filter(npi => !deselectedNpis.includes(npi));
			this.selectedProviders = this.selectedProviders.filter(provider => !deselectedNpis.includes(provider.npiId));
		}

		const e: GridCommandInfo = {
			command: GRID_COMMAND.select,
			dataItem: this.selectedRows,
			rowIndex: null,
			sourceEvent: event
		};

		this.gridService.issueCommand(this.key, e);
	}

	sortChange(sort: SortDescriptor[]) {
		// this method, sortChange, gets called after dataStateChange. We have the pattern to manually set this flag to to prevent any data
		// or sort events from occurring when an ibutton is clicked.
		if (!this.allowGridStateChange) {
			this.allowGridStateChange = true;
			return;
		}
		const filteredSort = sort.filter(e => e.dir);
		this.queryOptions.sort = filteredSort;
		this.gridService.reportQueryOptions(this.key, this.queryOptions);
	}

	toggleShowSelected(event: MatSlideToggleChange) {
		this.gridService.showSelected(this.key, event.checked);
	}

	clearSelected() {
		if (!this.gridInfo) {
			return;
		}

		this.selections = [];
		this.gridService.clearAll(this.key, true);
	}

	addReport() {
		this.gridService.addReport(this.key, this.gridInfo);
	}

	gridHasMultiColumnHeader(columns: GridColumn[]) {
		return columns.some(c => c.columnType === GRID_COLUMN_TYPE.preheader) ? 'bottom' : '';
	}

	public displayedData = async () => {
		const columns = this.gridInfo && this.gridInfo.columns;

		let bigExport: GridDataResult = null;
		if (this.gridInfo) {
			bigExport = await this.getExportData();
			if (bigExport && !bigExport.data?.length) {
				return {};
			}
		}

		const gridData = bigExport || this.dataResponse;
		const data = gridData?.data;

		const displayedResults = data.map(d => {
			const formattedData = {};
			for (const key in d) {
				if (d.hasOwnProperty(key)) {
					let column = columns.find(col => key === col.field);
					// If we can't find the column, check the preheaders.
					if (!column) {
						const preheader = columns.find(p => p.columns.some(c => c.field === key));
						if (preheader) {
							column = preheader.columns.find(c => key === c.field);
						}
					}
					formattedData[key] = this.getDisplayedValue(d[key], column);
				}
			}
			return formattedData;
		});

		const result: ExcelExportData = {
			data: process(displayedResults, {}).data
		};

		return result;
	};

	async getExportData(): Promise<GridDataResult> {
		if (!this.gridInfo) {
			return new Promise<GridDataResult>((resolve, reject) => null as GridDataResult);
		}

		const result = await this.gridExportService.getDataToExport(this.gridInfo);
		if (result && result.gridInfo.reportName === this.gridInfo.reportName) {
			return result.result || GridExport.withNoData(this.gridInfo).result;
		}

		return GridExport.withNoData(this.gridInfo).result;
	}

	openE() {
		if (!this.allowEButton) {
			return;
		}
		this.gridService.reportEButtonClick(this.key);
	}

	doesTextNeedTruncation(data: any, column: GridColumn): boolean {
		const toolTipText = this.getDisplayedValue(data, column);
		return toolTipText.length > column.maxCharacters;
	}

	buildLink(dataItem: Record<string, FormattedData>) {
		if (this.rowLinkFactory && this.gridInfo) {
			return this.rowLinkFactory(dataItem, this.gridInfo);
		}
		return '';
	}

	private collapseAll() {
		this.dataResponse.data.forEach((_, id) => {
			try {
				this.grid.collapseRow(id);
			} catch (err) {
				if (err instanceof TypeError) {
					// do nothing
				} else {
					throw err;
				}
			}
		});
	}
}
