import { Component, Input, OnInit } from '@angular/core';
import { ComponentWithSubscription, FormattedData, GridInfo } from '../../../public_api';
import { Utils } from '../../../shared/Utils';
import { GridService } from '../../../shared/services/grid.service';


@Component({
	selector: 'app-row-detail-grid',
	templateUrl: './row-detail-grid.component.html',
	styleUrls: ['./row-detail-grid.component.scss']
})
export class RowDetailComponent extends ComponentWithSubscription implements OnInit {

	@Input() rowLinkFactory: (key: Record<string,FormattedData>, info: GridInfo) => string;
	@Input() parentKey: string;
	@Input() parentGridInfo: GridInfo;

	get showGrid() {
		return this.parentKey.length && !!this.parentGridInfo;
	}

	@Input() set primaryNpi(dataItem: unknown) {
		if(dataItem && this.parentGridInfo?.npiField){
			this._primaryNpi = dataItem[this.parentGridInfo.npiField].value;
		}
	}

	
	loadingData = true;
	key = Utils.generateGuid();
	gridInfo: GridInfo;
	private _primaryNpi: string;
	

	constructor(private gridService: GridService) {
		super();
	}

	ngOnInit() { 
		this.subscribe(this.gridService.childGridInfoSet, this.parentKey, childGridInfo => {
			this.gridInfo = childGridInfo;
		});
		this.subscribe(this.gridService.loading, this.key, isLoading => {
			this.loadingData = isLoading;
		});
		this.gridService.createChildSubcription(this.parentKey, this.key);
		this.gridService.requestDetails(this.key, this._primaryNpi);
	}

}
