import {
	Component,
	ViewChild,
	OnInit,
	AfterViewInit,
	ElementRef,
	Output,
	EventEmitter,
	AfterViewChecked
} from '@angular/core';
import highChartSunburst from 'highcharts/modules/sunburst';
import { BaseChart } from '../base-chart';
import * as Highcharts from 'highcharts';
// eslint-disable-next-line no-duplicate-imports
import { SeriesSunburstOptions, Chart, CreditsOptions } from 'highcharts'; // SeriesPointClickEventObject
import { Subject } from 'rxjs';
import { ChartDataSeries, ChartDataElement } from '../../chart-data-series.model';
import { ChartConfig } from '../../../../shared/chart/chart';
import { UserFeedbackService } from '../../../../shared/services/user-feedback.service';
import { ERROR_MESSAGES } from '../../../../shared/constants';
import { ChartComponent } from '../../chart.component';


highChartSunburst(Highcharts);

const defaultSeriesOptions = {
	name: 'sunburst',
	allowDrillToNode: false,
	cursor: 'pointer',
	levels: [
		{
			level: 1,
			levelSize: {
				unit: 'percentage',
				value: 35
			}
		}
	]
};

const UNKNOWN_COLOR = 'rgba(100,100,100,1)';

const defaultChartOptions = {
	chart: {
		height: '100%'
	},
	title: {
		text: null,
		style: {
			display: 'none'
		}
	},
	subtitle: {
		text: null,
		style: {
			display: 'none'
		}
	},
	credits: {
		enabled: false //  remove highcharts watermark
	},
	boost: {
		enabled: true
	},
	export: {
		enabled: false
	},
	tooltip: {
		headerFormat: '',
		pointFormat: 'The population of {point.title} is <b>{point.percent:.1f}%</b>'
	}
};

export interface ChartData {
	id: string;
	parent: string;
	lvl: number;
}

@Component({
	selector: 'trella-sunburst-chart',
	templateUrl: './sunburst-chart.component.html',
	styleUrls: ['./sunburst-chart.component.scss']
})
export class SunburstChartComponent extends BaseChart implements OnInit, AfterViewInit, AfterViewChecked {
	@ViewChild('highchartimage', { static: false }) public highChartImage: ChartComponent;

	constructor(private userFeedbackService: UserFeedbackService) {
		super();
	}
	containerWatcher: Subject<ElementRef> = new Subject();

	highCharts: typeof Highcharts;
	chartOptions: Partial<Highcharts.Options>;
	selectedTree: string[] = [];

	isInsufficientData = false;

	@Output() clickEvent: EventEmitter<any> = new EventEmitter();
	@ViewChild('chart', { static: true }) public chartContainer: any;

	private _clickCallBack(event: any) {
		if (this.isInsufficientData) {
			return;
		}

		// SeriesPointClickEventObject
		const options = event.point.options as any;
		this.selectedTree = [];
		if (options.id) {
			this.selectedTree.push(options.id);
		}
		let parent = options.parent;
		let iSafety = 99;
		while (parent) {
			if (!iSafety--) {
				break;
			}
			this.selectedTree.push(parent);
			const getParent = event.point.series.data.filter((point: any) => point.id === parent) as any;
			parent = !!getParent.length ? getParent[0].parent : null;
		}

		const chartObj = this.highCharts.charts.find(x => x);
		if (chartObj) {
			chartObj.series[0].data.forEach(point => this.adjustOpacity(point, this.selectedTree));
			chartObj.redraw(false);
			this.clickEvent.emit(options);
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.highCharts = Highcharts;
	}

	postDataRead() {
		this.userFeedbackService.resetNotifications();

		const creditsOption: CreditsOptions = { enabled: false };

		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const component = this;

		const data: any = this.data.data;
		const centerData = data && data.find(d => d.id === 'Z0');
		this.isInsufficientData = centerData && centerData.name === '< 11 Patients';

		const series: any = {
			...defaultSeriesOptions,
			type: 'sunburst',
			data: this.isInsufficientData ? [centerData] : this.data.data,
			point: {
				events: {
					// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
					click(event) {
						component._clickCallBack(event);
					}
				}
			},
			turboThreshold: this.data.data.length
		};

		const chartOptions: Partial<Highcharts.Options> = {
			...defaultChartOptions,
			credits: creditsOption,
			series: [series as SeriesSunburstOptions],
			plotOptions: {
				series: {
					dataLabels: {
						style: {
							color: '#5b6770',
							textOutline: 'none',
							fontSize: '16px',
							fontFamily: 'Open Sans,sans-serif',
							textOverflow: 'wrap'
						}
					}
				}
			}
		};

		this.chartOptions = chartOptions as Highcharts.Options;

		if (this.isInsufficientData) {
			this.userFeedbackService.showWarning(ERROR_MESSAGES.insufficientChartData);
		}
	}

	ngAfterViewInit(): void {
		this.containerWatcher.next(this.chartContainer);
	}

	ngAfterViewChecked(): void {
		if (this.isInsufficientData) {
			const chartObj = this.highCharts.charts.find(x => x);
			if (chartObj) {
				const series: any = chartObj.series[0];
				series.tooltipOptions = undefined;
				series.data.forEach(point => this.adjustOpacity(point, this.selectedTree));
				chartObj.redraw(false);
			}
		}
	}

	adjustOpacity(point: any, selectedTree: any[]) {
		const colorParts = point.color.split(',');
		if (colorParts.length < 3 || colorParts.length > 4) {
			return;
		}
		if (colorParts.length === 3) {
			colorParts[2] = colorParts[2].replace(')', '');
			colorParts.push('1)'); //  just a place holder
		}

		const grayOut = this.isInsufficientData || (selectedTree.length > 1 && !selectedTree.includes(point.id));
		colorParts[3] = grayOut ? '.3)' : '1)';

		const newColor = colorParts.join(',');
		point.update(
			{
				color: newColor
			},
			false
		);
	}

	chartCallback = (chart: Chart) => {
		this.containerWatcher.subscribe(elem => {
			if (this.isInsufficientData || !elem) {
				return;
			}

			chart.reflow();
		});
	};

	readData(chartInfo: ChartConfig): ChartDataSeries {
		const dataRaw = chartInfo.data.map(d => {
			const newRow = {};
			Object.keys(d).map(x => {
				let item: any = d[x].formattedValue || d[x].value;
				if (!isNaN(Number(item)) && item && item.trim() !== '') {
					item = Number(item);
				}
				newRow[x] = item;
			});
			return newRow as any;
		});

		const colorNameMap = chartInfo.colorMap;

		if (colorNameMap) {
			const field = chartInfo.colorField;
			dataRaw.forEach(x => (x.color = colorNameMap[x[field]] || UNKNOWN_COLOR));
		}

		const result = new ChartDataSeries();
		result.data = dataRaw;
		result.isValidData = this.validateData(null);
		return result;
	}

	validateData = (chartData: ChartDataElement[]) => true;

	handleOptionsChange(config: ChartConfig) {
		// no op
	}
}
