import { RangeSelection } from './ranges/range-selection';
import { CheckedSelection } from './checked-selection';
import { SelectionsWithRange } from './selections-with-range';
import { Utils } from './utils';

export class SelectionsWithRangeSelection {
	items: CheckedSelection[];
	range: RangeSelection;

	constructor(rules: SelectionsWithRange) {
		this.items = Utils.getCheckedSelections(rules?.items);
		this.range = new RangeSelection(rules?.range);
	}
}
