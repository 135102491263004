import { AbstractValueAccessor } from './../shared/AbstractValueAccessor';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { HighchartsChartModule } from 'highcharts-angular';
import { Ng5SliderModule } from 'ng5-slider';
import { BarChartComponent } from './chart/chart-types/bar-chart/bar-chart.component';
import { ChartTooltipComponent } from './chart/chart-tooltip/chart-tooltip.component';
import { ColumnChartComponent } from './chart/chart-types/column-chart/column-chart.component';
import { ColumnChartWithAveragesComponent } from './chart/chart-types/column-chart-with-averages/column-chart-with-averages.component';
import { InsightComponent } from './chart/chart-types/insight/insight.component';
import { LineChartComponent } from './chart/chart-types/line-chart/line-chart.component';
import { PieChartComponent } from './chart/chart-types/pie-chart/pie-chart.component';

import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ChartComponent } from './chart/chart.component';
import { ComparisonsDialogComponent } from './comparisons-dialog/comparisons-dialog.component';
import { ComponentsComponent } from './components.component';
import { DataEulaComponent } from './data-eula/data-eula.component';
import { EulaComponent } from './eula/eula.component';
import { TrellaGridComponent } from './grid/trella-grid.component';
import { ListBoxComponent } from './list-box/list-box.component';
import { MultiGridComponent } from './multi-grid/multi-grid.component';
import { MultiChartComponent } from './multi-chart/multi-chart.component';
import { SearchComponent } from './search/search.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { SparklineAreaComponent } from './sparkline-area/sparkline-area.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { StarsComponent } from './stars/stars.component';
import { SunburstChartComponent } from './chart/chart-types/sunburst-chart/sunburst-chart.component';
import { DemographicCardComponent } from './demographic-card/demographic-card.component';
import { PostNotesComponent } from './grid/post-notes/post-notes.component';
import { FilterGroupComponent } from './filters/filter-group.component';
import { SearchResultContainerDirective } from './filters/searchResultContainer.directive';
import { LocationComponent } from './filters/location/location.component';
import { AffiliationComponent } from './filters/affiliation/affiliation.component';
import { RangeComponent } from './filters/range/range.component';
import { TabsComponent } from './tabs/tabs.component';
import { RouterModule } from '@angular/router';
import { MultiSelectListBoxComponent } from './multi-select-list-box/multi-select-list-box.component';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { TopInsightComponent } from './chart/chart-types/insight-top/top-insight.component';
import { TopPlacesInsightComponent } from './chart/chart-types/top-places-insight/top-places-insight.component';
import { NavigationButtonDirective } from '../shared/directives/navigation-button.directive';
import { PercentileEvaluationDirective } from '../shared/directives/percentile-evaluation.directive';
import { GridCellIconDirective } from '../shared/directives/grid-cell-icon.directive';
import { FilterSelectionDropdownComponent } from './filter-selection-dropdown/filter-selection-dropdown.component';
import { ComponentWithSubscription } from '../shared/component-with-subscription';
import { NotificationsComponent } from './notifications/notifications.component';
import { InfoCircleComponent } from './info-circle/info-circle.component';
import { RiskAdjustedToggleComponent } from './risk-adjusted-toggle/risk-adjusted-toggle.component';
import { SimpleFilterComponent } from './filters/simple/simple-filter.component';
import { MapComponent } from './map/map.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { TextTruncatePipe } from '../shared/pipes/text-truncate.pipe';
import { AcoDemographicCardComponent } from './aco-demographic-card/aco-demographic-card.component';
import { HoverTooltipComponent } from './hover-tooltip/hover-tooltip.component';
import { AlertDialogDirective } from '../shared/directives/alert-dialog.directive';
import { MultiLevelFilterComponent } from './filters/multi-level/multi-level-filter.component';
import { RowDetailComponent } from './grid/row-detail-grid/row-detail-grid.component';
@NgModule({
	declarations: [
		AcoDemographicCardComponent,
		AffiliationComponent,
		AlertDialogComponent,
		AlertDialogDirective,
		SimpleFilterComponent,
		RangeComponent,
		BarChartComponent,
		ChartComponent,
		ChartTooltipComponent,
		ColumnChartComponent,
		ColumnChartWithAveragesComponent,
		ComparisonsDialogComponent,
		ComponentsComponent,
		DataEulaComponent,
		DemographicCardComponent,
		EulaComponent,
		FilterGroupComponent,
		FilterListPipe,
		FilterSelectionDropdownComponent,
		GlobalSearchComponent,
		GridCellIconDirective,
		TrellaGridComponent,
		HoverTooltipComponent,
		InfoCircleComponent,
		InsightComponent,
		LineChartComponent,
		ListBoxComponent,
		LocationComponent,
		MapComponent,
		MultiChartComponent,
		MultiGridComponent,
		MultiSelectListBoxComponent,
		NavigationButtonDirective,
		NotificationsComponent,
		PasswordChangeComponent,
		PercentileEvaluationDirective,
		PieChartComponent,
		PostNotesComponent,
		RiskAdjustedToggleComponent,
		RowDetailComponent,
		SearchComponent,
		SearchResultContainerDirective,
		SparklineAreaComponent,
		MultiLevelFilterComponent,
		SpinnerComponent,
		StarsComponent,
		SunburstChartComponent,
		TabsComponent,
		TextTruncatePipe,
		TopInsightComponent,
		TopPlacesInsightComponent,

		// abstract
		AbstractValueAccessor,
		ComponentWithSubscription
	],
	imports: [
		AgmCoreModule.forRoot({
			apiKey: environment.googleMapApiKey
		}),
		ChartsModule,
		CommonModule,
		DialogsModule,
		DropDownsModule,
		ExcelModule, // Not exported - use App-Grid instead
		FormsModule,
		GridModule,
		HighchartsChartModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatInputModule,
		MatListModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatDividerModule,
		MatRadioModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatSliderModule,
		Ng5SliderModule,
		ReactiveFormsModule,
		RouterModule,
		ScrollingModule,
		TooltipModule
	],
	exports: [
		AcoDemographicCardComponent,
		AffiliationComponent,
		AlertDialogComponent,
		SimpleFilterComponent,
		RangeComponent,
		ChartComponent,
		ComparisonsDialogComponent,
		ComponentsComponent,
		DataEulaComponent,
		DemographicCardComponent,
		EulaComponent,
		FilterGroupComponent,
		FilterListPipe,
		FilterSelectionDropdownComponent,
		GlobalSearchComponent,
		GridCellIconDirective,
		TrellaGridComponent,
		HighchartsChartModule,
		HoverTooltipComponent,
		InfoCircleComponent,
		ListBoxComponent,
		LocationComponent,
		MapComponent,
		MatAutocompleteModule,
		MatButtonModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatDividerModule,
		MatInputModule,
		MatListModule,
		MatNativeDateModule,
		MatRadioModule,
		MatSelectModule,
		MatSlideToggleModule,
		MatTabsModule,
		MatSliderModule,
		MultiChartComponent,
		MultiGridComponent,
		MultiSelectListBoxComponent,
		NavigationButtonDirective,
		Ng5SliderModule,
		NotificationsComponent,
		PasswordChangeComponent,
		PercentileEvaluationDirective,
		RiskAdjustedToggleComponent,
		RouterModule,
		ScrollingModule,
		SparklineAreaComponent,
		MultiLevelFilterComponent,
		SpinnerComponent,
		SearchComponent,
		StarsComponent,
		TabsComponent,
		TextTruncatePipe,
		TopPlacesInsightComponent
	]
})
export class ComponentsModule {
	public static forRoot() {
		return {
			ngModule: ComponentsModule,
			providers: []
		};
	}
}
