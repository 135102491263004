import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { FILTER_TYPE } from '../../../shared/enums/filter-type.enum';
import { FilterGroupService } from '../../../shared/services/filter-group.service';
import { BaseFilterComponent } from '../base-filter.component';
import { Node } from '../../../shared/models/tree-filter/tree-filter-node';
import { NO_SUB_SPECIALTY } from '../../../shared/constants';

@Component({
	selector: 'trella-multi-level-filter',
	templateUrl: './multi-level-filter.component.html',
	styleUrls: ['./multi-level-filter.component.scss']
})
export class MultiLevelFilterComponent extends BaseFilterComponent {
	private _allNodes: Node[] = [];
	private _focusedNode: Node;
	private _caption: string;
	private _levels: string[] = [];
	private _filterType: FILTER_TYPE;
	visibleLevelOneNodes: Node[] = [];
	visibleLevelTwoNodes: Node[] = [];

	@Input() set levels(value: string[]) {
		this._levels = value;
	}
	get levels(): string[] {
		return this._levels;
	}

	@Input() set allNodes(value: Node[]) {
		this._allNodes = value;
	}
	get allNodes(): Node[] {
		return this._allNodes;
	}

	@Input() set caption(value: string) {
		this._caption = value;
	}
	get caption(): string {
		return this._caption;
	}

	@Input() set filterType(value: FILTER_TYPE) {
		this._filterType = value;
	}
	get filterType(): FILTER_TYPE {
		return this._filterType;
	}

	@Input() definitionFactory: (FILTER_TYPE) => string;
	get getDefinition(): string {
		return this.definitionFactory(this.filterType) ?? '';
	}

	@Input() titleFactory: (FILTER_TYPE) => string;
	get getTitle(): string {
		return (this.titleFactory && this.titleFactory(this.filterType)) || this.caption;
	}

	get getCount(): number {
		// TODO: recursive
		let count = 0;
		for (const node of this._allNodes) {
			if (node.checked && !node.children.some(c => c.checked)) {
				count++;
			}
			for (const child of node.children) {
				if (child.checked) {
					count++;
				}
			}
		}
		return count;
	}

	get visibleNodesByLevel(): Node[][] {
		const visibleLevelOneNodes = this.visibleLevelOneNodes.length ? this.visibleLevelOneNodes : this.sortNodes(this.allNodes);

		let visibleLevelTwoNodes = this.visibleLevelTwoNodes || [];
		if (visibleLevelOneNodes.includes(this._focusedNode)) {
			visibleLevelTwoNodes = this._focusedNode?.children || visibleLevelTwoNodes;
		} else if (this._focusedNode && this._focusedNode.parent) {
			visibleLevelTwoNodes = this._focusedNode.parent.children;
		}

		this.visibleLevelTwoNodes = this.sortNodes(visibleLevelTwoNodes);

		return [visibleLevelOneNodes, visibleLevelTwoNodes];
	}

	private _selectionsChanged: EventEmitter<Node[]> = new EventEmitter<Node[]>();
	@Output() selectionsChanged: Observable<Node[]> = this._selectionsChanged.asObservable();
	searchQuery: string;
	filteredNodes: Node[] = [];

	constructor(protected filterGroupService: FilterGroupService, private ref: ChangeDetectorRef) {
		super(filterGroupService);
	}

	sortNodes(nodes: Node[]): Node[] {
		return nodes.sort((a, b) => {
			if (a.checked && !b.checked) {
				return -1;
			} else if (!a.checked && b.checked) {
				return 1;
			} 
			else if (a.display !== NO_SUB_SPECIALTY && b.display === NO_SUB_SPECIALTY) {
				return 1;
			}
			else if (a.display === NO_SUB_SPECIALTY && b.display !== NO_SUB_SPECIALTY) {
				return -1;
			}
			else {
				return a.display.localeCompare(b.display);
			}
		});
	}

	onSearchKeyup(_: KeyboardEvent): void {
		if (this.searchQuery.length < 2) {
			return;
		}

		let nodes = [];

		this.allNodes.forEach(n => {
			if (n.toString().toLowerCase().includes(this.searchQuery.toLowerCase())) {
				nodes.push(n);
			}
			if (n.children) {
				const filtered = n.children.filter(child => child.toString().toLowerCase().includes(this.searchQuery.toLowerCase()));
				nodes = [...nodes, ...filtered];
			}
		});
		this.filteredNodes = nodes;
	}

	handleSearchResultSelection(node: Node) {
		this.filteredNodes = [];
		this._focusedNode = node;
		this.searchQuery = '';
		this.toggleNode(node);
	}

	handleNodeSelection(node: Node) {
		// Need to select all children, too
		this._focusedNode = node;
	}

	isSelectedNode(node: Node) {
		const areEqual = this._focusedNode?.value === node.value;
		// This protects from specialties/sub-specialties getting deselected every time we check/uncheck one
		if (areEqual) this.handleNodeSelection(node);
		return areEqual;
	}

	toggleNode(node: Node) {
		const newCheckedState = !node.checked;
		node.checked = newCheckedState;
		if (newCheckedState && node.parent) {
			node.parent.checked = true;
		}
		if (newCheckedState && node.parent && node.parent.children.every(n => !n.checked)) {
			node.parent.checked = false;
		}
		this.toggleAllChildren(node);
		this.emitSelections();
	}

	toggleAllChildren(node: Node) {
		const newCheckedState = node.checked;
		if (node.children) {
			node.children.forEach(c => {
				c.checked = newCheckedState;
				this.toggleAllChildren(c);
			});
		}
	}

	emitSelections() {
		this._selectionsChanged.emit(this.allNodes);
	}

	getHash(index: number, node: Node): string {
		return node.getHash();
	}

	getArrayHash(index: number, nodes: Node[]): string {
		return JSON.stringify(nodes.map(node => node.getHash()));
	}
}
