import { Directive, ElementRef, Input, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { PercentileQuality, SpecialColors } from '../constants';

@Directive({
	selector: '[mosaic-percentile-evaluation]'
})
export class PercentileEvaluationDirective implements OnChanges {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	@Input('mosaic-percentile-evaluation') quality: PercentileQuality;

	ngOnChanges(changes: SimpleChanges) {
		if (changes.quality) {
			this.renderer.setStyle(this.el.nativeElement, 'color', this.getColor());
		}
	}

	getColor() {
		switch (this.quality) {
			case PercentileQuality.Good:
        return SpecialColors.GoodPercentile;
      case PercentileQuality.Average:
        return SpecialColors.AveragePercentile;
			case PercentileQuality.Bad:
				return SpecialColors.BadPercentile;
			case PercentileQuality.Moot:
			default:
				return SpecialColors.MootPercentile;
		}
	}
}
