import { Injectable } from '@angular/core';
import { ChartComponent as Kendochart } from '@progress/kendo-angular-charts';
import { CHART_TYPE } from '../../lib/chart/chart-type';
import { drawDOM, exportImage, drawing } from '@progress/kendo-drawing';
import { saveAs } from '@progress/kendo-file-saver';
import * as Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);
import { UserFeedbackService } from '../services/user-feedback.service';
import html2canvas from 'html2canvas';
import { ChartComponent } from '../../lib/chart/chart.component';

import canvg from 'canvg';
@Injectable({
    providedIn: 'root'
})
export class ImageExportService {

    Highcharts = Highcharts;
    fileName: string;
    // When bubble chart is implemented, this will be required
    customBubbleLegend = 'bubbleLegendContainer';

    constructor(private userFeedbackService: UserFeedbackService) { }

    exportImage(chartExport: ChartComponent) {
        
        const chartTitle = chartExport.chartInfo.displayName || '';
        const chartType = chartExport.chartInfo.type;
        this.fileName = chartTitle.length === 0 ? 'trella_chart.png' : `${chartTitle.replace(' ', '_')}.png`;
        switch (chartType) {
            case CHART_TYPE.column:
            case CHART_TYPE.line:
            case CHART_TYPE.bar:
            case CHART_TYPE.venn:
                return this.exportKendoChart(chartExport.chartImage.kendoImage, chartTitle, null);

            case CHART_TYPE.bubble:
                return this.exportKendoChart(chartExport.chartImage, chartTitle, this.customBubbleLegend);

            case CHART_TYPE.columnWithAverages:
            case CHART_TYPE.pie:
                return this.exportHighChart(chartExport.chartImage.highChartImage.chart, chartTitle);
            
            // Anything else is an Insight
            default:
                return this.exportInsight(chartExport.chartInfo.reportName);

        }
    }

    private exportKendoChart(chart: Kendochart, title: string, customElement: string) {

        let titleMargin = 0;
        let chartLegend: HTMLElement;
        if (customElement) {

            chartLegend = document.getElementById(customElement);
            chartLegend.style.maxWidth = '400px';
            chartLegend.style.paddingTop = '46px';
            chartLegend.style.paddingLeft = '10px';
            titleMargin = (chartLegend.offsetHeight / 1.9) || 0;
        }

        const chartVisual = chart.exportVisual({
            options: {
                chartArea: {
                    border: {
                        color: 'black',
                        width: 1
                    },
                    margin: 15,
                    height: 450,
                    width: 470
                },
                title: {
                    text: title,
                    color: 'black',
                    align: 'left',
                    font: '20px sans-serif',
                    margin: {
                        bottom: titleMargin
                    }
                }
            }
        });
        if (customElement) {
            drawDOM(chartLegend).then(legendDrawn => {
                chartVisual.insert(1, legendDrawn);
            });
            chartLegend.style.removeProperty('max-width');
            chartLegend.style.removeProperty('padding-top');
            chartLegend.style.removeProperty('padding-left');
        }
        exportImage(chartVisual).then(dataUri => {
            saveAs(dataUri, this.fileName);
        });
    }

    // todo: create separate function for returning SVG
    private exportHighChart(chart: Highcharts.Chart, title: string) {

        chart.exportChart({
            scale: 1,
            sourceHeight: 450,
            sourceWidth: 470,
            filename: this.fileName
        }, {
            title: {
                text: title,
                align: 'left',
                style: {
                    fontFamily: 'Helvetica',
                    fontSize: '20px'
                }
            },
            chart: {
                borderColor: 'black',
                borderWidth: 1
            },
        });
    }

    private exportInsight(chartId: string) {
        this.userFeedbackService.startSpinner();
        
        const chartElement = <HTMLScriptElement>document.querySelector(`#${chartId}_chart`);
        const imageWidth = chartElement.getBoundingClientRect().width || 470;
        const imageHeight = chartElement.getBoundingClientRect().height || 450;
        window.scrollTo(0, 0);

        html2canvas(chartElement, {
            scale: 2,
            allowTaint: true,
            onclone: (document => {
                const noPrintElements = document.getElementsByClassName(`#${chartId}_chart .no-print`);
                Array.from(noPrintElements).forEach(element => {
                    element.remove();
                });
            })
        })
        .then(fileUrl => {
            const resizedCanvas = document.createElement('canvas');
            resizedCanvas.width = imageWidth;
            resizedCanvas.height = imageHeight;
            const resizedContext = resizedCanvas.getContext('2d');
            resizedContext.drawImage(fileUrl, 0, 0, imageWidth, imageHeight);
            this.userFeedbackService.stopSpinner();
            resizedCanvas.toBlob(blob => {
                saveAs(blob, `${this.fileName}`);
            });
        });
    }

    getImageData(chartComponent: ChartComponent, title: string): Promise<string> {

        switch (chartComponent.CHART_TYPE.toString()) {

            case CHART_TYPE.column:
            case CHART_TYPE.line:
            case CHART_TYPE.bar:
            case CHART_TYPE.venn:
                return this.getKendoImageData(chartComponent.chartImage.chart, title, null);

            case CHART_TYPE.bubble:
                return this.getKendoImageData(chartComponent.chartImage.chart, title, this.customBubbleLegend);

            case CHART_TYPE.columnWithAverages:
            case CHART_TYPE.pie:
                return this.getHighchartImageData(chartComponent.chartImage, title);
        }
    }

    async getKendoImageData(chart: Kendochart | any, title: string, customElement: string): Promise<string> {

        if (!chart) {
            return '';
        }
        let image;
        let titleMargin = 0;
        let chartLegend: HTMLElement;
        if (customElement) {
            chartLegend = document.getElementById(customElement);
            chartLegend.style.maxWidth = '400px';
            chartLegend.style.paddingTop = '46px';
            chartLegend.style.paddingLeft = '10px';
            titleMargin = (chartLegend.offsetHeight / 1.9) || 0;
        }

        const chartVisual = chart.exportVisual({
            options: {
                chartArea: {
                    border: {
                        color: 'black',
                        width: 1
                    },
                    margin: 15,
                    height: 900,
                    width: 940
                },
                title: {
                    text: title,
                    color: 'black',
                    align: 'left',
                    font: '20px sans-serif',
                    margin: {
                        bottom: titleMargin
                    }
                }
            }
        });

        if (customElement) {
            drawDOM(chartLegend).then(legendDrawn => {
                chartVisual.insert(1, legendDrawn);
            });
            chartLegend.style.removeProperty('max-width');
            chartLegend.style.removeProperty('padding-top');
            chartLegend.style.removeProperty('padding-left');
        }
        await drawing.exportImage(chartVisual, { width: 470, height: 450 }).then(img => {
            image = img;
        });
        return image;
    }

    async getHighchartImageData(chart: any, title: string): Promise<any> {

        if (!chart || !chart.chart || !chart.chart.getSVG) {
            return '';
        }

        const chartSvg = chart.chart.getSVG({
            title: {
                text: title,
                align: 'left',
                style: {
                    fontFamily: 'Helvetica',
                    fontSize: '20px'
                }
            },
            chart: {
                borderColor: 'black',
                borderWidth: 1,
                height: 450,
                width: 470
            },
        });
        const canvas = document.createElement('canvas');
        canvas.width = 1200;
        canvas.height = 1200;
        const cntx = canvas.getContext('2d');

        const contextSvg = await canvg.fromString(cntx, chartSvg);
        contextSvg.start();

        return canvas.toDataURL('image/png', 1);
    }

    async getImageDataFromHtml(elementId: string): Promise<string> {
       
        const element = document.getElementById(elementId);
        if (element) {
            return html2canvas(element).then(canvas => {
                return canvas.toDataURL();
            });
        } else {
            return '';
        }
    }
}
