import { CheckedSelection } from './checked-selection';
import { FilterRules } from './filter-rules';
import { Location } from './location';
import { RangeSelection } from './ranges/range-selection';
import { SelectionsWithRangeSelection } from './selections-with-range-selection';
import { Utils } from './utils';
import { Node } from './tree-filter/tree-filter-node';

export class FilterSelections {
	acos?: Node[];
	affiliations?: SelectionsWithRangeSelection;
	beneficiaryLocations?: Location[];
	providerLocations?: Location[];
	medicalGroups?: CheckedSelection[];
	networks?: CheckedSelection[];
	rangeFilters?: RangeSelection[];
	specialties?: Node[]; // needs to be a Node[] because this is what will be fed into the filter-group component

	constructor(rules: FilterRules) {
		if (!rules) {
			return;
		}

    	// Need to create a copy because the rules are immutable and coming from the redux store. FilterSelections are not immutable
		this.affiliations = rules.affiliations && new SelectionsWithRangeSelection(rules.affiliations);
		this.beneficiaryLocations = [];
		this.providerLocations = [];
		this.medicalGroups = rules.medicalGroups && Utils.getCheckedSelections(rules.medicalGroups);
		this.networks = rules.networks && Utils.getCheckedSelections(rules.networks);
		this.rangeFilters = rules.rangeFilters && rules.rangeFilters.map(r => new RangeSelection(r));
		this.specialties = rules.specialties && Utils.convertToTree(rules.specialties);
    	this.acos =  rules.acos && Utils.convertAcosToTree(rules.acos);
	}
}
