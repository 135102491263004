import { Component, Input } from '@angular/core';

@Component({
	selector: 'trella-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
	@Input() show: boolean;
	@Input() isFullDiv = false;
	@Input() includeMargin = true;
	isGlobal: boolean;

	get overlayClass() {
		return this.isFullDiv ? 'div-overlay' : '';
	}

	get spinnerClass() {
		return this.isFullDiv ? 'center-spinner' : '';
	}

	get circleColor() {
		return this.isFullDiv ? 'white' : 'black';
	}

	get circleClass() {
		return this.includeMargin ? '' : 'sk-fading-circle-no-margin';
	}

	public startSpinner() {
		this.show = true;
	}

	public stopSpinner() {
		this.show = false;
	}
}
