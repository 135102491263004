import { Input, OnInit, Output, EventEmitter, Directive, Component, Injectable } from '@angular/core';
import { Target, ChartConfig } from '../../../shared/chart/chart';
import { ChartDataSeries, ChartDataElement } from '../chart-data-series.model';
import { LegendMarkers, LegendLabels, ValueAxisLabels, CategoryAxisLabels, ValueAxisTitle } from '@progress/kendo-angular-charts';
import { Observable } from 'rxjs';
import { formatLabelVisual } from './utils';
import { DISPLAY_VALUE } from '../../../shared/constants';
import { Utils } from '../../../shared/Utils';
import { ChartUtils } from '../chart-utils';
import { CHART_DATA_TYPE } from '../chart-data-type';

@Injectable()
export abstract class BaseChart implements OnInit {
	@Input() fullScreen = false;
	@Input() dataChange$: Observable<ChartConfig>;
	@Input() activeLegendItems: any[] = [];
  	@Input() target: Target;
	@Output() activeLegendItemsChange = new EventEmitter();

	public seriesColors = ['#86A9EC', '#C66080', '#FFAA65', '#22B102', '#B8C5CF', '#006747', '#FFC72C', '#939393'];

	private fontMd = '.68rem \'Roboto Condensed\', sans-serif';
	private fontLg = 'bold 1.25rem \'Roboto Condensed\', sans-serif';
	// font = this.fullScreen ? this.fontLg : this.fontMd;

	stateAverageKey = 'State';
	countyAverageKey = 'County';

	legendLabelConfig: LegendLabels = {
		font: this.fontMd
	};
	categoryAxisLabels: CategoryAxisLabels = {
		font: this.fontMd,
		position: 'onAxis'
	};
	valueAxisLabels: ValueAxisLabels = {
		font: this.fontMd,
		content: (e: any) => this.formatDisplayValue(e.value),
		position: 'onAxis'
	};
	legendMarkers: LegendMarkers = {
		height: 12
	};

	xAxisTitle: ValueAxisTitle;
	yAxisTitle: ValueAxisTitle;
	toolTip: string;
	data: ChartDataSeries;
	options: ChartConfig;

	abstract postDataRead();
	abstract handleOptionsChange(config: ChartConfig);

	// eslint-disable-next-line @angular-eslint/contextual-lifecycle
	ngOnInit() {
		this.dataChange$.subscribe(chartInfo => {
			this.options = chartInfo;
			this.xAxisTitle = { text: this.options && this.options.xLabel };
			this.yAxisTitle = { text: this.options && this.options.yLabel };
			if (this.options.xAxisLabelRotation) {
				this.categoryAxisLabels.rotation = this.options.xAxisLabelRotation;
			} else {
				this.categoryAxisLabels.visual = formatLabelVisual;
			}
			this.data = this.readData(chartInfo);
			this.postDataRead();
			this.handleOptionsChange(chartInfo);
		});
	}

	public onLegendItemClick(e): void {
		e.preventDefault();
		this.activeLegendItems = !this.activeLegendItems.length ? this.data.data.map(c => c.label) : this.activeLegendItems; // This populates the list with all items when the page is first loaded or when the list is cleared out some other way
		// If we're trying to disable the last active item, do nothing
		if(this.activeLegendItems.length === 1 && this.activeLegendItems.find(f => f === e.text)) {
			return;
		}
		this.activeLegendItems = Utils.toggle(this.activeLegendItems, e.text);
		if (this.activeLegendItems.length === this.data.data.length) {
			this.activeLegendItems = [];
		}
		this.activeLegendItemsChange.emit(this.activeLegendItems);
	}

	public filterSeriesByLegend = (label: string) => {
		const showSeries = !this.activeLegendItems.length || this.activeLegendItems.includes(label);
		return showSeries;
	};

	readData(chartInfo: ChartConfig): ChartDataSeries {
		if (!chartInfo) {
			throw Error('No config.');
		}
		if (!chartInfo.data || !Array.isArray(chartInfo.data)) {
			throw Error('Incoming data must be array.');
		}
		return {
			data: [],
			categories: [],
			isValidData: true
		};
	}

	public getFormattedName = (e: any) => {
		if (!this.options.wrapText) {
			return e.value;
		}

		if (!e.value) {
			return '';
		}

		// let's get an array storing each part of the name & npi
		const spacesOnNewLine: string[] = e.value.split(' ');

		// get the npi that we will appended at the end
		const npi = spacesOnNewLine.pop();

		// initialize empty array and helper variable
		const combinedArray = [];
		let currentLine = '';

		for (let counter = 0; counter <= spacesOnNewLine.length - 1; ) {
			// as long as current line is under character limit, concatenate next string
			if (currentLine.length <= this.options.maxCharacters) {
				currentLine += spacesOnNewLine[counter] + ' ';
				counter += 1;

				// if counter is same as array length, reached the end, add onto array and exit out
				if (counter === spacesOnNewLine.length) {
					combinedArray.push(currentLine);
					break;
				}

				// check if current line is over the character limit
				if (currentLine.length > this.options.maxCharacters) {
					combinedArray.push(currentLine);
					currentLine = ''; // reset to start new line
				}
			}
		}

		combinedArray.push(npi); // add npi at the end

		// return the array with line breaks
		return combinedArray.join('\n');
	};

	protected validateRange(chartData: ChartDataElement[]): boolean {
		const rawData = chartData.map(x => x.rawData).reduce((p, c) => [...p, ...c], []);
		const data = chartData.map(x => x.data).reduce((p, c) => [...p, ...c], []);

		if (!rawData.length || !data.length) {
			return false;
		}

		return true;
	}

	isValidData() {
		return !!this.data;
	}

	protected formatDisplayValue(value: string): string {
		if (value === DISPLAY_VALUE.insufficient || value === DISPLAY_VALUE.lessThanEleven || value === DISPLAY_VALUE.dash || !value) {
			return value;
		}

		switch (this.options.dataType) {
			case CHART_DATA_TYPE.currency:
				return ChartUtils.getFormattedCurrencyValue(value);
			case CHART_DATA_TYPE.percent:
				return ChartUtils.getFormattedPercentValue(value);
			default:
				return value;
		}
	}
}
