import { GridDataResult } from '@progress/kendo-angular-grid';
import { FormattedResult } from './formatted-result';
import { GridInfo } from '../../grid-info';

export class GridExport {
  gridInfo: GridInfo;
  result?: GridDataResult;

  static withNoData(gridInfo: GridInfo){
    const noData = {
      data: [] as FormattedResult[],
      total: 0
    };

    return { gridInfo, result: noData };
  }
}
