import { LocationNode, LocationType, LocationTypeConfigKey } from './location-node';
import { Utils } from '../../../shared/Utils';
import { Location } from '../../../shared/models/location';

export class LocationTree {
	private root: LocationNode;
	private stateCountyOnly: boolean;

	public filteredLocationList: LocationNode[] = [];
	public visibleLoctionMenu: LocationNode[][] = [];

	public selectedNodes: LocationNode[] = [];
	public checkedNodes: LocationNode[] = [];

	get selectedMarkets(): LocationNode[] {
		return this.getSelectedLocationTypes(LocationType.Market);
	}

	get selectedCounties(): LocationNode[] {
		return this.getSelectedLocationTypes(LocationType.County);
	}

	get selectedCities(): LocationNode[] {
		return this.getSelectedLocationTypes(LocationType.City);
	}

	get selectedZips(): LocationNode[] {
		return this.getSelectedLocationTypes(LocationType.Zip);
	}

	get selectedCount(): number {
		return this.selectedMarkets.length + this.selectedCounties.length + this.selectedCities.length + this.selectedZips.length;
	}

	private checkedNodeBuckets: { [locationType in LocationTypeConfigKey]: LocationNode[] };

	constructor(locations: Location[], stateCountyOnly = false) {
		this.root = new LocationNode(LocationType.Root, 'locations', null);
		this.stateCountyOnly = stateCountyOnly;
		this.resetNodeBuckets();
		if (!locations) {
			return;
		}

		locations.forEach(x => {
			this.addNode(x);
		});
	}

	public setDefaultMenuView() {
		this.visibleLoctionMenu = [];
		this.visibleLoctionMenu.push(this.root.children);
		this.sortVisibleLoctionMenu();
	}

	public reset() {
		this.performRecursiveDown(this.root, (node: LocationNode) => {
			node.checked = false;
		});

		this.filteredLocationList = [];
		this.visibleLoctionMenu = [];
		this.selectedNodes = [];
		this.checkedNodes = [];
		this.resetNodeBuckets();
	}

	public recalculateTreeView() {
		this.updateCheckedSelection();
		this.sortVisibleLoctionMenu();
	}

	public loadFromNodeModels(models: Location[]) {
		this.reset();

		if (!models || !models.length) {
			return;
		}

		models.forEach(x => {
			this.selectByNodeModel(x);
		});

		this.recalculateTreeView();
	}

	resetNodeBuckets() {
		const emptyNodeBucket = {};
		emptyNodeBucket[LocationType.Market] = [];
		emptyNodeBucket[LocationType.County] = [];
		emptyNodeBucket[LocationType.City] = [];
		emptyNodeBucket[LocationType.Zip] = [];
		this.checkedNodeBuckets = emptyNodeBucket as any;
	}

	addNode(location: Location) {
		if (!location) {
			return;
		}

		let marketNode = this.root.children.find(x => x.data === location.market); // market node
		if (!marketNode) {
			marketNode = new LocationNode(LocationType.Market, location.market, this.root);
		}

		let countyNode = marketNode.children.find(x => x.data === location.county); // county node
		if (!countyNode) {
			countyNode = new LocationNode(LocationType.County, location.county, marketNode);
		}

		if (this.stateCountyOnly) {
			return;
		}

		let cityNode = countyNode.children.find(x => x.data === location.city); // city node
		if (!cityNode) {
			cityNode = new LocationNode(LocationType.City, location.city, countyNode);
		}

		const zipNode = cityNode.children.find(x => x.data === location.zipcode); // zip node
		if (!zipNode) {
			new LocationNode(LocationType.Zip, location.zipcode, cityNode); // TODO: Don't let the construtor of one object affet it's params
		}
	}

	getCheckedNodes(): LocationNode[] {
		const getCheckedFn = (node: LocationNode) => {
			if (!node.checked) {
				return false;
			}

			if (!node.children || !node.children.length) {
				return node.checked;
			}

			// Only save the nodes that have no children that are checked (Only Last leaf Nodes)
			return node.checked && !node.children.some(c => c.checked);
		};
		return this.getMatches(getCheckedFn);
	}

	getSearchResults(searchString: string): LocationNode[] {
		const searchFn = node => {
			return Utils.stringContains(node.data, searchString);
		};
		return this.getMatches(searchFn);
	}

	onSearch(searchString): LocationNode[] {
		if (!searchString || searchString.length < 2) {
			this.filteredLocationList = [];
			return;
		}

		this.filteredLocationList = this.getSearchResults(searchString);
		return this.filteredLocationList;
	}

	selectSearchResult(node: LocationNode) {
		this.selectNode(node);
		this.performRecursiveUp(node, (n: LocationNode) => {
			n.checked = true;
		});
		this.recalculateTreeView();
	}

	toggleNode(node: LocationNode) {
		node.checked = !node.checked;
		if (node.checked) {
			this.performRecursiveUp(node, (n: LocationNode) => {
				n.checked = true;
			});
		} else {
			this.performRecursiveDown(node, (n: LocationNode) => {
				n.checked = false;
			});
		}

		this.recalculateTreeView();
	}

	getSelectedLocations(): Location[] {
		return this.checkedNodes.map(x => x.configModel);
	}

	selectNode(node: LocationNode) {
		this.filteredLocationList = [];
		this.visibleLoctionMenu = this.getSelectedLocationArray(node);
		this.sortVisibleLoctionMenu();
		this.selectedNodes = this.getBranchLine(node);
	}

	private getSelectedLocationTypes(type: LocationType) {
		if (!type) {
			return [];
		}

		return (this.checkedNodeBuckets && this.checkedNodeBuckets[type]) || [];
	}

	private updateCheckedSelection() {
		this.checkedNodes = this.getCheckedNodes();
		this.updateCheckedNodeBuckets();
	}

	private updateCheckedNodeBuckets() {
		this.resetNodeBuckets();

		if (!this.checkedNodes || !this.checkedNodes.length) {
			return;
		}

		this.checkedNodes.forEach(x => {
			this.checkedNodeBuckets[x.type].push(x);
		});
	}

	private sortVisibleLoctionMenu() {
		if (!this.visibleLoctionMenu) {
			return;
		}

		this.visibleLoctionMenu.forEach((x: LocationNode[]) => {
			x = x.sort((a, b) => {
				if (a.checked && !b.checked) {
					return -1;
				}

				if (b.checked && !a.checked) {
					return 1;
				}

				return 0;
			});
		});
	}

	private performRecursiveUp(node: LocationNode, fn: (node) => any) {
		if (!node) {
			return;
		}

		if (fn && typeof fn === 'function') {
			fn(node);
		}

		if (!node.parent) {
			return;
		}

		this.performRecursiveUp(node.parent, fn);
	}

	private performRecursiveDown(node: LocationNode, fn: (node) => any) {
		if (!node) {
			return;
		}

		if (fn && typeof fn === 'function') {
			fn(node);
		}

		if (!node.children || !node.children.length) {
			return;
		}

		node.children.forEach(x => {
			this.performRecursiveDown(x, fn);
		});
	}

	private getBranchLine(node: LocationNode) {
		if (!node || node.isRoot) {
			return [];
		}

		if (!node.parent) {
			return [node];
		}

		return [node].concat(this.getBranchLine(node.parent));
	}

	private getSelectedLocationArray(node: LocationNode): LocationNode[][] {
		if (!node) {
			return [];
		}

		if (!node.parent) {
			return [node.children];
		}

		return this.getSelectedLocationArray(node.parent).concat([node.children]);
	}

	private getMatches(fn: (node) => boolean) {
		let results = [];

		for (const state of this.root.children) {
			if (fn && fn(state)) {
				results.push(state);
			}

			if (!state.children.length) {
				continue;
			}

			for (const county of state.children) {
				if (fn && fn(county)) {
					results.push(county);
				}

				if (!county.children.length || this.stateCountyOnly) {
					continue;
				}

				for (const city of county.children) {
					if (fn && fn(city)) {
						results.push(city);
					}

					if (!city.children.length) {
						continue;
					}

					for (const zip of city.children) {
						if (fn && fn(zip)) {
							results.push(zip);
						}
					}
				}
			}
		}

		results = results.sort((a: LocationNode, b: LocationNode) => {
			if (!a || !b) {
				return 0;
			}
			const aLevel = this.getLocationTypeLevel(a.type);
			const bLevel = this.getLocationTypeLevel(b.type);

			if (aLevel > bLevel) {
				return -1;
			}
			if (bLevel < aLevel) {
				return 1;
			}

			return 0;
		});

		return results;
	}

	private getLocationTypeLevel(type: LocationType) {
		switch (type) {
			case LocationType.Root:
				return 5;
			case LocationType.Market:
				return 4;
			case LocationType.County:
				return 3;
			case LocationType.City:
				return 2;
			case LocationType.Zip:
				return 1;
		}
	}

	private selectByNodeModel(model: Location) {
		if (!model || !model.market) {
			return;
		}

		const marketVal = model.market;
		const countyVal = model.county;
		const cityVal = model.city;
		const zipVal = model.zipcode;

		for (const state of this.root.children) {
			if (state.data !== marketVal) {
				// If this isn't a match, continue
				continue;
			}

			state.checked = true;

			if (!countyVal || !state.children.length) {
				// There's no county attached to this model, so next
				continue;
			}

			for (const county of state.children) {
				if (county.data !== countyVal) {
					continue;
				}

				county.checked = true;

				if (!cityVal || !county.children.length) {
					continue;
				}

				for (const city of county.children) {
					if (city.data !== cityVal) {
						continue;
					}

					city.checked = true;

					if (!zipVal || !city.children.length) {
						continue;
					}

					for (const zip of city.children) {
						if (zip.data === zipVal) {
							zip.checked = true;

							// Last match. Can break out of this
							break;
						}
					}
				}
			}
		}
	}
}
