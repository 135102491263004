import { Component } from '@angular/core';
import { BaseChart } from '../base-chart';
import { RISK_CATEGORY } from '../../../../shared/constants';
import { InsightOptions } from '../../../../shared/chart/chart';
import { ChartDataSeries, ChartDataElement } from '../../chart-data-series.model';

@Component({
	selector: 'trella-top-insight',
	templateUrl: './top-insight.component.html',
	styleUrls: ['./top-insight.component.scss']
})
export class TopInsightComponent extends BaseChart {
	insightOptions: InsightOptions;
	datapoint: ChartDataElement = {};
	npiName: string;
	npiType: string;
	sourceNpiName: string;

	riskScore = Object.freeze({
		isLow: (score: string): boolean => score === RISK_CATEGORY.Low || score === RISK_CATEGORY.VeryLow,
		isMedium: (score: string): boolean =>
			score === RISK_CATEGORY.Medium || score === RISK_CATEGORY.MediumHigh || score === RISK_CATEGORY.MediumLow,
		isHigh: (score: string): boolean => score === RISK_CATEGORY.High || score === RISK_CATEGORY.VeryHigh
	});

	constructor() {
		super();
	}

	readData(chartInfo: InsightOptions): ChartDataSeries {
		super.readData(chartInfo);
		const data = chartInfo.data;
		this.npiType = chartInfo.npiType;
		const valueField = chartInfo.value;
		const sourceNpiField = chartInfo.sourceNpiName;
		const results = data.map(datapoint => {
			const mappedData: ChartDataElement = {
				data: datapoint[valueField].formattedValue || datapoint[valueField].value, // TODO: Future only need formattedValue
				percentile: datapoint.percentile && (datapoint.percentile.formattedValue || datapoint.percentile.value) // TODO: Future to not hard code field name
			};
			mappedData.data = this.formatDisplayValue(mappedData.data);
			this.npiName = datapoint.label.value;
			this.sourceNpiName = datapoint[sourceNpiField] && datapoint[sourceNpiField].value;
			return mappedData;
		});
		this.datapoint = results[0] || {};

		return {
			data: results,
			categories: [],
			isValidData: !!results.length
		};
	}

	postDataRead() {
		// no op
	}

	handleOptionsChange(config: InsightOptions) {
		this.insightOptions = this.options as InsightOptions;
	}
}
