import { Injectable } from '@angular/core';
import { FilterEvent, Filter } from '../models/filter';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class FilterSelectionService {
	private filter: BehaviorSubject<FilterEvent> = new BehaviorSubject(null);
	public readonly selectedFilter: Observable<FilterEvent> = this.filter.asObservable();

	select(filter: Filter, key: string) {
		this.filter.next({ filter, key });
	}
}
