import {
	Component,
	ElementRef,
	OnInit,
	Output,
	EventEmitter,
	Input,
	ViewChild,
	OnChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { NpiSearchResult } from '../../shared/models/npi/npi-search-result';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SearchItem } from '../../shared/models/search/search-item';
import { CheckedSelection } from '../../shared/models/checked-selection';
import { ALL_OPTION } from '../../shared/constants';

@Component({
	selector: 'trella-global-search',
	templateUrl: './global-search.component.html',
	styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent extends ComponentWithSubscription implements OnInit, OnChanges {
	@Output() errorCallback: EventEmitter<void> = new EventEmitter();
	@Output() searchCallback: EventEmitter<any> = new EventEmitter();
	@Output() itemSelectCallback: EventEmitter<NpiSearchResult> = new EventEmitter();
  @Input() minSearchLength = 0;
	@Input() searchResults: SearchItem[];
	@Input() showLoadBar: boolean;
	@Input() options = [];
	@ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;
	@ViewChild('searchInput') searchInput: ElementRef;

	inputChangeSub = new Subject<string>();
	myControl = new FormControl();
	searchText: string;
	searchPlaceholderText = 'Search by ACO/Provider Name, Location, or ID';

	private _selections: CheckedSelection[] = [];

	constructor() {
		super();
	}

	ngOnInit() {
		this.options.unshift({id: ALL_OPTION, display: 'All'});
		this.inputChangeSub.pipe(takeUntil(this.ngUnsubscribe), debounceTime(500)).subscribe(
			async search => {
				search = search.trim();
				if (!search || search.length < this.minSearchLength) {
					return;
				}
				this.showLoadBar = true;
				await this.onInputChangeInternal(search, this._selections);
			},
			err => {
				this.errorCallback.emit();
			}
		);
	}

	ngOnChanges() {
		this.showLoadBar = false;
	}

	onChange(search: string) {
		this.searchText = search;
		this.inputChangeSub.next(search);
	}

	private async onInputChangeInternal(search: string, selections: CheckedSelection[]) {
		this.searchCallback.emit({query: search, filters: selections});
	}

	selectItem(event: MatAutocompleteSelectedEvent) {
		const info = event.option.value;
		this.itemSelectCallback.emit(info);
		this.autoComplete.openPanel();
		this.searchInput.nativeElement.value = '';
		this.searchInput.nativeElement.blur();
	}

	handleFilterChanged(filters: any){
		this._selections = filters.selections.filter(s => !!s.checked);
		this.autoComplete.openPanel();
		this.onInputChangeInternal(this.searchText, this._selections);
	}
}
