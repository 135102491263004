import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridInfo } from '../grid-info';
import { GridCommandInfo } from '../grid-command-info';
import { Keyed } from '../models/keyed';
import { QueryOptions } from '../query-options';
import { FormattedResult } from '../models/grid/formatted-result';
import { ProviderIds } from '../models/npi/provider-ids';

@Injectable({
	providedIn: 'root'
})
export class GridService {
  private _addToNetworkRequested: BehaviorSubject<Keyed<ProviderIds[]>> = new BehaviorSubject(null);
  readonly addToNetworkRequested: Observable<Keyed<ProviderIds[]>> = this._addToNetworkRequested.asObservable();

  private _childGridInfoSet: BehaviorSubject<Keyed<GridInfo>> = new BehaviorSubject(null);
  readonly childGridInfoSet: Observable<Keyed<GridInfo>> = this._childGridInfoSet.asObservable();

  private _childSubscriptionSet: BehaviorSubject<Keyed<string>> = new BehaviorSubject(null);
  readonly childSubscriptionSet: Observable<Keyed<string>> = this._childSubscriptionSet.asObservable();

  private _clearAllSelected: BehaviorSubject<Keyed<boolean>> = new BehaviorSubject(null);
  public readonly clearAllSelected: Observable<Keyed<boolean>> = this._clearAllSelected.asObservable();
  
  private _commandIssued: BehaviorSubject<Keyed<GridCommandInfo>> = new BehaviorSubject(null);
  readonly commandIssued: Observable<Keyed<GridCommandInfo>> = this._commandIssued.asObservable();
  
  private _dataFetched: BehaviorSubject<Keyed<GridDataResult>> = new BehaviorSubject(null);
  readonly dataFetched: Observable<Keyed<GridDataResult>> = this._dataFetched.asObservable();

  private _eButtonClicked: BehaviorSubject<string> = new BehaviorSubject(null);
  readonly eButtonClicked: Observable<string> = this._eButtonClicked.asObservable();

  private _eButtonPermissionChecked: BehaviorSubject<Keyed<boolean>> = new BehaviorSubject(null);
  readonly eButtonPermissionChecked: Observable<Keyed<boolean>> = this._eButtonPermissionChecked.asObservable();

  private _footerFetched: BehaviorSubject<Keyed<FormattedResult[]>> = new BehaviorSubject(null);
  readonly footerFetched: Observable<Keyed<FormattedResult[]>> = this._footerFetched.asObservable();

  private _itemToggled: BehaviorSubject<Keyed<any>> = new BehaviorSubject(null);
  readonly itemToggled: Observable<Keyed<any>> = this._itemToggled.asObservable();

  private _loading: BehaviorSubject<Keyed<boolean>> = new BehaviorSubject(null);
  readonly loading: Observable<Keyed<boolean>> = this._loading.asObservable();

  private _queryOptionsChanged: BehaviorSubject<Keyed<QueryOptions>> = new BehaviorSubject(null);
  readonly queryOptionsChanged: Observable<Keyed<QueryOptions>> = this._queryOptionsChanged.asObservable();

  private _detailsRequested: BehaviorSubject<Keyed<string>> = new BehaviorSubject(null);
  readonly detailsRequested: Observable<Keyed<string>> = this._detailsRequested.asObservable();

  private _refreshRequested: BehaviorSubject<Keyed<GridInfo>> = new BehaviorSubject(null);
  readonly refreshRequested: Observable<Keyed<GridInfo>> = this._refreshRequested.asObservable();

  private _reportAdded: BehaviorSubject<Keyed<GridInfo>> = new BehaviorSubject(null);
  readonly reportAdded: Observable<Keyed<GridInfo>> = this._reportAdded.asObservable();

  private _searchRequested: BehaviorSubject<Keyed<string>> = new BehaviorSubject(null);
  readonly searchRequested: Observable<Keyed<string>> = this._searchRequested.asObservable();

  private _selectionRequested: BehaviorSubject<Keyed<string[]>> = new BehaviorSubject(null);
  readonly selectionRequested: Observable<Keyed<string[]>> = this._selectionRequested.asObservable();

  private _selectionCountRequested: BehaviorSubject<Keyed<number>> = new BehaviorSubject(null);
  readonly selectionCountRequested: Observable<Keyed<number>> = this._selectionCountRequested.asObservable();

  private _selectionsChanged: BehaviorSubject<Keyed<string[]>> = new BehaviorSubject(null);
  readonly selectionsChanged: Observable<Keyed<string[]>> = this._selectionsChanged.asObservable();

  private _showSelectedToggled: BehaviorSubject<Keyed<boolean>> = new BehaviorSubject(null);
  readonly showSelectedToggled: Observable<Keyed<boolean>> = this._showSelectedToggled.asObservable();

  addReport(key: string, report: GridInfo){
    this._reportAdded.next({key, value: report});
  }

  addToNetwork(key: string, items: ProviderIds[]){
    this._addToNetworkRequested.next({key, value: items});
  }

  allowEButton(key: string, isAllowed: boolean){
    this._eButtonPermissionChecked.next({key, value: isAllowed});
  }

  clearAll(key: string, value: boolean) {
    this._clearAllSelected.next({key, value});
  }

  createChildSubcription(key: string , childKey: string){
    this._childSubscriptionSet.next({key, value: childKey});
  }

  issueCommand(key: string, info: GridCommandInfo) {
    this._commandIssued.next({key, value: info});
  }

  reportEButtonClick(key: string){
    this._eButtonClicked.next(key);
  }

  reportQueryOptions(key: string, options: QueryOptions){
    this._queryOptionsChanged.next({key, value: options});
  }

  requestDetails(key: string, identifier: string){
    this._detailsRequested.next({key, value: identifier});
  }

  requestRefresh(key: string, info: GridInfo){
    this._refreshRequested.next({key, value: info});
  }

  requestSelection(key: string, selections: string[]){
    this._selectionRequested.next({key, value: selections});
  }

  requestSelectionCount(key: string, selectionCount: number){
    this._selectionCountRequested.next({key, value: selectionCount});
  }

  search(key: string, searchText: string){
    this._searchRequested.next({key, value: searchText});
  }

  select(key: string, selections: string[]){
    this._selectionsChanged.next({key, value: selections});
  }

  setChildGridInfo(key: string, value: GridInfo){
    this._childGridInfoSet.next({key, value});
  }

  setData(key: string, data: GridDataResult){
    this._dataFetched.next({key, value: data});
  }

  setFooter(key: string, footer: FormattedResult[]){
    this._footerFetched.next({key, value: footer});
  }

  setLoading(key: string, value: boolean){
    this._loading.next({key, value});
  }

  showSelected(key: string, value: boolean){
    this._showSelectedToggled.next({key, value});
  }

  toggle(key: string, value: any){
    this._itemToggled.next({key, value});
  }
}
