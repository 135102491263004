import { Injectable } from '@angular/core';
import { GridInfo } from '../grid-info';
import { GridExport } from '../models/grid/grid-export';

@Injectable({
	providedIn: 'root'
})
export class GridExportService {

  private _getExportData: (gridInfo: GridInfo) => Promise<GridExport>;

  configure(getExportData: (gridInfo: GridInfo) => Promise<GridExport>) {
    this._getExportData = this._getExportData ?? getExportData;
  }

	async getDataToExport(gridInfo: GridInfo) : Promise<GridExport> {
    return this._getExportData?
       this._getExportData(gridInfo) :
       new Promise<GridExport>((resolve, reject) => {
         resolve(GridExport.withNoData(gridInfo));
      });
  }
}
