import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'trella-stars',
	templateUrl: './stars.component.html',
	styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit {
	@Input() rating = 0;
	@Input() max = 5;
	@Input() ratingNotApplicable = false;
	@Input() noRating = false;
	
	wholeStars = [];
	halfStars = [];
	emptyStars = [];

	constructor() {}

	ngOnInit(): void {
		this.setStarValues(this.rating, this.max);
	}

	setStarValues(rating, max) {
		let emptyCount: any;

		if (rating === 0 || rating === -2) {
			this.wholeStars = new Array(0);
			this.halfStars = new Array(0);
			this.emptyStars = new Array(5);
			return;
		}

		if (max <= rating) {
			this.wholeStars = new Array(max);
			this.halfStars = new Array(0);
			this.emptyStars = new Array(0);
			return;
		}

		this.wholeStars = new Array(Math.trunc(rating));
		emptyCount = max - Math.trunc(rating);

		if (rating % 1) {
			emptyCount = emptyCount > 0 ? --emptyCount : 0;
			this.halfStars = new Array(1);
		}	
		
		this.emptyStars = new Array(emptyCount);
	}
}
