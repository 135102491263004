export enum FILTER_TYPE {
	ACOS = 'Acos',
	AFFILIATIONS = 'Affiliations',
	BENEFICIARY_LOCATION = 'BeneficiaryLocation',
	CARE_PATH_TYPE = 'CarePathType',
	DYNAMIC = 'Dynamic',
	FACILITY_SPECIFIC_HOSPITALIZATION_RATE_HHA = 'FacilitySpecificHospRateHha',
	FACILITY_SPECIFIC_HOSPITALIZATION_RATE_HOS = 'FacilitySpecificHospRateHos',
	FACILITY_SPECIFIC_HOSPITALIZATION_RATE_SNF = 'FacilitySpecificHospRateSnf',
	GROWTH_SHOW_SELECTED = 'GrowthShowSelected',
	HARD_CODED = 'HardCoded',
	HOSPITAL_DISCHARGE_SETTING = 'HospitalDischargeSetting',
	MEDICAL_GROUP = 'MedicalGroup',
	NETWORK_MODEL = 'NetworkModel',
	NETWORK = 'Network',
	NETWORKS_AND_ACOS = 'NetworksAndAcos',
	NPI_LIST = 'NpiList',
	PATH_LIST = 'PathList',
	PATTERN_LIST = 'PatternList',
	PROVIDER_LOCATION = 'ProviderLocation',
	RANGE = 'RangeFilters',
	RISK_ADJUSTED = 'RiskAdjusted',
	SPECIALTY = 'Specialty'
}
