export enum SearchType {
	Aco = 'Aco',
	Hha = 'Hha',
	Hos = 'Hos',
	Hosp = 'Hosp',
	Othf = 'Othf',
	Othp = 'Othp',
	OthpMos = 'OthpMos',
	PhysApp = 'PhysApp',
	Snf = 'Snf',
	Unknown = 'Unknown'
}
