import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '../../shared/models/location';
import { FilterGroupService } from '../../shared/services/filter-group.service';
import { FILTER_TYPE } from '../../shared/enums/filter-type.enum';
import { RangeSelection } from '../../shared/models/ranges/range-selection';
import { FilterRules } from '../../shared/models/filter-rules';
import { SelectionsWithRangeSelection } from '../../shared/models/selections-with-range-selection';
import { CheckedSelectionGroup } from '../../shared/models/checked-selection-group';
import { CheckedSelection } from '../../shared/models/checked-selection';
import { FilterSelections } from '../../shared/models/filter-selections';
import { Node } from '../../shared/models/tree-filter/tree-filter-node';

@Component({
	selector: 'trella-filter-group',
	templateUrl: './filter-group.component.html',
	styleUrls: ['./filter-group.component.scss']
})
export class FilterGroupComponent {
	FILTER_TYPE = FILTER_TYPE;

	private _affiliations: SelectionsWithRangeSelection;
	private _beneficiaryLocations: Location[] = [];
	private _providerLocations: Location[] = [];
	private _rangeFilters: RangeSelection[] = [];
	private _medicalGroups: CheckedSelection[] = [];
	private _networks: CheckedSelection[] = [];
	private _specialties: Node[] = [];
	private _acos: Node[] =[];

	private _selections: FilterSelections = {};

	@Input()
	get affiliations() {
		return this._affiliations;
	}
	set affiliations(affiliations: SelectionsWithRangeSelection) {
		this._affiliations = affiliations;
		this._selections.affiliations = affiliations;
	}

	@Input()
	get beneficiaryLocations() {
		return this._beneficiaryLocations;
	}
	set beneficiaryLocations(locations: Location[]) {
		this._beneficiaryLocations = locations;
		this._selections.beneficiaryLocations = locations;
	}

	@Input()
	get providerLocations() {
		return this._providerLocations;
	}
	set providerLocations(locations: Location[]) {
		this._providerLocations = locations;
		this._selections.providerLocations = locations;
	}

	@Input()
	get rangeFilters() {
		return this._rangeFilters;
	}
	set rangeFilters(rangeFilters: RangeSelection[]) {
		this._rangeFilters = rangeFilters;
		this._selections.rangeFilters = rangeFilters;
	}

	@Input()
	get medicalGroups() {
		return this._medicalGroups;
	}
	set medicalGroups(medicalGroups: CheckedSelection[]) {
		this._medicalGroups = medicalGroups;
		this._selections.medicalGroups = medicalGroups;
	}

	@Input()
	get networks() {
		return this._networks;
	}
	set networks(networks: CheckedSelection[]) {
		this._networks = networks;
		this._selections.networks = networks;
	}

	@Input()
	get specialties() {
		return this._specialties;
	}
	set specialties(specialties: Node[]) {
		this._specialties = specialties;
		this._selections.specialties = specialties;
	}

	@Input()
	get acos() {
		return this._acos;
	}
	set acos(acos: Node[]) {
		this._acos = acos;
		this._selections.acos = acos;
	}

	@Input() filterTypes: FILTER_TYPE[];
	@Input() filterRules: FilterRules;
	@Input() definitionFactory: (FILTER_TYPE) => string;
	@Input() titleFactory: (FILTER_TYPE) => string;

	private _selectionsChanged: EventEmitter<FilterSelections> = new EventEmitter<FilterSelections>();
	@Output() selectionsChanged: Observable<FilterSelections> = this._selectionsChanged.asObservable();

	constructor(private filterGroupService: FilterGroupService) {}

	isIncluded(filterType: FILTER_TYPE) {
		return this.filterTypes.some(x => x === filterType);
	}

	handleAffiliationsChanged(affiliations: SelectionsWithRangeSelection) {
		this._selections.affiliations = affiliations;
		this._selectionsChanged.emit(this._selections);
	}

	handleBeneficiaryLocationChanged(locations: Location[]) {
		this._selections.beneficiaryLocations = locations;
		this._selectionsChanged.emit(this._selections);
	}

	handleLocationChanged(locations: Location[]) {
		this._selections.providerLocations = locations;
		this._selectionsChanged.emit(this._selections);
	}

	handleRangeChanged(ranges: RangeSelection[]) {
		this._selections.rangeFilters = this._selections.rangeFilters.map(x => {
			const range = ranges.find(r => r.name === x.name);
			x.minSelected = range.minSelected;
			x.maxSelected = range.maxSelected;
			return x;
		});
		this._selectionsChanged.emit(this._selections);
	}

	handleSelectionsChanged(selections: CheckedSelectionGroup) {
		switch (selections.filterType) {
			case FILTER_TYPE.MEDICAL_GROUP:
				this._selections.medicalGroups = selections.selections;
				break;
			case FILTER_TYPE.NETWORK:
				this._selections.networks = selections.selections;
				break;
		}
		this._selectionsChanged.emit(this._selections);
	}

	handleSpecialtyChanged(selection: Node[]){
		this._selections.specialties = selection;
		this._selectionsChanged.emit(this._selections);
	}

	handleCmsAcoChanged(selection: Node[]){
		this._selections.acos = selection;
		this._selectionsChanged.emit(this._selections);
	}

	reset() {
		this.filterGroupService.resetFilters();
	}
}
