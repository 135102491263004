import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationNode } from './location-node';
import { LocationTree } from './location-tree';
import { Location } from '../../../shared/models/location';
import { FilterGroupService } from '../../../shared/services/filter-group.service';
import { BaseFilterComponent } from '../base-filter.component';
import { FILTER_TYPE } from '../../../shared/enums/filter-type.enum';
import { Observable } from 'rxjs';

@Component({
	selector: 'trella-location',
	templateUrl: './location.component.html',
	styleUrls: ['./location.component.scss']
})
export class LocationComponent extends BaseFilterComponent {
	private _allLocations: Location[];
	private _selectedLocations: Location[];
	private _selectedNode: LocationNode;

	@Input() header = 'Locations';
	@Input() stateCountyOnly = false;
	@Input() filterType: FILTER_TYPE;

	@Input() definitionFactory: (FILTER_TYPE) => string;
	get getDefinition(): string {
		return this.definitionFactory(this.filterType) ?? '';
	}

	@Input() titleFactory: (FILTER_TYPE) => string;
	get getTitle(): string {
		return (this.titleFactory && this.titleFactory(this.filterType)) || this.header;
	}

	@Input() set allLocations(value: Location[]) {
		this._allLocations = value;
		this.loadTree();
	}

	@Input() set selectedLocations(value: Location[]) {
		this._selectedLocations = value;
		this.loadTree();
	}

	get getCount(): number {
		const tree = this.locationTree;
		if (!tree || !tree.selectedCount) {
			return 0;
		}
		return tree.selectedCount;
	}

	get searchPlaceholderText(): string {
		return this.stateCountyOnly ?  'State or County' : 'State, County, City, or Zip';
	}

	private _locationsChanged: EventEmitter<Location[]> = new EventEmitter<Location[]>();
	@Output() locationsChanged: Observable<Location[]> = this._locationsChanged.asObservable();

	searchQuery: string;
	filteredLocations: LocationNode[] = [];
	locationTree: LocationTree;

	constructor(protected filterGroupService: FilterGroupService) {
		super(filterGroupService);
	}

	loadTree() {
		if (!(this._allLocations && this._selectedLocations)) {
			return;
		}

		this.locationTree = new LocationTree(this._allLocations, this.stateCountyOnly);
		this.locationTree.loadFromNodeModels(this._selectedLocations);
		this.locationTree.checkedNodes.forEach(node => this.locationTree.selectNode(node));
	}

	onSearchKeyup(_: KeyboardEvent): void {
		if (this.searchQuery.length < 2) {
			this.filteredLocations = [];
			return;
		}

		this.filteredLocations = this.locationTree.onSearch(this.searchQuery);
	}

	handleSearchResultSelection(node: LocationNode) {
		this.locationTree.selectSearchResult(node);
		this.setParentModel();
		this.filteredLocations = [];
	}

	handleNodeSelection(node: LocationNode) {
		this.locationTree.selectNode(node);
		this._selectedNode = node;
	}

	isSelectedNode(node: LocationNode) {
		return this._selectedNode === node;
	}

	toggleNode(node: LocationNode) {
		this.locationTree.toggleNode(node);
		this.setParentModel();
	}

	setParentModel() {
		this._locationsChanged.emit(this.locationTree.getSelectedLocations());
	}
}
