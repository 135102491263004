import { Component, EventEmitter, Host, Input, Output } from '@angular/core';
import { FilterGroupService } from '../../../shared/services/filter-group.service';
import { BaseFilterComponent } from '../base-filter.component';
import { CheckedSelection } from '../../../shared/models/checked-selection';
import { FILTER_TYPE } from '../../../shared/enums/filter-type.enum';
import { CheckedSelectionGroup } from '../../../shared/models/checked-selection-group';
import { Observable } from 'rxjs';
import { ALL_OPTION } from '../../../shared/constants';

@Component({
	selector: 'trella-simple-filter',
	templateUrl: './simple-filter.component.html',
	styleUrls: ['./simple-filter.component.scss']
})
export class SimpleFilterComponent extends BaseFilterComponent {
	listOptions: CheckedSelection[];
	@Input() caption = '';
	@Input() set options(selections: CheckedSelection[]) {
		this.listOptions = selections.map(x => ({ ...x }));
	}
	@Input() filterType: FILTER_TYPE;

	private _selectionsChanged: EventEmitter<CheckedSelectionGroup> = new EventEmitter<CheckedSelectionGroup>();
	@Output() selectionsChanged: Observable<CheckedSelectionGroup> = this._selectionsChanged.asObservable();

	@Input() definitionFactory: (FILTER_TYPE) => string;
	get getDefinition(): string {
		return (this.definitionFactory && this.definitionFactory(this.filterType)) || '';
	}

	@Input() titleFactory: (FILTER_TYPE) => string;
	get getTitle(): string {
		return (this.titleFactory && this.titleFactory(this.filterType)) || this.caption;
	}
	
	get getCount(): number {
		return this.checkedOptions.length;
	}

	get checkedOptions(): CheckedSelection[] {
		return this.listOptions.filter(o => o.checked);
	}

	constructor(protected filterGroupService: FilterGroupService) {
		super(filterGroupService);
	}

	handleSelectionsChange(selection: CheckedSelection) {
    if(selection.id === ALL_OPTION) {
      this.handleAllSelection(selection);
    }
		this._selectionsChanged.emit({ filterType: this.filterType, selections: this.listOptions });
	}

  handleAllSelection(selection: CheckedSelection) {
    this.listOptions.forEach(o => o.checked = selection.checked);
  }

}
