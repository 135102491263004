/* eslint-disable eqeqeq */
import { cloneDeep, round, uniq, xor } from 'lodash';
import * as Moment from 'moment';

const moment = Moment;
import {
	AGENCY_PROVIDER_TYPES,
	DISPLAY_VALUE,
	FORMAT_FUNCTIONS,
	FORMAT_FUNCTION_TARGET_TYPE,
	NpiGroupTypeConfigs,
	PORTAL_TYPE,
	ProviderTypeConfigs,
	PROVIDER_TYPE,
	RAW_VALUE,
	ROUTE_CONSTANTS,
	SPECIAL_DATA_VALUES,
	TARGET_TYPE
} from './constants';
import { Selection } from './models/selection';

export interface EnumItem<E> {
	ID: keyof E;
	Name: E;
}

// @dynamic
export class Utils {
	public static loadDate: string; // Gets set on CurrentUser call

	static toggle = (array: any[], item: string) => xor(array, [item]);

	static exists(val: any): boolean {
		return val !== undefined && val !== null && val !== '';
	}

	static isSpecialValue(data) {
		return SPECIAL_DATA_VALUES.indexOf(data) > -1;
	}

	static deepClone(source: any) {
		return cloneDeep(source);
	}

	static toCalendarString(date) {
		return date ? moment(date).format('LL') : 'Unavailable';
	}

	static stringContains(src: string, val: string) {
		return src.toLowerCase().indexOf(val.toLowerCase()) > -1;
	}

	static arrayLength(array: Array<any>) {
		return (array || []).length;
	}

	static generateGuid() {
		const s = [];
		const hexDigits = '0123456789abcdef';
		for (let i = 0; i < 36; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = '4';
		// eslint-disable-next-line no-bitwise
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
		s[8] = s[13] = s[18] = s[23] = '-';

		const uuid = s.join('');
		return uuid;
	}

	static getSelectListObjectFromPrimitive(list: any[]): Selection[] {
		if (!list || !list.length) {
			return [];
		}

		const uniqueArray = uniq(list);
		return uniqueArray
			.map(x => {
				return {
					id: x,
					display: x
				};
			})
			.sort((a, b) => {
				if (a.display < b.display) {
					return -1;
				}
				if (a.display > b.display) {
					return 1;
				}
				return 0;
			});
	}

	static getEnumList(enumObject): EnumItem<any>[] {
		return Object.keys(enumObject).map(key => ({ ID: key, Name: enumObject[key] } as EnumItem<typeof enumObject>));
	}

	static getAvailableProviderTypesConfigsForPortal(portal: PORTAL_TYPE) {
		switch (portal) {
			case PORTAL_TYPE.HHA:
				return [ProviderTypeConfigs.physician, ProviderTypeConfigs.facility, ProviderTypeConfigs.homehealth];
			case PORTAL_TYPE.HOS:
				return [ProviderTypeConfigs.physician, ProviderTypeConfigs.facility, ProviderTypeConfigs.hospice];
			default:
				return [ProviderTypeConfigs.physician, ProviderTypeConfigs.facility];
		}
	}

	static getAvailableProviderTypesForPortal(portal: PORTAL_TYPE) {
		switch (portal) {
			case PORTAL_TYPE.HHA:
				return [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility, PROVIDER_TYPE.homehealth];
			case PORTAL_TYPE.HOS:
				return [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility, PROVIDER_TYPE.hospice];
			default:
				return [PROVIDER_TYPE.physician, PROVIDER_TYPE.facility];
		}
	}

	static getAgencyForPortal(portal: PORTAL_TYPE) {
		switch (portal) {
			case PORTAL_TYPE.HHA:
				return PROVIDER_TYPE.homehealth;
			case PORTAL_TYPE.HOS:
				return PROVIDER_TYPE.hospice;
			default:
				return null;
		}
	}

	static getRouteAttrByProviderType(type: PROVIDER_TYPE) {
		switch (type) {
			case PROVIDER_TYPE.physician:
				return ROUTE_CONSTANTS.physician;
			case PROVIDER_TYPE.physicianGroup:
				return ROUTE_CONSTANTS.physicianGroup;
			case PROVIDER_TYPE.facility:
				return ROUTE_CONSTANTS.facility;
			case PROVIDER_TYPE.hospice:
				return ROUTE_CONSTANTS.hospice;
			case PROVIDER_TYPE.homehealth:
				return ROUTE_CONSTANTS.homehealth;
			default:
				return null;
		}
	}

	static getProviderTypeConfig(providerType: PROVIDER_TYPE) {
		switch (providerType) {
			case PROVIDER_TYPE.physician:
				return ProviderTypeConfigs.physician;
			case PROVIDER_TYPE.physicianGroup:
				return ProviderTypeConfigs.physicianGroup;
			case PROVIDER_TYPE.facility:
				return ProviderTypeConfigs.facility;
			case PROVIDER_TYPE.homehealth:
				return ProviderTypeConfigs.homehealth;
			case PROVIDER_TYPE.hospice:
				return ProviderTypeConfigs.hospice;
			default:
				return null;
		}
	}

	static getNpiGroupTypeConfig() {
		return [NpiGroupTypeConfigs.myAgency, NpiGroupTypeConfigs.competitor, NpiGroupTypeConfigs.comparison];
	}

	static getPercentValueFromDecimal(value: any): number {
		if (!Utils.exists(value) || Utils.isSpecialValue(value) || value <= 0) {
			return 0;
		}

		return value * 100;
	}

	static getDefaultDisplayedValue(value: any, format: string, decimalPoints: number = null) {
		// TODO: Create this transform library on the backend!
		if (!value) {
			return;
		}
		if (Utils.doesFormatFunctionExist(format)) {
			const transformFunc = Utils.getFormatFunction(format);
			return transformFunc(value);
		}

		if (!Utils.exists(format) || !Utils.exists(value) || Utils.isSpecialValue(value)) {
			return value;
		}

		if (value <= 0) {
			return Utils.getDisplayedValue(value, format);
		}

		if (format.indexOf('%') > -1) {
			// Turn into percent
			value = Utils.getPercentValueFromDecimal(value);

			if (!value) {
				// Value is special. Handle this generically
				return Utils.getDisplayedValue(value, format);
			}

			return value.toFixed(decimalPoints || 2) + '%';
		}

		if (format.indexOf('I') > -1) {
			// Round to nearest integer
			return round(value);
		}

		if (decimalPoints) {
			return parseFloat(value).toFixed(decimalPoints);
		}

		if (format.indexOf('1') > -1) {
			// Decimal with 1 digit after dot
			return parseFloat(value).toFixed(1);
		}

		return Utils.getDisplayedValue(value, format);
	}

	static getDisplayedValue(value: any, format: string) {
		if ((!format && value > 0) || (!value && value !== 0 && value !== false)) {
			return value;
		}

		// Need double equals here because sometimes data is string, sometimes it's a number
		if (value == RAW_VALUE.negativeOne) {
			return DISPLAY_VALUE.lessThanEleven;
		} else if (value == RAW_VALUE.negativeTwo || value == RAW_VALUE.negativeFour || value == RAW_VALUE.zero) {
			return DISPLAY_VALUE.dash;
		} else if (value == RAW_VALUE.negativeThree) {
			return DISPLAY_VALUE.insufficient;
		} else if (value == RAW_VALUE.negativeFive) {
			return '';
		} else if (format.indexOf('%') > -1) {
			return `${(value * 100).toFixed(1)}%`;
		}

		return value;
	}

	static getYearQuarterStringFromQm(qm: string) {
		if (!qm || qm.length < 2) {
			return qm;
		}

		const quarterM = parseInt(qm.charAt(2)); // number of quarters we want to go back

		const m = moment(this.loadDate);
		const newDate = m.subtract(quarterM, 'quarters'); // subtract the desired number of quarters

		const datestring = `${newDate.year()} Q${newDate.quarter()}`;

		return datestring;
	}

	static getParsedGridColumnHeader(title: string) {
		title = this._removeBrackets(title);
		if (!title) {
			return '';
		}

		const lowerTitle = title.toLowerCase();
		switch (lowerTitle) {
			case 'qm0':
			case 'qm1':
			case 'qm2':
			case 'qm3':
			case 'qm4':
			case 'qm5':
			case 'qm6':
			case 'qm7':
			case 'qm0b':
			case 'qm1b':
			case 'qm2b':
			case 'qm3b':
			case 'qm4b':
			case 'qm5b':
			case 'qm6b':
			case 'qm7b':
				return Utils.getYearQuarterStringFromQm(title);
			default:
				return title;
		}
	}

	static replaceQuarterWithinString(title: string) {
		if (!title) {
			return '';
		}

		const quarterTagStart = title.indexOf('[[');
		const quarterTagEnd = title.indexOf(']]');

		// want to make sure these string exists
		if (quarterTagStart < 0 || quarterTagEnd < 0) {
			return title;
		}

		// want to make sure the starting brackets are positioned before the ending brackets
		if (quarterTagStart > quarterTagEnd) {
			return title;
		}

		const quarterString = title.substr(quarterTagStart, quarterTagEnd - quarterTagStart + 2);
		const quarter = Utils.getParsedGridColumnHeader(quarterString);

		// replace quarter tag with acutal quarter
		return title.replace(quarterString, quarter);
	}

	private static _removeBrackets(text: string): string {
		if (!text) {
			return '';
		}
		return text.replace(/[\[\]']+/g, '');
	}

	static isAgencyProviderType(providerType: PROVIDER_TYPE): boolean {
		return AGENCY_PROVIDER_TYPES.includes(providerType);
	}

	static doesFormatFunctionExist(functionName: string) {
		return FORMAT_FUNCTIONS.includes(functionName);
	}

	// TODO: This should be moved to backend!
	static getFormatFunction(formatFunction: string) {
		switch (formatFunction) {
			case FORMAT_FUNCTION_TARGET_TYPE:
				return this.targetTypeTransform;
		}
		return null;
	}

	static targetTypeTransform(val: TARGET_TYPE): string {
		switch (val) {
			case TARGET_TYPE.CA:
				return 'Competitor Affiliated';
			case TARGET_TYPE.UU:
				return 'Underutilizing';
			case TARGET_TYPE.UA:
				return 'Unaffiliated';
			case TARGET_TYPE.US:
				return 'User Selected';
		}
	}

	static getPortalDisplayName(portal: PORTAL_TYPE): string {
		switch (portal) {
			case PORTAL_TYPE.ADMIN:
				return 'Admin';
			case PORTAL_TYPE.HOS:
				return 'Hospice';
			case PORTAL_TYPE.HHA:
				return 'Home Health';
			case PORTAL_TYPE.CAREPATH:
				return 'Care Path';
		}
	}

	static getPortal(portal: string): PORTAL_TYPE {
		portal = portal.toLowerCase();
		switch (portal) {
			case 'admin':
				return PORTAL_TYPE.ADMIN;
			case 'hos':
				return PORTAL_TYPE.HOS;
			case 'hha':
				return PORTAL_TYPE.HHA;
			case 'carepath':
				return PORTAL_TYPE.CAREPATH;
			default:
				return;
		}
	}

	static getAcuityFilterList(): Selection[] {
		return [
			{ id: '', display: '(Select)' },
			{ id: 'Low', display: 'Low Acuity' },
			{ id: 'Med', display: 'Medium Acuity' },
			{ id: 'High', display: 'High Acuity' }
		];
	}

	static getAgeFilterList(): Selection[] {
		return [
			{ id: '', display: '(Select)' },
			{ id: '<65', display: '<65' },
			{ id: '65-74', display: '65-74' },
			{ id: '75-84', display: '75-84' },
			{ id: '85+', display: '85+' },
			{ id: 'Unknown', display: 'Unknown' }
		];
	}

	static encodeQueryParam(object: object) {
		const ret = [];

		for (const key of Object.keys(object)) {
			const value = object[key];
			const qKey = key.toLowerCase()[0];
			if (value instanceof Array) {
				if (value.length) {
					value.forEach(v => {
						ret.push(qKey + '=' + v);
					});
				}
			} else if (value) {
				ret.push(qKey + '=' + value);
			}
		}
		return ret.join('&');
	}

	static convertToPascalCase(title: string) {
		if (!title) {
			return '';
		}

		// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
		title = title.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
			return g1.toUpperCase() + g2.toLowerCase();
		});

		return title;
	}

  static isColor(color = ''){
    if(!color.length){
      return false;
    }
    const s = new Option().style;
    s.color = color;
    const isColor = s.color === color || new RegExp('^#(?:[0-9a-fA-F]{3}){1,2}$').test(color);
    return isColor;
  }
}

export const contains = (s: string, arr: string[]) => arr.every(word => s.includes(word));

export const stringInsensitiveEqualas = (a: string, b: string): boolean => {
  return a?.toLowerCase() === b?.toLowerCase();
};
