import { MedicalEntityType } from '../../enums/medical-entity-type';
import { NpiType } from '../../enums/npi-type';

export class NpiFloatingListModel {
	colorClass: string;
	display: string;
	icon: string;
	id: string;
	type?: NpiType | MedicalEntityType;
	showRemove: boolean;
	title: string;

	public constructor(init?: Partial<NpiFloatingListModel>) {
		Object.assign(this, init);
	}
}
