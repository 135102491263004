import { Component, ViewChild, Input, TemplateRef, ElementRef } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
	selector: 'trella-hover-tooltip',
	templateUrl: './hover-tooltip.component.html',
	styleUrls: ['./hover-tooltip.component.scss']
})
export class HoverTooltipComponent {
	@ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
	@Input() tooltipTemplate: TemplateRef<ElementRef>;
	@Input() titleTemplate: TemplateRef<ElementRef>;
	@Input() position = 'top';
	@Input() underline = true;

	constructor() {}
}
