import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { takeUntil, finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'trella-password-change',
	templateUrl: './password-change.component.html',
	styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {
	@Output() update = new EventEmitter();

	constructor(private activatedRoute: ActivatedRoute) {}
	warning: string;
	password: string;
	confirmPassword: string;
	token: string;

	ngOnInit() {
		this.token = this.activatedRoute.snapshot.queryParams.token;
	}

	change() {
		this.warning = '';
		if (!this.password) {
			this.warning = 'New Password cannot be blank';
			return;
		}
		if (!this.confirmPassword) {
			this.warning = 'Repeated Password cannot be blank';
			return;
		}
		if (this.password !== this.confirmPassword) {
			this.warning = 'Passwords must match';
			return;
		}
		if (!/\d/.test(this.password)) {
			this.warning = 'Password must contain 1 number';
			return;
		}
		if (!/[A-Z]/.test(this.password)) {
			this.warning = 'Password must contain 1 uppercase letter';
			return;
		}
		if (this.password.length < 8) {
			this.warning = 'Password must be 8 characters';
			return;
		}

		this.update.emit(this.password);
	}
}
