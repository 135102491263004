export enum RangeFilterType {
	FOLLOWED_PATIENTS = 'FollowedPatients',
	PHYSICIAN_MEDICARE_PATIENTS = 'PhysicianMedicarePatients',
	PHYSICIAN_MEDICARE_PATIENTS_PLUS_OP = 'PhysicianMedicarePatientsPlusOp',
	PHYSICIAN_HHA_PATIENTS = 'PhysicianHHAPatients',
	HOME_HEALTH_UTILIZATION = 'HomeHealthUtilization',
	LEVEL_OF_AFFILIATION = 'LevelOfAffiliation'
}

export const getRangeFilterNameByType = (filterType: RangeFilterType) => {
	switch (filterType) {
		case RangeFilterType.FOLLOWED_PATIENTS:
			return 'Followed Patients';
		case RangeFilterType.PHYSICIAN_MEDICARE_PATIENTS:
			return 'Physician Medicare Patients (Professional)';
		case RangeFilterType.PHYSICIAN_MEDICARE_PATIENTS_PLUS_OP:
			return 'Physician Medicare Patients (Professional + OP)';
		case RangeFilterType.PHYSICIAN_HHA_PATIENTS:
			return 'Physician HHA Patients';
		case RangeFilterType.HOME_HEALTH_UTILIZATION:
			return 'Home Health Utilization %';
		case RangeFilterType.LEVEL_OF_AFFILIATION:
			return 'Hospital Affiliation %';
	}
};
