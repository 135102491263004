import { Component, OnInit, Injectable, Input } from '@angular/core';
import { FilterGroupService } from '../../shared/services/filter-group.service';
import { FILTER_TYPE } from '../../shared/enums/filter-type.enum';
@Injectable()
export abstract class BaseFilterComponent implements OnInit {
	constructor(protected filterGroupService: FilterGroupService) {}
	isOpen: boolean;
	abstract filterType: FILTER_TYPE;
	abstract get getCount(): number;

	// eslint-disable-next-line @angular-eslint/contextual-lifecycle
	ngOnInit() {
		this.filterGroupService.filtersToggled.subscribe((filterType: FILTER_TYPE) => this.closeFilter(filterType));
	}

	toggleFilter() {
		if (!this.isOpen) {
			this.filterGroupService.toggleFilters(this.filterType);
		}
		this.isOpen = !this.isOpen;
	}

	closeFilter(filterType: FILTER_TYPE) {
		if (filterType !== this.filterType) {
			this.isOpen = false;
		}
	}
}
