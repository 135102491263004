import { FilterableSettings, PagerSettings, SelectableSettings, SortSettings } from '@progress/kendo-angular-grid';

export class GridAbilities {
	pageable?: PagerSettings = {};

	sortable?: SortSettings = {
		allowUnsort: true,
		mode: 'single',
		initialDirection: 'desc'
	};

	selectable: SelectableSettings = {
		checkboxOnly: true,
		enabled: true,
		mode: 'multiple'
	};
	hideSelectableCheckbox = false;

	enableAdding = false;
	filterable?: FilterableSettings = true;

	editCommand?: any;
	deleteRow?: any;
	viewable = false;

	groupedActionColumn = false;
	favoriteCommand = false;
	targetCommand = false;
	compareCommand = false;
	npiGroupCommand = false;
	scrollable?: 'none' | 'scrollable' | 'virtual';
	showMagnifyingGlass = false;
	isDragAndDrop = false;

	static GetAbilitiesForGroupedAction() {
		const abilities = new GridAbilities();
		abilities.groupedActionColumn = true;
		abilities.favoriteCommand = true;
		abilities.targetCommand = true;
		abilities.compareCommand = true;
		abilities.npiGroupCommand = true;
		return abilities;
	}
}
