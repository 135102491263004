import { Component, Input, OnChanges, OnInit,  SimpleChanges} from '@angular/core';
import { BaseChart } from '../base-chart';
import { ChartDataElement } from '../../chart-data-series.model';
import { PROVIDER_TYPE, ROUTE_CONSTANTS } from '../../../../shared/constants';
import { ChartConfig } from '../../../../shared/chart/chart';
import { Observable } from 'rxjs';

@Component({
	selector: 'trella-top-places-insight',
	templateUrl: './top-places-insight.component.html',
	styleUrls: ['./top-places-insight.component.scss']
})

export class TopPlacesInsightComponent extends BaseChart implements OnInit, OnChanges {
    
	@Input() chartData: any;
  	@Input() portal: string;
  	@Input() dataChange$: Observable<ChartConfig>;

	header: any[];
	displayData: ChartDataElement[];
	providerType: PROVIDER_TYPE;

	constructor() {
		super();
	}

	ngOnInit() {
    this.data = this.chartData.data;
    super.dataChange$ = this.dataChange$;
		super.ngOnInit();
		this.providerType = this.chartData.options.config.providerType;
		this.header = this.data && this.data.categories;
		this.displayData = this.data && this.data.data && this.data.data;
	}

	ngOnChanges(simpleChanges: SimpleChanges) {		
		if (!simpleChanges.firstChange) {
			this.displayData = simpleChanges.data.currentValue.data;
		}
	}

	getRouterLink(data: any) {
		const selectedRow = this.displayData.find(m => m.data.find(d => d.data === data.data));
		const npi = selectedRow && selectedRow.data.find(d => d.field === this.chartData.options.config.npiField);

		if (npi) {
			return ['/', this.portal, ROUTE_CONSTANTS.analyze.route, this.providerType, npi.data];
		}
	}

    postDataRead() {
        // no op
    }
    handleOptionsChange(config: ChartConfig) {
        // no op
    }
}
