import { SpecialColors } from '../../constants';

export enum GridCellEnumType {
	Check = 'check',
	// eslint-disable-next-line no-shadow
	DestinationType = 'destinationType',
	UpArrow = 'upArrow',
	DownArrow = 'downArrow'
}

export enum GridCellCheck {
	On = 1,
	Off = 0
}

export enum DestinationType {
	Deceased = 'DECEASED',
	Home = 'HOME',
	HomeHealth = 'HHA',
	Hospice = 'HOS',
	Inpatient = 'INP',
	InpatientEr = 'INPER',
	OutpatientErObservation = 'OUTPEROBS',
	ResumptionOfHomeHealth = 'RHHA',
	SkilledNursing = 'SNF'
}

export interface GridCellIconContent {
	data?: string;
	enumType: GridCellEnumType;
}

export interface GridCellAppearance {
	colorSwatch?: string;
	iconClass?: string;
}

type CellContentMatcher = (content: GridCellIconContent) => boolean;
type CellContentAppearanceConverter = (content: GridCellIconContent) => GridCellAppearance;

export interface CellContentProcessorTemplate {
	match: CellContentMatcher;
	convert: CellContentAppearanceConverter;
}

const unknown = 'swatch_unknown';

const isDestinationType = (value?: string) => {
	if (!value) {
		return false;
	}

	const values = Object.values(DestinationType);
	return !!values.find(v => v.toString() === value);
};

const ContentTemplates: CellContentProcessorTemplate[] = [
	{
		match: content => isExpectedEnumType(content.enumType, GridCellEnumType.Check),
		convert: content => ({ colorSwatch: unknown, iconClass: content.data === GridCellCheck.On.toString() ? 'far fa-check' : null })
	},
	{
		match: content => isExpectedEnumType(content.enumType, GridCellEnumType.DestinationType),
		convert: content => ({
			colorSwatch: isDestinationType(content.data) ? `swatch_${content.data}` : unknown,
			iconClass: 'fas fa-square'
		})
	},
	{
		match: content => isExpectedEnumType(content.enumType, GridCellEnumType.UpArrow),
		convert: content => ({
			colorSwatch: SpecialColors.GoodPercentile,
			iconClass: 'fas fa-triangle'
		})
	},
	{
		match: content => isExpectedEnumType(content.enumType, GridCellEnumType.DownArrow),
		convert: content => ({
			colorSwatch: SpecialColors.BadPercentile,
			iconClass: 'fas fa-triangle fa-rotate-180'
		})
	}
];

const isExpectedEnumType = (enumType: string, expectedType: GridCellEnumType) => {
	const expectedText = expectedType.toString();
	return enumType === expectedText;
};

export const GridCellContentProcessor = {
	process: (content: GridCellIconContent) => {
		const template = ContentTemplates.find(t => t.match(content));
		return template ? template.convert(content) : null;
	}
};
