import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GridActionService {
  private _gridPaginated = new Subject();
  gridPaginated: Observable<any> = this._gridPaginated.asObservable();

  paginate() {
    this._gridPaginated.next();
  }
}
