import { Component } from '@angular/core';
import { ERROR_MESSAGES } from '../../shared/constants';

@Component({
	selector: 'trella-notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
	message: '';

	success = false;
	error = false;
	warning = false;
	_slideOutUp = false;

	get showAlert() {
		return this.success || this.error || this.warning;
	}

	get slideOutUp() {
		return this._slideOutUp;
	}

	showSaveSucess(object: string) {
		this.showSuccess(`${object} saved successfully!`);
		this.autoReset();
	}

	showCreateSucess(object: string) {
		this.showSuccess(`${object} created successfully!`);
		this.autoReset();
	}

	showDeleteSucess(object: string) {
		this.showSuccess(`${object} deleted successfully!`);
		this.autoReset();
	}

	showSaveError(object: string) {
		this.showWarning(`Error saving ${object}. Please try again.`);
		this.autoReset();
	}

	showUnexpectedError() {
		this.showWarning(ERROR_MESSAGES.default);
		this.autoReset();
	}

	showSuccess(message) {
		if (!message) {
			return;
		}

		this.reset();
		this.message = message;
		this.success = true;
	}

	showWarning(message) {
		if (!message) {
			return;
		}

		this.reset();
		this.message = message;
		this.warning = true;
	}

	showError(message) {
		if (!message) {
			return;
		}

		this.reset();
		this.message = message;
		this.error = true;
		this.autoReset(10);
	}

	reset() {
		this.message = '';
		this.success = false;
		this.error = false;
		this.warning = false;
		this._slideOutUp = false;
	}

	autoReset(seconds?: number) {
		if (seconds) {
			setTimeout(() => this.toggle(), seconds * 1000);
		} else {
			setTimeout(() => this.toggle(), 5000); // 5 seconds as default
		}
	}

	toggle() {
		this._slideOutUp = true;
		setTimeout(() => this.reset(), 3000);
	}
}
