import { AxisLabelVisualArgs } from '@progress/kendo-angular-charts';
import { geometry, Layout, Text, Group } from '@progress/kendo-drawing';
const { Rect } = geometry;

const LABEL_MARGIN = 30;
const LABEL_MARGIN_SCALE = 60;
const LABEL_MIN_HEIGHT = 100;
export const formatLabelVisual = (e: AxisLabelVisualArgs) => {
	const labelWords = e.text.split(' ');
	const labelFont = e.options.font;
	const maxWidth = e.rect.size.width;
	const labelOrigin = e.rect.origin;

	const rect = new Rect(labelOrigin, [maxWidth, LABEL_MIN_HEIGHT]);
	const layout = new Layout(rect, {
		orientation: 'vertical',
		alignContent: 'center',
		alignItems: 'center',
		name: 'text-group'
	});

	let currentLine = '';
	let previousLine = '';
	const labelGroup = new Group({ id: 'labeltext' });

	labelWords.forEach((word, i, wordsArray) => {
		currentLine = previousLine.concat(' ', word);
		if (getTextWidth(currentLine, labelFont) > getResponsiveWidth(maxWidth)) {
			layout.append(new Text(previousLine, labelOrigin));
			previousLine = word;
		} else {
			previousLine = currentLine;
		}
		if (i === wordsArray.length - 1) {
			layout.append(new Text(previousLine, labelOrigin));
		}
	});
	labelGroup.append(layout);

	layout.reflow();
	return labelGroup;
};

const getTextWidth = (text, font) => {
	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	context.font = font;
	const metrics = context.measureText(text);
	return metrics.width;
};

const getResponsiveWidth = width => {
	return width - Math.round(width / LABEL_MARGIN_SCALE) * LABEL_MARGIN;
};
