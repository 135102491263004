import { Component, ElementRef, ViewChild } from '@angular/core';
import { BaseChart } from '../base-chart';
import * as Highcharts from 'highcharts';
import { ChartDataSeries, ChartDataElement } from '../../chart-data-series.model';
import { ChartConfig } from '../../../../shared/chart/chart';
import { ChartComponent } from '../../chart.component';


const basePieChartOptions = {
	chart: {
		type: 'pie'
	},
	credits: {
		enabled: false // remove highchars watermark
	},
	title: {
		text: ''
	},
	tooltip: {
		pointFormat: '{point.percentage:.1f}%'
	},
	legend: {
		enabled: false
	},
	plotOptions: {
		pie: {
			cursor: 'pointer',
			dataLabels: {
				enabled: true,
				format: '{point.percentage:.1f}% ({point.name})'
			}
		},
		series: {
			point: {
				events: {
					legendItemClick: () => {
						return false;
					}
				}
			}
		}
	},
	series: []
};
@Component({
	selector: 'trella-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent extends BaseChart {
	@ViewChild('highchartimage', { static: false }) public highChartImage: ChartComponent;

	constructor() {
		super();
	}
	Highcharts = Highcharts; // required

	fontSize;
	fontFamily;
	fontWeight;

	chartOptions;

	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	ngOnInit() {
		super.ngOnInit();

		this.fontSize = '.68rem';
		this.fontFamily = '\'Roboto Condensed\', sans-serif';
		this.fontWeight = this.fullScreen ? 'bold' : '';
	}

	getFormattedData() {
		return this.data.data.map(d => {
			return {
				name: d.label,
				y: d.data
			};
		});
	}

	readData(chartInfo: ChartConfig): ChartDataSeries {
		super.readData(chartInfo);
		const data = chartInfo.data;
		const results = data.map(datapoint => {
			const mappedData: ChartDataElement = {
				label: datapoint[chartInfo.key].value,
				data: +datapoint[chartInfo.value].value
			};

			return mappedData;
		});

		const validData = this.validateData(results);
		return {
			data: results,
			categories: [],
			isValidData: validData
		};
	}

	postDataRead() {
		this.chartOptions = {
			...basePieChartOptions,
			series: [
				{
					color: this.seriesColors[0],
					colorByPoint: false,
					data: [...this.getFormattedData()]
				}
			]
		};
	}

	validateData(data: ChartDataElement[]): boolean {
		if (!data || !data.length) {
			return false;
		}

		const anyValidPoint = data.some(x => {
			const numberVal = Number(x.data) || 0;
			return numberVal > 0;
		});

		return anyValidPoint;
	}

	handleOptionsChange(config: ChartConfig) {
		// no op
	}
}
