export enum NpiTypeDisplay {
  homeHealthAgency = 'Home Health Agency',
  hospice = 'Hospice',
  hospital = 'Hospital',
  otherFacility = 'Other Facility',
  otherPerson = 'Other Person',
  physician = 'Physician',
  physicianGroup = 'Physician Group',
  skilledNursingFacility = 'Skilled Nursing Facility'
}
