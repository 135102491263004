import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseChart } from '../base-chart';
import { ChartDataSeries, ChartDataElement } from '../../chart-data-series.model';
import { CHART_DATA_TYPE } from '../../chart-data-type';
import { RAW_VALUE } from '../../../../shared/constants';
import { ChartConfig } from '../../../../shared/chart/chart';
import { Group, Text, Path } from '@progress/kendo-drawing';
import { Utils } from '../../../../shared/Utils';
import { COLORS } from '../../../../shared/enums/color';
import { ChartComponent } from '@progress/kendo-angular-charts';
@Component({
	selector: 'trella-line-chart',
	templateUrl: './line-chart.component.html',
	styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent extends BaseChart implements OnInit {
  type: any; // TODO: Needed? created to enable compile

  @ViewChild('kendoimage') kendoImage: ChartComponent;

	constructor() {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	getLineType(dataLabel: string) {
		if (dataLabel && (dataLabel.includes(this.countyAverageKey) || dataLabel.includes(this.stateAverageKey))) {
			return 'dash';
		}

		return 'solid';
	}

	readData(chartInfo: ChartConfig): ChartDataSeries {
		const result = [];
		const data = chartInfo.data;
		const seriesNameField = chartInfo.seriesNameFields;
		const valueFields = chartInfo.valueFields;

		data.forEach(item => {
			const lineChartData = {
				data: [],
				rawData: [],
				label: item[seriesNameField].value
			};
			valueFields.forEach(field => {
				lineChartData.data.push(item[field].formattedValue);
				lineChartData.rawData.push(this.formatValue(item[field].value));
			});
			result.push(lineChartData);
		});

		const validData = this.validateData(result);
		return {
			data: result,
			categories: [],
			isValidData: validData
		};
	}

	formatValue(value) {
		switch (this.options.dataType) {
			case CHART_DATA_TYPE.percent:
				if (value === RAW_VALUE.negativeOne || value === RAW_VALUE.negativeThree) {
					return null;
				} else if (value === RAW_VALUE.negativeTwo || value === RAW_VALUE.negativeFour) {
					return 0;
				} else {
					return value;
				}
			case CHART_DATA_TYPE.count:
			default:
				return this.getFormattedCountValue(value);
		}
	}

	getFormattedCountValue(value) {
		if (value === RAW_VALUE.negativeOne) {
			return 5;
		} else if (value === RAW_VALUE.negativeThree) {
			return null;
		} else if (value === RAW_VALUE.negativeTwo || value === RAW_VALUE.negativeFour) {
			return 0;
		} else {
			return value;
		}
	}

	renderTarget(args: any): void {
		if (!this.target) {
			return;
		}

		const chart = args.sender;
		const valueAxis = chart.findAxisByName('valueAxis');
		const categoryAxis = chart.findAxisByName('categoryAxis');
		const valueSlot = valueAxis.slot(this.target.value);
		const range = categoryAxis.range();
		const categorySlot = categoryAxis.slot(range.min, range.max);
		const targetColor = Utils.isColor(this.target.color) ? this.target.color : COLORS.red;
		const line = new Path({
			opacity: 1,
			stroke: {
				color: targetColor,
				width: 2,
				dashType: 'dot'
			}
		})
			.moveTo(valueSlot.origin)
			.lineTo(categorySlot.topRight().x, valueSlot.origin.y);
		const label = new Text(this.target.title || this.target.value, [0, 0]);
		const group = new Group();

		label.fill(targetColor);
		label.position([categorySlot.topLeft().x + 5, valueSlot.origin.y]);
		group.append(line, label);
		chart.surface.draw(group);
	}

	validateData = (chartData: ChartDataElement[]) => this.validateRange(chartData);

	postDataRead() {
		// no op
	}

	handleOptionsChange(config: ChartConfig) {
		// no op
	}
}
