import { isArray } from 'lodash';
import { ComparisonOptions, ConfigColumnModel, SparklineOptions, ComparisonShadeOptions, ComparisonBarOptions } from './report-config';
import { Utils } from './Utils';

/**
 * i == Integer
 * d == Decimal
 * p == Percent
 */
export const numericDataTypes = ['i', 'd', 'p'];

export interface SparklineGridColOptions {
	subtitles?: any[];
	fields: string[];
}

export interface Replacement {
	word: string;
	title: string;
	url: string;
}

export enum GRID_FILTER_TYPE {
	text = 'text',
	numeric = 'numeric',
	date = 'date',
	bool = 'boolean'
}

export enum GRID_DATA_TYPE {
	integer = 'i',
	colorSwatch = 'colorSwatch'
}

export enum GRID_COLUMN_TYPE {
	preheader = 'preheader',
	normal = 'normal'
}

export enum DataSubType {
	currency = 'currency'
}

export const DEFAULT_COLUMN_WIDTH = 20;

export class GridColumn {
	columnType: GRID_COLUMN_TYPE;
	columns?: GridColumn[] = [];
	comparison?: ComparisonOptions;
	comparisonBar?: ComparisonBarOptions;
	comparisonShade?: ComparisonShadeOptions;
	clickable = false;
	dataType?: GRID_DATA_TYPE;
	dataSubType: DataSubType;
	decimalPoints = 1;
	definition: string;
	rawDefinition: string;
	replacements?: Replacement[];
	
	enumType?: string;
	favoriteable = false;
	field: string;
	filterable = true;
	filterType: string;
	format: string;
	hidden = false;
	hideTitle = false;
	includeInColumnChooser = true;
	locked = false;
	maxCharacters?: number;
	medicalDirector = false;
	metadata?: GridColumn;
	sortable = true;
	sparklineCol?: SparklineGridColOptions;
	targetable = false;
	title: string;
	uniq: number;
	width: any;

	getWidth = (columns: ConfigColumnModel[], defaultWidth: number) =>
		(columns || []).map(column => column.width).reduce((p, c) => p + c, defaultWidth);

	constructor(config: Partial<ConfigColumnModel>) {
		if (!config) {
			return;
		}

		this.columns = (config.columns || []).map(x => new GridColumn(x));
		this.metadata = config.metadata ? new GridColumn(config.metadata) : null;
		this.width = this.getWidth(config.columns, config.width);

		this.setCommonOptions(config);
		this.setSparklineOptions(config.sparklineOptions);
	}

	private setCommonOptions(config: Partial<ConfigColumnModel>) {
		this.field = config.field;
		this.title = Utils.getParsedGridColumnHeader(config.title);
		this.definition = Utils.replaceQuarterWithinString(config.definition);
		this.enumType = config.enumType;
		this.width = config.width || DEFAULT_COLUMN_WIDTH;
		this.comparison = config.comparisonOptions;
		this.comparisonBar = config.comparisonBarOptions;
		this.comparisonShade = config.comparisonShadeOptions;
		this.hidden = !!config.hidden;
		this.hideTitle = config.hideTitle;
		this.filterable = config.filterable;
		this.decimalPoints = config.decimalPoints;
		this.maxCharacters = config.maxCharacters;
		this.uniq = config.uniq;

		if (config.sparklineOptions) {
			// Sparklines don't need a data type
			return;
		}

		this.format = config.flags;
		this.dataType = config.dataType;
		this.dataSubType = config.dataSubType;

		const isNumericType = this.dataType && numericDataTypes.find(x => x === this.dataType);
		this.filterType = isNumericType ? GRID_FILTER_TYPE.numeric : GRID_FILTER_TYPE.text;

		this.clickable = config.clickable;
		this.favoriteable = config.favoriteable;
		this.targetable = config.targetable;
		this.includeInColumnChooser = config.includeInColumnChooser;
		this.medicalDirector = config.medicalDirector;
		this.locked = config.locked;

		// Hide number filters for now as well anything with a fiterable value of false/undefined
		if (this.filterType === GRID_FILTER_TYPE.numeric || !config.filterable) {
			this.filterable = false;
		}
	}

	private setSparklineOptions(options: SparklineOptions) {
		if (!options) {
			return;
		}

		const fields = options.fields;
		if (isArray(fields)) {
			// Concatenate with a valid field character to get around console warnings
			this.field = fields.join('_');
		}

		this.sparklineCol = {
			subtitles: options.subtitle.map(subtitle => {
				return Utils.getParsedGridColumnHeader(subtitle);
			}),
			fields
		};

		this.filterType = null;
		this.filterable = false;
		this.clickable = false;
		this.sortable = false;
		this.dataType = null;
	}
}
