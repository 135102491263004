import { MedicalEntity } from '../medical-entity';

export class Aco extends MedicalEntity {
  id: string;
  assignment: string;
  email: string;
  fax: string;
  markets: string[];
  note: string;
  pay: string;
  phone: string;
  revenue: string;
  snf3DayRuleWaiver: boolean;
  track: string;
}



