import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../shared/AbstractValueAccessor';
import { CheckedSelection } from '../../shared/models/checked-selection';

@Component({
	selector: 'trella-list-box',
	templateUrl: './list-box.component.html',
	styleUrls: ['./list-box.component.scss'],
	providers: [MakeProvider(ListBoxComponent)]
})
export class ListBoxComponent {
	@Input() options: CheckedSelection[] = [];
	@Input() disabled = false;
	@Input() height = '450px';
	@Input() singleSelect = false;
	@Input() isDisabled: boolean;
	@Output() userChange = new EventEmitter<CheckedSelection>();

	userSearchString = '';

	get selectedOption() {
		return this.getCheckedOptions()[0];
	} 

	set selectedOption(listObj : CheckedSelection) {
		// do nothing - handled by toggleVal
	} 

	get modelCount() {
		return this.getCheckedOptions.length;
	}

	getCheckedOptions(): CheckedSelection[] {
		return this.options.filter(o => o.checked);
	}

	isChecked(listObj: CheckedSelection) {
		return listObj.checked;
	}

	toggleVal(listObj: CheckedSelection) {
		if(this.singleSelect && !listObj.checked){
			this.options.forEach(e => e.checked = false);
		}
		listObj.checked = !listObj.checked;
		this.userChange.emit(listObj);
	}
}
