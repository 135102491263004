import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { GridInfo } from '../../shared/grid-info';
import { ComponentWithSubscription } from '../../shared/component-with-subscription';
import { FilterSelection } from '../../shared/models/filter';
import { GridService } from '../../shared/services/grid.service';
import { FormattedData } from '../../shared/models/grid/formatted-data';

@Component({
	selector: 'trella-multi-grid',
	templateUrl: './multi-grid.component.html',
	styleUrls: ['./multi-grid.component.scss']
})
export class MultiGridComponent extends ComponentWithSubscription implements OnInit {
	gridInfos: GridInfo[];

	@Input()
	set configs(configs: GridInfo[]) {
		this.gridInfos = configs;
		this.selectOptions = configs.map(config => {
			const index = configs.indexOf(config);
			return {
				display: config.title || config.reportName || index
			};
		});

		if (configs && configs.length) {
			this.selectedOption = this.selectOptions[0];
		}
	}

	@Input() disabled = false;
	@Input() filters: FilterSelection[];
	@Input() isLoading = false;
	@Input() key = '';
	// TODO: These can be options?
	@Input() showComparisonsButton = false;
	@Input() showFavoritesButton = false;
	@Input() showTargetsButton = false;
	@Input() showAssigneesButton = false;
	@Input() showUnAssigneeButton = false;
	@Input() showAddToClipboard = false;
	@Input() dataChange$: Observable<GridInfo>;
	@Input() hasShowSelectedToggle = false;
	@Input() showSelected = false;
	@Input() userFavorites: unknown[] = [];
	@Input() userTargets: unknown[] = [];
	@Input() customerId = 0;
	@Input() rowLinkFactory: (key: Record<string,FormattedData>, info: GridInfo) => string;

	@Output() getStaff = new EventEmitter();

	selectedOption: any;
	selectedGridInfo: GridInfo;

	@Output() selectOptions;

	get showMagnifyingGlass(): boolean {
		return (
			this.selectedGridInfo &&
			this.selectedGridInfo.gridAbilities.filterable &&
			this.selectedGridInfo.columns &&
			this.selectedGridInfo.columns.some(x => x.filterable)
		);
	}

	constructor(private gridService: GridService) {
		super();
	}

	ngOnInit() {
		this.dataChange$.subscribe(gridInfo => {
			this.selectedGridInfo = gridInfo;
		});
	}

	// eslint-disable-next-line @angular-eslint/use-lifecycle-interface
	ngAfterViewInit() {
		this.getStaff.emit();
	}

	public selectGrid() {
		const index = this.selectOptions.indexOf(this.selectedOption);
		this.selectedGridInfo = this.gridInfos[index];
		this.gridService.requestRefresh(this.key, this.selectedGridInfo);
	}

	filterHasValues() {
		return this.filters && this.filters.some(f => f.values.length > 1);
	}
}
