import { NpiType } from '../../enums/npi-type';

export class AcoNpi {
  address1?: string;
  address2?: string;
  city: string;
  county: string;
  display: string;
  gpsLatitude?: number;
  gpsLongitude?: number;
  npi: string;
  patientBeneficiaryCount?: number;
  phone: string;
  state: string;
  trellaType: NpiType;
  zip: string;
}
