export class Staff {
	address: string;
	contactinfo: string;
	id: string;
	name: string;
	notes: string;
	npi: string;
	patients: string;
	providerType: string;
	specialty: string;
	staffNpi: string;
}
