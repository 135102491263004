import { Component, Input } from '@angular/core';
import { Utils } from '../../shared/Utils';

@Component({
	selector: 'trella-sparkline-area',
	templateUrl: './sparkline-area.component.html',
	styleUrls: ['./sparkline-area.component.scss']
})
export class SparklineAreaComponent {
	@Input() rawData: any[];
	@Input() sparklineData: any[];
	@Input() width = 200;
	@Input() quarters: any[]; // TODO - this is beling included for backwards compatibility: future remove this input

	seriesHover(event: any) {
		if (!event || !event.point) {
			return '';
		}

		const index = event.point.categoryIx;

		// replace 0 (the current value of the point) with the corresponding display value
		event.point.value = Utils.getDisplayedValue(this.rawData[index], '');
	}
}
